import React from 'react'
import classNames from "classnames"

type Props = {
  name: string;
  label: React.ReactNode;
  register: () => void;
  className?: string;
  style?:{[key:string]:any}
  type?: "switch"|"checkbox"|"radio"
  value?: string;
  disabled?: boolean;
};

export const CustomInput: React.FC<Props> = ({
    name,
    value,
    label,
    register,
    className,
    style,
    type,
    disabled
}) => {

  const inputId = `custom-input-${name}${value ? '-'+value : ''}`

  return (
    <div
      className={classNames("custom-control mt-1", `custom-${type}`, className)}
      {...style && {style}}
    >
      <input
        type={type === 'radio' ? 'radio' : 'checkbox'}
        className="custom-control-input"
        id={inputId}
        name={name}
        {...value && {value}}
        ref={register}
        disabled={disabled}
      />
      <label className="custom-control-label" htmlFor={inputId}>{label}</label>
    </div>
  )
}

CustomInput.defaultProps = {
  type: "checkbox",
  disabled: false
}