import React from 'react'

import {Col, Container, Jumbotron, OverlayTrigger, Row, Tooltip} from "react-bootstrap"
import Helmet from "react-helmet"
import {META, ROUTE} from "config"
import {Header} from "components/Header"

import {getCarName} from "utils"
import {useSelector} from "react-redux"
import {getAuthUid, getCarById, queryCarById} from "redux/selectors"
import {useParams, Link} from "react-router-dom"
import {isLoaded, useFirestoreConnect} from "react-redux-firebase"

import {Loading, Footer, SmartImage, Icon, SmartButton} from "components"
import {ErrorPage} from "pages"

import {CarPlaceholder} from "svg"
import _get from 'lodash/get'
import styles from './CarPage.module.scss'
import {CarSpecData} from "redux/reducers";
import {useTranslation} from "react-i18next"
import {LinkContainer} from "react-router-bootstrap";
import {i18path} from "i18n";
import {CarSpecsComponents} from "types";

type Props = {
  
};

export const CarPage: React.FC<Props> = (props) => {

  const { t } = useTranslation('CarPage')
  const { carId } = useParams<{carId:string}>()
  const uid = useSelector(getAuthUid)
  
  useFirestoreConnect([
    queryCarById(carId)
  ])
  
  const car = useSelector(getCarById(carId))

  if (!isLoaded(car)) return <>
    <Header/>
    <Loading/>
    <Footer/>
  </>

  //todo: check for disabled
  if (!car) return <ErrorPage/>

  return (
    <div className={styles.CarPage}>
      <Helmet>
        <title>{getCarName(car)} &mdash; {META.TITLE}</title>
      </Helmet>
      <Header/>
      <Jumbotron className={"pt-3 pb-4"}>
        <Container>
          <Row>
            <Col lg={8}>
              <h3 className="brand-title"><Link to={i18path(`${ROUTE.CARS}`)}>{t('CARS')}</Link> <b/> {_get(car, 'specs.chassis.vendor.name')} <b/> {_get(car, 'specs.chassis.model.name')}</h3>
              <br/>
            </Col>
          </Row>
          <Row className={styles.carBlock}>
            <Col lg={8} className={"pr-0"}>
              <SmartImage
                src={car.photo}
                placeholder={CarPlaceholder}
                className={styles.carPhoto}
                imageClass={styles.carImage}
                placeholderClass={styles.carPlaceholder}
              />
            </Col>
            <Col lg={4} className={styles.carSpecs}>
              {Object.keys(CarSpecsComponents).map((spec:string) => (
                <p className="d-flex" key={spec}>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`specs-hint-${spec}`}>
                        {t(`common:SPECS.${spec}.TITLE`)}
                      </Tooltip>
                    }
                  >
                    <Icon type={spec} className={styles.specIcon}/>

                  </OverlayTrigger>
                  {<span>
                        <strong>{_get(car, `specs.${spec}.vendor.name`) || "-"}</strong>&nbsp;
                    {_get(car, `specs.${spec}.model.name`) || ""}
                      </span>}
                </p>
              ))}
            </Col>

          </Row>

        </Container>
      {/*</div>*/}
      </Jumbotron>
      <div className={styles.carInfo}>
        <Container>
          <h1 className={"mb-4 font-weight-bold"}><span className="mr-3">{getCarName(car)}</span>
            {car.owner && car.owner.id === uid && <LinkContainer to={i18path(`${ROUTE.GARAGE}/${carId}`)}>
              <SmartButton iconType="createOutline" size="sm" variant="outline-light">
                {t('EDIT')}
              </SmartButton>
            </LinkContainer>}</h1>
          <p className={"text-warning text-center mb-0 py-3"}>
            <Icon type="warningOutline" className={"mr-2"}/>
            {t('common:UNDER_CONSTRUCTION')}
          </p>
        </Container>
      </div>
    </div>
  )
}