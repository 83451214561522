import * as React from 'react'

import { Container, Jumbotron, Row, Col, Card, Badge  } from 'react-bootstrap'
import {HEROSHOT_THUMB_SIZE, ROUTE} from "config"
import { Link } from "react-router-dom"

import {capitalize, displayCarNumber, getThumbURL, StageStanding, Standing} from "utils"
import classNames from "classnames"

import styles from './Leaderboard.module.scss'
import {i18path} from "i18n"
import {useTranslation} from "react-i18next"
import _get from "lodash/get"
import {SmartImage} from "components/SmartImage"
import {UserPlaceholder} from "svg"

type LeaderStanding = Standing | StageStanding;

type Props = {
  title?: string;
  leaders: LeaderStanding[];
  seriesId: string;
  promo?: string;
};

export const Leaderboard: React.FC<Props> = ({title, leaders, seriesId, promo}) => {
  const { t } = useTranslation('profiles')
  return (
    <Jumbotron fluid className={classNames(styles.Leaderboard, promo && styles.promo, promo && styles[`promo-${promo}`])}>
      <Container>
        <h3 className="brand-title">{title}</h3>
        <br/>
        <Row className="justify-content-center">
        <Col sm={12} xl={12}>
        <Row className="justify-content-md-left">
          {leaders.map((leader, index) => (
            <Col lg={4} key={index} className={"px-xl-3"}>
              <Card bg={index === 0 ? "primary" : "secondary"} border={index > 0 ? "primary" : undefined} className={classNames(styles.leaderCard, "mx-xl-4")}>
                <Link to={leader.driver ? i18path(`${ROUTE.DRIVERS}/${leader.driverId}`) : `#/`} className={styles.driverLink}>
                  <Card.Header className={classNames(styles.cardHeader, index === 0 && "font-weight-bold")}>
                    {index === 0 ? "1st" : index === 1 ? "2nd" : "3rd"}
                    <Badge variant="primary" className="brand-badge">{leader.total}</Badge>
                  </Card.Header>
                  <SmartImage placeholder={UserPlaceholder} as={Card.Img} src={leader.driver && getThumbURL(leader.driver.photo, HEROSHOT_THUMB_SIZE.sm)} imageClass={styles.photo} placeholderClass={styles.placeholder}/>
                  <Card.Body>
                    <Row className="align-items-start justify-content-between">
                      <Col>
                        <Card.Title>
                          {leader.driver ? t(`${leader.driverId}.fullName`) : leader.driverId ? capitalize(leader.driverId) : "(unknown driver)"}
                        </Card.Title>
                        <Card.Subtitle className="text-muted">{leader.driver && leader.driver.city}</Card.Subtitle>
                      </Col>
                      <Col className="mb-3 text-right">
                        <h5 className={styles.carNumber}>{leader.driver && displayCarNumber(_get(leader.driver, ['tailNumber', seriesId!]))}</h5>
                      </Col>
                    </Row>
                  </Card.Body>
                </Link>
              </Card>
            </Col>
          ))}
        </Row>
        </Col>
        </Row>
      </Container>
    </Jumbotron>
  )
}

Leaderboard.defaultProps = {
  title: "Current Leaderboard"
}