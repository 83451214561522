import * as React from 'react'
import Helmet from 'react-helmet'
import { Footer, Header } from 'components'
import { META } from 'config'

type Props = {

};

export const Home = (props: Props) => {
  return (
    <>
      <Helmet>
        <title>UDRC &mdash; {META.TITLE}</title>
      </Helmet>
      <Header/>
      <Footer/>
    </>
  )
}