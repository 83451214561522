import React, {useEffect, useState} from 'react'
import Helmet from 'react-helmet'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {isEmpty, isLoaded, useFirestoreConnect} from 'react-redux-firebase'
import {AppState, Series} from "redux/reducers"

import {Footer, Header, Leaderboard, Loading, StagesList, StandingsTable} from 'components'
import { META } from 'config'
import {getStandingsData, Standing } from "utils"
import { ErrorPage } from "pages"
import {addProfileTranslations} from "i18n"

// import styles from './Standings.module.scss'

type Props = {
  
};

export const Standings: React.FC<Props> = (props) => {

  const { seriesId } = useParams<{seriesId:string}>()

  useFirestoreConnect([
    { collection: 'series',
      doc: seriesId,
      storeAs: `series-${seriesId}`
    },
    { collection: 'results',
      where: [
        ['series', '==', seriesId],
      ]
    },
    {
      collection: 'profiles',
      where: [
        ['isActive', '==', true],
        ['isDriver', '==', true]
      ]
    },
    {
      collection: 'events',
      where: [
        ['series', '==', seriesId],
      ],
      orderBy: ["date", "asc"],
    }

  ])

  const series:Series | undefined = useSelector(({ firestore: { data } }: AppState) => data[`series-${seriesId}`])
  const events = useSelector((state:AppState) => state.firestore.ordered.events)
  const results = useSelector((state:AppState) => state.firestore.ordered.results)
  const drivers = useSelector((state:AppState) => state.firestore.data.profiles)
  const driversOrdered = useSelector((state:AppState) => state.firestore.ordered.profiles)

  const [{ standings, leaders }, setStandings] = useState<{standings: Standing[], leaders: Standing[]}>({standings: [], leaders: []})

  useEffect(() => {
    if (isLoaded(series, events, results, drivers)) {
      const standings = getStandingsData(events, results, drivers)
      const leaders = standings.slice(0, 3)
      setStandings({ standings, leaders })
    }
  }, [series, events, results, drivers])

  const [ tReady, setTReady ] = useState(false)

  useEffect(() => {
    if (isLoaded(driversOrdered)) {
      if (!isEmpty(driversOrdered)) addProfileTranslations(driversOrdered)
      setTReady(true)
    }
  }, [driversOrdered])

  if (isLoaded(series, events, results, drivers) && isEmpty(series, events)) return <ErrorPage message="We couldn't find the results of this tournament throughout our archives."/>

  return (
  <>
    <Helmet>
      {isLoaded(series) && <title>{series!.name} Standings &mdash; {META.TITLE}</title>}
    </Helmet>
    <Header/>
    {isLoaded(series, events, results, drivers) && tReady ? <>
      <Leaderboard leaders={leaders} seriesId={seriesId!} promo="standings"/>
      <StagesList events={events}/>
      <StandingsTable standings={standings} stages={events} title={`${series!.year} All Standings`}/>
    </> : <Loading/>}
    <Footer/>
  </>
  )
}