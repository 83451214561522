import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Form, Row, Spinner} from "react-bootstrap"
import {useDispatch, useSelector} from "react-redux"
import {Controller, useForm} from "react-hook-form"
import {useFirebase} from "react-redux-firebase"
import {hideAlert, openAuthPopup, showAlert} from "redux/actions"
import {FIREBASE_ERROR_CODES, AUTH_VARIANT, REAUTH_TIMEOUT} from "config"
import {useTranslation} from "react-i18next"
import {REAUTH_ALERT_ID, reauthenticateAlert} from "pages/Member/Settings/reauthenticateAlert"
import {getReauthenticate} from "redux/selectors"

const defaultValues = {
  password: '',
  confirmPassword: ''
}

type Props = {

};


export const PasswordSettings: React.FC<Props> = (props) => {

  const dispatch = useDispatch()
  const { t } = useTranslation('PasswordSettings')
  const firebase = useFirebase()

  // form validation hook & state
  const { handleSubmit, errors, control, watch, reset, formState: { dirtyFields } } = useForm({
    defaultValues
  })
  // form processing state
  const [ isProcessing, setProcessing ] = useState(false)
  const [ isWaitingAuth, setWaitingAuth ] = useState<{resolve:()=>void, reject:()=>void} | null>(null)

  // waiting for reauth
  const reauthenticate = useSelector(getReauthenticate)

  useEffect(() =>{
    if (reauthenticate && isWaitingAuth) isWaitingAuth.resolve()
  }, [reauthenticate, isWaitingAuth])

  const handleSuccess = useCallback(() => {
    dispatch(showAlert({content: t('alerts:SETTINGS.PASSWORD'), props: { variant: "success"}}))
    setProcessing(false)

    reset(defaultValues)
  }, [dispatch, t, setProcessing, reset])

  const handleFail = useCallback(() => {
    dispatch(showAlert({content: t('alerts:GENERAL_ERROR'), props: { variant: "danger"}}))
    setProcessing(false)

    reset(defaultValues)
  }, [dispatch, t, setProcessing, reset])

  const updatePassword = useCallback((password:string) => {
    setProcessing(true)

    firebase.auth().currentUser?.updatePassword(password).then(() => {
      handleSuccess()
    }).catch((e) => {
      if (e.code === FIREBASE_ERROR_CODES.REAUTH_REQUIRED) {
        dispatch(openAuthPopup(AUTH_VARIANT.CONFIRM))

        new Promise((resolve, reject) => {
          setWaitingAuth({resolve, reject})
          window.setTimeout(()=>reject(), REAUTH_TIMEOUT)
        })
          .then(() => updatePassword(password))
          .catch(() => {
            reauthenticateAlert(dispatch, t, (e) => {
              e.preventDefault()
              dispatch(hideAlert(REAUTH_ALERT_ID))
              dispatch(openAuthPopup(AUTH_VARIANT.CONFIRM))
            })

            setProcessing(false)
            reset(defaultValues)
          })
          .finally(() => setWaitingAuth(null))

      } else {
        handleFail()
      }
    })
  }, [dispatch, t, reset, setProcessing, firebase, handleSuccess, handleFail])

  const onSubmit = handleSubmit(({ password }) => { updatePassword(password) })
  
  return (
    <Row className="mb-5">
      <Col lg={10}>
        <h4>{t('TITLE')}</h4>
        <hr/>
        <Form onSubmit={onSubmit}>
          <fieldset disabled={isProcessing}>
            <Form.Group>
              <Form.Label>{t('PASSWORD.LABEL')}</Form.Label>
              <Controller
                as={Form.Control}
                control={control}
                type="password"
                placeholder={t('PASSWORD.PLACEHOLDER')}
                name="password"
                rules={{
                  required: true,
                  minLength: 8
                }}
                isInvalid={errors.password}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('CONFIRM.LABEL')}</Form.Label>
              <Controller
                as={Form.Control}
                control={control}
                type="password"
                placeholder={t('CONFIRM.PLACEHOLDER')}
                name="confirmPassword"
                rules={{
                  required: true,
                  validate: (value) => value === watch('password')
                }}
                isInvalid={errors.confirmPassword}
              />
              <Form.Text className="text-muted">
                {t('CONFIRM.TEXT')}
              </Form.Text>
            </Form.Group>

            <Button variant="primary" type="submit" disabled={!dirtyFields.size}>
              {isProcessing && <Spinner animation="border" size="sm" as="span" role="status" aria-hidden="true" className="mr-2 align-middle" /> }
              <span className="align-middle">{t('SUBMIT')}</span>
            </Button>
          </fieldset>
        </Form>
      </Col>
    </Row>
  )
}