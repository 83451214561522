import React, {useMemo} from 'react'

import Helmet from "react-helmet"
import {META} from "config"
import {Header} from "components/Header"
import {Footer} from "components/Footer"
import {RegulationsPage} from "pages/ContentPage/RegulationsPage"
import {SiteContentType, SiteContentStatus} from "redux/reducers"
import {isEmpty, isLoaded, useFirestoreConnect} from "react-redux-firebase"
import {
  getAllContentByType, getContentById,
  getOneContentByType, getUserProfile,
  queryAllContentByType,
  queryContentById,
  queryOneContentByType
} from "redux/selectors"
import {useSelector} from "react-redux"
import {Loading} from "components/Loading"
import {ErrorPage} from "pages/ErrorPage"
import {getCurrentLocale} from "i18n"
import {Trans, useTranslation} from "react-i18next"
import {useParams} from 'react-router-dom'
import {Alert, Container} from "react-bootstrap"

import styles from "./ContentPage.module.scss"
import {getDateFromTimestamp} from "utils"

const ContainerByType = {
  [SiteContentType.regulations]: RegulationsPage
}

export enum ContentPageMode {
  one = "one",
  list = "list",
}

const getContentQuery = (mode: ContentPageMode) => {
   if (mode === ContentPageMode.one) {
    return queryOneContentByType
  } else {
    return queryAllContentByType
  }
}

const getContentSelector = (mode: ContentPageMode) => {
   if (mode === ContentPageMode.one) {
    return getOneContentByType
  } else {
    return getAllContentByType
  }
}

type Props = {
  contentType: SiteContentType
  mode?: ContentPageMode
  title?:string
};

export const ContentPage: React.FC<Props> = ({ contentType, mode, title }) => {

  const { t } = useTranslation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const locale = useMemo(() => getCurrentLocale("en"), [t])
  const { id } = useParams()

  useFirestoreConnect([
    id ? queryContentById(id) : getContentQuery(mode!)(contentType, locale!)
  ])
  const PageContainer = useMemo(() => ContainerByType[contentType], [contentType])

  /*
   {object} if `id` provided; {array} in `mode = "list"`; {array} with one element in `mode` = "one"
   */
  const content = useSelector(id ? getContentById(id) : getContentSelector(mode!)(contentType, locale!))
  const profile = useSelector(getUserProfile)

  if (!isLoaded(content, profile)) return <><Header/><Loading/><Footer/></>
  if (isEmpty(content)) return <ErrorPage/>
  if (id && content.status !== SiteContentStatus.published && !profile.isOrganizer) return <ErrorPage/>

  const pageTitle = title || (id ? content.title : (mode === ContentPageMode.one && content[0] && content[0].title)) || ""

  return (
    <>
      <Helmet>
        <title>{pageTitle} &mdash; {META.TITLE}</title>
      </Helmet>
      <Header/>
      {id && content.status !== SiteContentStatus.published &&
        <Alert variant="warning" className={styles.alert}>
          <Container className="d-flex align-items-center">
            <Trans t={t} i18nKey={`alerts:DRAFT_WARNING`}>
              <strong>W</strong> <code className="ml-auto">Type: {{type: t(`MemberRequests:CONTENT_TYPE.${content.type}`)}}; Locale: {{locale: t(`common:LOCALES.${content.locale}`)}}; Updated: {{updated: getDateFromTimestamp(content.updatedAt).fromNow()}}</code>
            </Trans>
          </Container>
        </Alert>}
      <PageContainer content={(id || mode === ContentPageMode.list ? content : content[0])}/>
      <Footer/>
    </>
  )
}

ContentPage.defaultProps = {
  mode: ContentPageMode.one
}