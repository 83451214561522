import React  from 'react'
import classNames from 'classnames'
import * as icons from 'svg/icons'

import styles from './Icon.module.scss'

function convertDashesToCamelCase(text:string):string {
  return text.replace(/-([a-z])/g, g => g[1].toUpperCase())
}

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
  type: string;
  className?: string
}

type IconSetType = { [key: string]: ((...args: any[]) => any) | string}
const IconSet: IconSetType = { ...icons }

export const Icon: React.FC<IconProps> = ({ type, className, ...props}) => {

    const iconType = convertDashesToCamelCase(type)

    if (!iconType || !IconSet[iconType]) {
      return null
    }

    const IconInner = typeof IconSet[iconType] !== "string" && IconSet[iconType]

  return IconInner ?
      <div className={classNames(styles.icon, `icon-${iconType}`, className)} {...props}><IconInner/></div> :
      null
}
