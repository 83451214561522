import React, {useEffect} from 'react'

import { useRouteMatch, Route, Switch, Redirect, Link } from 'react-router-dom'

import {Header, Footer, Loading} from "components"
import {MemberPage} from "pages/Member/MemberPage"
import {ROUTE} from "config"
import {Settings} from "pages/Member/Settings"
import {showAlert} from "redux/actions/GlobalActions"
import {isEmpty, isLoaded, useFirebase, useFirestore, useFirestoreConnect} from "react-redux-firebase"
import {useDispatch, useSelector} from "react-redux"
import {MemberRequest} from "redux/reducers"
import {Alert, Container} from "react-bootstrap"

import styles from './Member.module.scss'
import {Profile} from "pages/Member/Profile"
import {UpdateRegulations, Requests} from "pages/Member"
import {i18path} from "i18n"
import {useTranslation, Trans} from "react-i18next"
import {agreeRequest} from "utils"
import {getDateFromTimestamp} from "utils"
import {getAuthUid, getMyRequests, getUserProfile, queryActiveRequests, queryMyRequests} from "redux/selectors"
import {Garage} from "pages/Member/Garage/Garage"

type Props = {

};

export const Member: React.FC<Props> = (props) => {

  const { t } = useTranslation('MemberPage')
  const { path, url} = useRouteMatch()

  const firebase = useFirebase()
  const firestore = useFirestore()
  const dispatch = useDispatch()

  const uid = useSelector(getAuthUid)
  const profile = useSelector(getUserProfile)

  useFirestoreConnect([
    queryActiveRequests,
    queryMyRequests(uid)
  ])

  const myRequests = useSelector(getMyRequests)

  useEffect(() => {
    if (profile.isVerified !== firebase.auth().currentUser?.emailVerified) {
      firebase.updateProfile({ isVerified: firebase.auth().currentUser?.emailVerified })
    }
  }, [uid, profile, dispatch, firebase])

  useEffect(() => {
    if (isLoaded(myRequests) && !isEmpty(myRequests)) myRequests.filter((r: MemberRequest) => r.isResolved).forEach((request: MemberRequest) => {
      dispatch(showAlert({
        id: request.id,
        content: t(request.isApproved ? 'alerts:YOUR_REQUEST.APPROVED' : 'alerts:YOUR_REQUEST.DISMISSED', {...request.payload, type: request.type, reason: request.reason, date: getDateFromTimestamp(request.createdAt).format("DD MMM YYYY HH:mm:ss")}),
        autoHide: false,
        props: {
          variant: request.isApproved ? "success" : "danger",
          onClose: () => agreeRequest(request, firestore)
        }
      }))
    })
  }, [dispatch, firestore, myRequests, t])
  
  if (!isLoaded(profile, myRequests)) return <Loading/>

  return (
      <>
        <Header/>
          {!profile.isVerified &&
            <Alert variant="danger" className={styles.alert}>
              <Container>
                <Trans t={t} i18nKey={`alerts:VERIFY_WARNING`}>
                  T: VERIFY_WARNING
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link to={i18path(ROUTE.SETTINGS)}>Go to Settings</Link> or try to <a href="#" onClick={(e) => {e.preventDefault();window.location.reload()}}>Reload the page</a>
                </Trans>
              </Container>
            </Alert>
          }
          <Switch>
            {profile?.isOrganizer &&
              <Route path={i18path(ROUTE.REQUESTS)}>
                <MemberPage title={t('TITLE.MEMBERS_REQUESTS')}>
                  <Requests/>
                </MemberPage>
              </Route>
            }
            {profile?.isOrganizer && <Route path={i18path(ROUTE.SERIES)}>
              <MemberPage title={t('TITLE.SERIES_AND_EVENTS')}/>
            </Route>
            }
            {profile?.isOrganizer && <Route path={i18path(ROUTE.CONTENT)}>
              <MemberPage title={t('TITLE.CONTENT')}/>
            </Route>
            }
            {profile?.isOrganizer && <Route path={i18path(ROUTE.UPDATE_REGULATIONS)}>
              <MemberPage title={t('TITLE.UPDATE_REGULATIONS')}>
                <UpdateRegulations/>
              </MemberPage>
            </Route>
            }
            <Route path={i18path(ROUTE.PROFILE)}>
              <MemberPage title={t('TITLE.MEMBER_PROFILE')}>
                <Profile/>
              </MemberPage>
            </Route>
            <Route path={i18path(ROUTE.GARAGE)}>
              <MemberPage title={t('TITLE.GARAGE')}>
                <Garage/>
              </MemberPage>
            </Route>
            <Route path={i18path(ROUTE.TEAM)}>
              <MemberPage title={t('TITLE.TEAM')}/>
            </Route>
            <Route path={i18path(ROUTE.PARTICIPATIONS)}>
              <MemberPage title={t('TITLE.PARTICIPATIONS')}/>
            </Route>
            <Route path={i18path(ROUTE.RACES)}>
              <MemberPage title={t('TITLE.RACES_LOG')}/>
            </Route>
            <Route path={i18path(ROUTE.SETTINGS)}>
              <MemberPage title={t('TITLE.ACCOUNT_SETTINGS')}>
                <Settings/>
              </MemberPage>
            </Route>
            {/*<Redirect path={path} to={`${profile?.isOrganizer ? ROUTE.REQUESTS : ROUTE.PROFILE}`}/>*/}
          </Switch>
        <Footer/>
      </>
  )
}