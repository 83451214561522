import {
  SET_DEFAULT_SERIES,
  OPEN_AUTH_POPUP,
  CLOSE_AUTH_POPUP,
  SHOW_ALERT,
  HIDE_ALERT,
  OPEN_REQUEST_DETAILS,
  CLOSE_REQUEST_DETAILS,
  CLOSE_DISMISS_POPUP,
  OPEN_DISMISS_POPUP, REAUTHENTICATE, OPEN_CAR_DELETE_POPUP, CLOSE_CAR_DELETE_POPUP
} from "./"
import {GlobalAlert} from "redux/reducers/global"
import {CarDelete, MemberRequest} from "redux/reducers"
import { v4 as uuid } from 'uuid'

export function setDefaultSeriesId(id:string) {
  return {
    type: SET_DEFAULT_SERIES,
    payload: id
  }
}

export function openAuthPopup(mode:string) {
  return {
    type: OPEN_AUTH_POPUP,
    payload: mode
  }
}

export function closeAuthPopup() {
  return {
    type: CLOSE_AUTH_POPUP
  }
}

export function showAlert(alert: GlobalAlert | string) {
  if (typeof alert === "string") alert = { content: alert, autoHide: true }
  if (!alert.id) alert.id = uuid()
  if (alert.autoHide === undefined) alert.autoHide = (!alert.props || alert.props.dismissible === undefined)

  return {
    type: SHOW_ALERT,
    payload: alert
  }
}

export function hideAlert(id: string) {
  return {
    type: HIDE_ALERT,
    payload: id
  }
}

export function openRequestDetails(request:MemberRequest) {
  return {
    type: OPEN_REQUEST_DETAILS,
    payload: request
  }
}

export function closeRequestDetails() {
  return {
    type: CLOSE_REQUEST_DETAILS
  }
}

export function openDismissPopup(request:MemberRequest) {
  return {
    type:OPEN_DISMISS_POPUP,
    payload: request
  }
}

export function closeDismissPopup() {
  return {
    type: CLOSE_DISMISS_POPUP
  }
}

export function setReauthenticate(payload: boolean) {
  return {
    type: REAUTHENTICATE,
    payload
  }
}

export function openCarDeletePopup(car:CarDelete) {
  return {
    type:OPEN_CAR_DELETE_POPUP,
    payload: car
  }
}

export function closeCarDeletePopup() {
  return {
    type: CLOSE_CAR_DELETE_POPUP
  }
}