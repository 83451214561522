import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Col, Form, Row} from "react-bootstrap"
import {Trans, useTranslation} from "react-i18next"
import {getCarName, selectorStyles, selectorTheme, sortCars} from "utils"
import {Car, CarStatus, MemberProfileCar} from "redux/reducers"
import {Controller, useForm} from "react-hook-form"
import Select from "react-select"
import {i18path} from "i18n"
import {ROUTE} from "config"
import {Link} from 'react-router-dom'
import {useFirestore} from "react-redux-firebase"
import {useDispatch} from "react-redux"
import {SmartButton} from "components"
import classNames from "classnames"

import {showAlert} from "redux/actions"
import _pick from "lodash/pick"
import styles from './Profile.module.scss'

type SelectOption = {
  value: any;
  label: string;
  isDisabled?: boolean;
}

const getCarsOptions = (cars: Car[]):SelectOption[] =>
  cars
    .filter(car => car.status !== CarStatus.draft)
    .map(car => ({
      value: car,
      label: getCarName(car),
      isDisabled: car.status !== CarStatus.active
    }))

const getDefaultOption = (options: SelectOption[], carId: string) =>
  options.find(option => option.value.id === carId)


type Props = {
  defaultCar: MemberProfileCar;
  profileId: string;
  cars: Car[];
};

export const CarSelect: React.FC<Props> = ({ cars, defaultCar, profileId }) => {

  const { t } = useTranslation('CarSelect')
  const dispatch = useDispatch()

  const sortedCars = useMemo(() => sortCars(cars), [cars])
  const options = useMemo(() => sortedCars ? getCarsOptions(sortedCars) : [], [sortedCars])
  const defaultOption = useMemo(() => options.length && defaultCar && getDefaultOption(options, defaultCar.id), [options, defaultCar])

  const { control, reset, handleSubmit, errors, formState: {dirtyFields} } = useForm({
    defaultValues:{
      newCar: defaultOption
    }})
  const firestore = useFirestore()

  useEffect(() => {
    reset({newCar: defaultOption})
  }, [defaultOption, reset])

  const [ isProcessing, setProcessing ] = useState(false)

  const handleSuccess = useCallback(() => {
    dispatch(showAlert({content: t('alerts:PROFILE.UPDATED'), props: { variant: "success"}}))
    setProcessing(false)
    reset()
  }, [dispatch, reset, t])

  const handleFail = useCallback((message: string) => {
    dispatch(showAlert({content: t('GENERAL_ERROR_MESSAGE', { message }), props: { variant: "danger"}}))
    setProcessing(false)
  }, [dispatch, setProcessing, t])


  const updateProfileCar = useCallback((newCar) => {
    setProcessing(true)

    const newProfileCar:MemberProfileCar = newCar && _pick(newCar.value, ['id', 'specs', 'photo', 'updatedAt'])
    const batch = firestore.batch()

    // update profile
    batch.update(firestore.doc(`profiles/${profileId}`), {
      defaultCar: newCar ? newProfileCar : null
    })
    // update previous default car driver
    if (defaultOption) {
      batch.update(firestore.doc(`cars/${defaultOption.value.id}`), {
        driver: null
      })
    }
    // update new car driver
    if (newCar) {
      batch.update(firestore.doc(`cars/${newProfileCar.id}`), {
        driver: {
          id: profileId
        }
      })
    }

    batch.commit()
      .then(() => handleSuccess())
      .catch((e:any) => handleFail(e.message))
  }, [firestore, handleFail, handleSuccess, profileId, defaultOption])

  const onSubmit = handleSubmit(({ newCar }) => updateProfileCar(newCar))

  return (
    <>
      <h4>{t('TITLE')}</h4>
      <hr/>
      <Row className="mb-5">
        <Col lg={12} xl={10}>
          <Form onSubmit={onSubmit}>
            <fieldset disabled={isProcessing}>
              <Form.Group>
                <Form.Label>
                  {t('LABEL')}
                </Form.Label>
                <Controller
                  control={control}
                  as={Select}
                  name="newCar"
                  rules={{}}
                  options={options}
                  isDisabled={isProcessing || !options.length}
                  isClearable={true}
                  className={classNames("select", styles.carSelect)}
                  theme={selectorTheme}
                  styles={selectorStyles(!errors.newCar)}
                  placeholder={t('PLACEHOLDER')}
                />
                <Form.Text className={!options.length ? "text-danger" : "text-muted"}>
                  <Trans t={t} i18nKey={!options.length ? "TEXT_EMPTY": "TEXT"}>_ <Link to={i18path(ROUTE.GARAGE)}>_</Link></Trans>
                </Form.Text>
              </Form.Group>
              <SmartButton
                isProcessing={isProcessing}
                variant="primary"
                type="submit"
                disabled={!dirtyFields.size}>
                {t('SUBMIT')}
              </SmartButton>
            </fieldset>
          </Form>
        </Col>
      </Row>
    </>
  )
}