import React, {useMemo} from 'react'

import {RouteComponentProps} from 'react-router'
import {Link, Route, Switch, useRouteMatch} from 'react-router-dom'
import {CarForm} from "pages/Member/Garage/CarForm"
import {useTranslation} from "react-i18next"
import {isEmpty, isLoaded, useFirestoreConnect} from "react-redux-firebase"
import {
  getAuthUid,
  getCarsByUid,
  getDriverById,
  getUserProfile,
  queryCarsByUid, queryDriverById
} from "redux/selectors"
import {Loading} from "components/Loading"
import {useSelector} from "react-redux"
import styles from "pages/Member/Garage/Garage.module.scss"
import {Alert} from "react-bootstrap"
import {EmptyCarsList} from "pages/Member/Garage/EmptyCarsList"
import {CarsList} from "pages/Member/Garage/CarsList"
import {Car, CarStatus} from "redux/reducers"
import {sortCars} from "utils";

type Props = {

};

export const Garage: React.FC<Props> = (props) => {

  const { t } = useTranslation('Garage')
  const { path, url } = useRouteMatch()
  const uid = useSelector(getAuthUid)
  const profile = useSelector(getUserProfile)
  const publicProfile = useSelector(getDriverById(profile.profileId))
  const cars = useSelector(getCarsByUid(uid))

  useFirestoreConnect([
    queryDriverById(profile.profileId),
    queryCarsByUid(uid)
  ])

  const sortedCars = useMemo(() => sortCars(cars), [cars])

  if (!isLoaded(profile, publicProfile, cars)) return <>
    <h4 className="mb-0">{t('TITLE.GARAGE')}</h4>
    <hr/>
    <Loading inline/>
  </>

  return (

    <Switch>
      <Route exact path={path}>
        {!isEmpty(cars) ? <CarsList cars={sortedCars} baseUrl={url}/> : <EmptyCarsList baseUrl={url}/>}
      </Route>
      <Route path={`${path}/:carId`}>
        {({ match: { params: { carId }}}:RouteComponentProps<{carId: string}>) => {
          const car = carId !== "new" ? cars.find((car:Car) => car.id === carId) : undefined

          if (!(carId === "new" || car)) return (
            <Alert variant="danger" className={styles.alert}>
              {t('errors:NOT_FOUND.TITLE')}
            </Alert>
          )

          return (
            <>
              <h4 className="d-flex justify-content-between align-items-center">
                <span>
                  <Link to={url}>{t('TITLE.GARAGE')}</Link>&nbsp;/ {t(carId !== "new" ? 'TITLE.EDIT_CAR' : 'TITLE.ADD_CAR')}
                </span>
              </h4>
              <hr/>
              <CarForm backUrl={url} {...car && {carData:car}}/>
            </>
          )
        }}
      </Route>
    </Switch>

  )
}