import * as React from 'react'
import { Container, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Helmet from "react-helmet"
import {META, ROUTE} from "config"
import {Header, Footer} from "components"

import {useTranslation} from "react-i18next"
import {i18path} from "i18n"

import styles from './ErrorPage.module.scss'

type Props = {
  title?: string;
  message?: string;
  action?: string | (() => void);
  buttonTitle?: string;
};

export const ErrorPage: React.FC<Props> = ({ title, message, action, buttonTitle}) => {

  const { t } = useTranslation('errors')

  return (
    <>
    <Helmet>
      <title>{t(title!)} &mdash; {META.TITLE}</title>
    </Helmet>
    <Header/>
    <Container className={styles.ErrorPage}>
      <h1 className="mb-4 text-center">{t(title!)}</h1>
      <p className="lead mb-5 text-center">{t(message!)}</p>
      {typeof action === 'function' ?
        <Button variant="primary" onClick={action}>{t(buttonTitle!)}</Button> :
        <LinkContainer to={i18path(action!) || '/'}><Button variant="primary">{t(buttonTitle!)}</Button></LinkContainer>
      }
    </Container>
    <Footer/>
    </>
  )
}

ErrorPage.defaultProps = {
  title: "NOT_FOUND.TITLE",
  message: "NOT_FOUND.MESSAGE",
  action: `${ROUTE.HOME}`,
  buttonTitle: "NOT_FOUND.BUTTON"
}