import React from 'react'
import {showAlert} from "redux/actions"
import {Trans} from "react-i18next"
import {Action, Dispatch} from "redux"
import {TFunction} from "i18next"

export const REAUTH_ALERT_ID = "reauth-alert"

export const reauthenticateAlert = (dispatch: Dispatch, t: TFunction, onClick: (e:React.MouseEvent) => void):Action => dispatch(showAlert({
  id: REAUTH_ALERT_ID,
  content:
    <Trans t={t} i18nKey="alerts:AUTH.CONFIRM_AUTHORITY">
      CONFIRM_AUTHORITY
      <a href="#/" onClick={onClick}>Confirm</a>
    </Trans>,
  props: { variant: "danger"},
  autoHide: false}
))