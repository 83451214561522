import React, {useCallback, useEffect, useState} from 'react'

import { useForm, Controller } from 'react-hook-form'
import {useDispatch, useSelector} from "react-redux"
import {useFirebase} from "react-redux-firebase"
import { Button, Form, Spinner } from 'react-bootstrap'

import {ANIMATION_TIMEOUT, FIREBASE_ERROR_CODES, EMAIL_PATTERN} from "config"

import styles from './AuthPopup.module.scss'
import {showAlert} from "redux/actions/GlobalActions"
import {useTranslation} from "react-i18next"
import {getAuthError, getUserProfile} from "redux/selectors"

export type ProcessingType = 'email'|'google'|'facebook'
type ProcessingState = { isProcessing: boolean; processingType: ProcessingType | null }

interface Props {
  onSuccess?: () => void;
  onProcessing?: (processing: boolean) => void;
}

export const RecoverForm: React.FC<Props> = ({ onSuccess, onProcessing }) => {

  const { t } = useTranslation('RecoverForm')
  const dispatch = useDispatch()
  const firebase = useFirebase()
  const authError = useSelector(getAuthError)
  const profile = useSelector(getUserProfile)

  // form validation hook & state
  const { handleSubmit, control, errors } = useForm()
  // form processing state
  const [{ isProcessing, processingType }, setProcessing] = useState<ProcessingState>({ isProcessing: false, processingType: null })

  useEffect(() => {
    onProcessing!(isProcessing)
  }, [isProcessing, onProcessing])

  const handleSuccess = useCallback(() => {
    setTimeout(() => {
      dispatch(showAlert({content: t('alerts:AUTH.RECOVER'), props: { variant: "success"}}))
    }, ANIMATION_TIMEOUT)
    setProcessing({ isProcessing: false, processingType: null })
    onSuccess!()
  }, [dispatch, t, setProcessing, onSuccess])

  // form processing methods
  const startProcessing = useCallback((processingType: ProcessingType = 'email') =>
    setProcessing({ isProcessing: true, processingType }), [setProcessing])
  const stopProcessing = useCallback((processingType: ProcessingType = 'email', success: boolean = true) => {
    setProcessing({ isProcessing: false, processingType })
    if (success) handleSuccess()
  }, [setProcessing, handleSuccess])

  const resetPassword = useCallback((email: string) => {
    startProcessing()

    firebase.resetPassword(email)
      .then(() => stopProcessing())
      .catch(() => stopProcessing('email', false))
  }, [startProcessing, firebase, stopProcessing])

  const onSubmit = handleSubmit(({ email, password }) => { resetPassword(email) })

  return (
    <Form className={styles.form} onSubmit={onSubmit}>
      <fieldset disabled={isProcessing as boolean}>
        <Form.Group controlId="formEmail">
          <Controller
            as={Form.Control}
            control={control}
            type="email"
            placeholder={t('EMAIL')}
            name="email"
            defaultValue={profile?.email}
            rules={{
              required: true,
              pattern: EMAIL_PATTERN
            }}
            isInvalid={errors.email || (authError && !isProcessing && processingType === 'email' && (authError.code === FIREBASE_ERROR_CODES.WRONG_PASSWORD || authError.code === FIREBASE_ERROR_CODES.USER_DISABLED))}
          />
        </Form.Group>

        <Form.Group className="mb-0">
          <Button variant="primary" type="submit" block>
            { isProcessing && processingType === "email" && <Spinner animation="border" size="sm" as="span" role="status" aria-hidden="true" className="mr-2 ml-n4 align-middle" /> }
            <span className="align-middle">{t('SUBMIT')}</span>
          </Button>
        </Form.Group>
        {
          authError && !isProcessing &&
          processingType === 'email' &&
          <p className="text-danger text-center mt-4 mb-0 small">
            {t(`firebase:${authError.message.replace(".", "")}`, authError.message)}
          </p>
        }
      </fieldset>
    </Form>
  )
}

RecoverForm.defaultProps = {
  onSuccess: () => {},
  onProcessing: () => {}
}