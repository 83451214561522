import * as React from 'react'
import {Button, ButtonProps, Spinner} from "react-bootstrap"
import {Icon} from "components/Icon"
import {CountDownTimer} from "components/CountDownTimer"
import {useState} from "react"


interface Props extends ButtonProps {
  iconType?: string;
  isProcessing?: boolean
  children: React.ReactNode
  delay?: number;
  disabled?: boolean;
};

export const SmartButton: React.FC<Props & React.ButtonHTMLAttributes<HTMLButtonElement>> =
  ({
     iconType,
     isProcessing,
     children,
     delay,
     disabled,
     ...props
  }) => {

  const [ isWaiting, setWaiting ] = useState(!!delay)

  return (
    <Button disabled={isProcessing || isWaiting || disabled} {...props}>
      {
        isProcessing ?
          <Spinner animation="border" size="sm" as="span" role="status" aria-hidden="true" className="mr-2 align-middle" />:
          delay && isWaiting ?
            <CountDownTimer max={delay!} timeout={delay!} onEnd={() => setWaiting(false)} className="mr-2"/>:
            iconType && <Icon type={iconType!} className="mr-2"/>
      }
      <span className="align-middle">{children}</span>
    </Button>
  )
}

SmartButton.defaultProps = {
  isProcessing: false
}