import * as React from 'react'

import { Container, Col, Row } from 'react-bootstrap'

import styles from './Member.module.scss'
import {MemberMenu} from "pages/Member/MemberMenu"
import {META} from "config"
import Helmet from "react-helmet"
import {ReactNode} from "react"

type Props = {
  title: string;
  children?: ReactNode
};

export const MemberPage = ({title, children}: Props) => {
  return (
    <>
      <Helmet>
        <title>{title} &mdash; {META.MEMBER_AREA_TITLE}</title>
      </Helmet>
      <Container className={styles.MemberPage}>
        <Row>
          <Col lg={3} className="pr-4 mb-4">
            <MemberMenu/>
          </Col>
          <Col className="pt-3">
            {children}
          </Col>
        </Row>
      </Container>
    </>
  )
}