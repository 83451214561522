import { createStore, /*applyMiddleware,*/ compose, StoreEnhancer } from "redux"
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import thunk from 'redux-thunk';
import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction'

// import { getFirebase } from 'react-redux-firebase'
// import { getFirestore } from 'redux-firestore'

import rootReducer from "../reducers"

const persistConfig = {
  key: 'root',
  version: 1,
  whitelist: ['user'],
  // migrate: createMigrate(migrations, { debug: false }),
  storage,
}
//
const persistedReducer = persistReducer(persistConfig, rootReducer)

const enhancer:StoreEnhancer = compose(
  // applyMiddleware(
  //   thunk.withExtraArgument({ getFirebase, getFirestore }),
  // ),

  devToolsEnhancer({name: "UDRC"})
)

export default () => {
  let store = createStore(persistedReducer, enhancer)
  let persistor = persistStore(store)
  return { store, persistor }
}