import {
  OPEN_AUTH_POPUP,
  CLOSE_AUTH_POPUP,
  SET_DEFAULT_SERIES,
  SHOW_ALERT,
  HIDE_ALERT,
  OPEN_REQUEST_DETAILS,
  CLOSE_REQUEST_DETAILS,
  CLOSE_DISMISS_POPUP,
  OPEN_DISMISS_POPUP,
  CLOSE_CAR_DELETE_POPUP,
  OPEN_CAR_DELETE_POPUP,
  REAUTHENTICATE,
} from "../actions"
import {ReactNode} from "react"
import { AlertProps } from "react-bootstrap"
import {CarDelete, MemberRequest} from "redux/reducers/index"

export interface GlobalAlert {
  id?: string;
  content: ReactNode;
  autoHide?: boolean;
  props?: AlertProps;
}

// export type GlobalAlertStack = {[key:string]: GlobalAlert}

export type GlobalAppState = {
  authPopupMode: string | null;
  defaultSeriesId: string | null;
  alerts: GlobalAlert[],
  requestDetails: MemberRequest | null;
  requestDismiss: MemberRequest | null;
  carDelete: CarDelete | null;
  reauthenticate: boolean;
}

const initialState:GlobalAppState = {
  authPopupMode: null,
  defaultSeriesId: null,
  alerts: [],
  requestDetails: null,
  requestDismiss: null,
  carDelete: null,
  reauthenticate: false
}

// todo: remove ts-ignore
//@ts-ignore
export default function(state = initialState, action) {

  switch (action.type) {
    case SET_DEFAULT_SERIES:
      return {...state, defaultSeriesId: action.payload}
    case OPEN_AUTH_POPUP:
      return {...state, authPopupMode: action.payload}
    case CLOSE_AUTH_POPUP:
      return {...state, authPopupMode: null}
    case SHOW_ALERT:
      return {...state, alerts: [...state.alerts.filter(a => a.id !== action.payload.id), action.payload]}
    case HIDE_ALERT:
      return {...state, alerts: state.alerts.filter(a => a.id !== action.payload)}
    case OPEN_REQUEST_DETAILS:
      return {...state, requestDetails: action.payload}
    case CLOSE_REQUEST_DETAILS:
      return {...state, requestDetails: null}
    case OPEN_DISMISS_POPUP:
      return {...state, requestDismiss: action.payload}
    case CLOSE_DISMISS_POPUP:
      return {...state, requestDismiss: null}
    case OPEN_CAR_DELETE_POPUP:
      return {...state, carDelete: action.payload}
    case CLOSE_CAR_DELETE_POPUP:
      return {...state, carDelete: null}
    case REAUTHENTICATE:
      return {...state, reauthenticate: action.payload}
    default:
      return state
  }
}
