import {SIGNIN_WITH_PROVIDER, VERIFICATION_EMAIL_SENT} from "redux/actions/index"

export function signinWithProvider(provider:string) {
  return {
    type: SIGNIN_WITH_PROVIDER,
    payload: provider
  }
}

export function verificationEmailSent(timestamp: number | null) {
  return {
    type: VERIFICATION_EMAIL_SENT,
    payload: timestamp
  }
}