import * as React from 'react'

import classNames from "classnames"
import {Container, Row, Col, Card} from 'react-bootstrap'
import Helmet from 'react-helmet'

import {useSelector} from 'react-redux'
import {isLoaded, useFirestoreConnect} from 'react-redux-firebase'
import {Car} from 'redux/reducers'

import {Footer, Header, Icon, Loading, SmartImage} from 'components'
import {CARPHOTO_THUMB_SIZE, META} from 'config'

import {useTranslation} from "react-i18next"

import styles from './CarsListPage.module.scss'
import {getActiveCars, queryActiveCars} from "redux/selectors"
import {useRouteMatch, Link} from 'react-router-dom'
import {getCarName, getDateFromTimestamp, getThumbURL} from "utils"
import {Avatar, CarPlaceholder} from "svg"

type Props = {};

export const CarsListPage: React.FC<Props> = () => {

  const {t} = useTranslation('CarsListPage')
  useFirestoreConnect([
    queryActiveCars
  ])
  const cars = useSelector(getActiveCars)
  const {url} = useRouteMatch()

  return (
    <>
      <Helmet>
        <title>{t('TITLE')} &mdash; {META.TITLE}</title>
      </Helmet>
      <Header/>
      <div className="promo-header cars mb-3">
        <Container>
          <h1 className="brand-title">{t('TITLE')}</h1>
        </Container>
      </div>
      <Container className={classNames(styles.Cars, "mb-5")}>
        <Row>
          {(!isLoaded(cars)) && <Loading/>}
          {isLoaded(cars) && cars && cars.map((car: Car, i: number) => (
              <Col lg={3} key={i} className="my-3">
                <Card bg="secondary" border="primary" className={styles.carCard}>
                  <Link to={`${url}/${car.id}`} className={styles.carLink}>
                    <SmartImage as={Card.Img}
                                src={getThumbURL(car.photo, CARPHOTO_THUMB_SIZE.sm)}
                                placeholder={CarPlaceholder}
                                placeholderClass={styles.placeholder}/>
                    <Card.Body className={styles.cardBody}>
                      <Card.Title>{getCarName(car)}</Card.Title>
                      <Card.Subtitle className={styles.carUpdated}>{t('UPDATED')} {(car.updatedAt ? getDateFromTimestamp(car.updatedAt).fromNow() : "-")}</Card.Subtitle>
                      <div className={styles.carOwner}>
                        <SmartImage
                          src={car.owner.avatarUrl}
                          placeholder={Avatar}
                          className={styles.ownerPic}
                          placeholderClass={styles.ownerPlaceholder}
                        />
                        <div className={styles.ownerName}>{car.owner.displayName || <span className="text-muted">{t('DELETED')}</span>}</div>
                      </div>
                    </Card.Body>
                  </Link>
                </Card>
              </Col>
            )
          )}
        </Row>
        {isLoaded(cars) && <p className={"text-warning text-center mb-0 py-3"}>
          <Icon type="warningOutline" className={"mr-2"}/>
          {t('common:UNDER_CONSTRUCTION')}
        </p>}
      </Container>
      <Footer/>
    </>
  )
}