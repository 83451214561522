import React from 'react'
import {Icon} from "components/Icon"
import {useFirebase} from "react-redux-firebase"
import {Form, Button, Spinner} from 'react-bootstrap'

import {ProcessingType} from "./SigninForm"
import {useTranslation} from "react-i18next"

type LoginType = 'LOGIN'|'CONFIRM';
type ProviderType = 'google'|'facebook';

type Props = {
  isProcessing: boolean;
  processingType: ProcessingType | null;
  onStartProcessing: (processingType?: ProcessingType) => void;
  onStopProcessing: (processingType?: ProcessingType, success?: boolean) => void;
  loginType: LoginType;
  provider: ProviderType;
};

export const SocialLogin: React.FC<Props> = ({
  isProcessing,
  processingType,
  onStartProcessing,
  onStopProcessing,
  loginType,
  provider,
}) => {

  const { t } = useTranslation('SocialLogin')

  // Authentication hooks & states
  const firebase = useFirebase()

  const loginWithProvider = () => {
    onStartProcessing(provider)
    return firebase.login({ provider: provider, type: 'popup' })
      .then(() => onStopProcessing(provider))
      .catch(() => onStopProcessing(provider, false))
  }

  const confirmWithProvider = () => {
    onStartProcessing(provider)
    // @ts-ignore
    return firebase.reauthenticate({ provider: provider, type: 'popup' })
      .then(() => onStopProcessing(provider))
      .catch(() => onStopProcessing(provider, false))
  }

  const login = () => {
    switch (loginType) {
      case 'LOGIN':
        return loginWithProvider()

      case 'CONFIRM':
        return confirmWithProvider()
    }
  }

  return (
    <>
      <Form.Group>
        <Button variant="outline-light" block onClick={login}>
          {
            isProcessing && processingType === provider ?
              <Spinner animation="border" size="sm" as="span" role="status" aria-hidden="true" className="mr-2 align-middle" />:
              <Icon type={`logo-${provider}`} className="mr-2"/>
          }
          <span className="align-middle">{`${t(loginType)} ${t(`PROVIDER_TYPE.${provider}`)}`}</span>
        </Button>
      </Form.Group>
    </>
  )
}