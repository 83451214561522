import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Badge, Button, Col, Form, OverlayTrigger, Row, Spinner, Tooltip} from "react-bootstrap"
import {CopyText, Icon, CustomInput} from "components"
import {useDispatch, useSelector} from "react-redux"
import {Controller, useForm} from "react-hook-form"
import {useFirebase} from "react-redux-firebase"
import {showAlert} from "redux/actions"
import {useTranslation} from "react-i18next"
import {getAuthUid, getUserProfile} from "redux/selectors"
import {getLocales, truncDocId} from "utils"

import {Prompt} from "react-router-dom"
import styles from './Settings.module.scss'

enum NotificationType {
  general_requests = "general_requests",
  personal_requests = "personal_requests",
  my_requests = "my_requests"
}

const NotificationTypes = [
  { type: NotificationType.general_requests, isOrganizer: true},
  { type: NotificationType.personal_requests, isOrganizer: true},
  { type: NotificationType.my_requests, isOrganizer: false},
]

type Props = {

};

export const AccountSettings: React.FC<Props> = (props) => {

  const dispatch = useDispatch()

  const { t } = useTranslation('AccountSettings')
  const firebase = useFirebase()
  const uid = useSelector(getAuthUid)
  const profile = useSelector(getUserProfile)

  const defaultValues = useMemo(() => ({
    name: profile.displayName,
    notifications: {
      ...profile.isVerified && {locale: "en",
        ...profile.notifications
      }
    }
  }), [profile])

  // form validation hook & state
  const { handleSubmit, errors, control, reset, formState: { dirtyFields }, register } = useForm({
    ...profile && { defaultValues }
  })

  useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  // form processing state
  const [ isProcessing, setProcessing ] = useState(false)

  const handleSuccess = useCallback(() => {
    dispatch(showAlert({content: t('alerts:SETTINGS.ACCOUNT'), props: { variant: "success"}}))
    setProcessing(false)
  }, [dispatch, setProcessing, t])

  const updateAccountSettings = useCallback((displayName:string, notifications: {[key:string]: any}) => {
    setProcessing(true)
    firebase.updateProfile({ displayName, notifications })
    handleSuccess()
  }, [setProcessing, firebase, handleSuccess])

  const onSubmit = handleSubmit(({ name, notifications }) => { updateAccountSettings(name, notifications) })

  return (
    <Row className="mb-5">
      <Col lg={10}>
        <Row className="justify-content-between">
          <Col><h4>{t('TITLE')}</h4></Col>
          <Col className="h5 pr-sm-0 pl-xs-0 pr-sm-3 align-items-center d-flex justify-content-end">
            <span className="align-middle">UID:&nbsp;</span>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="uid-tooltip">{t(`UID_TOOLTIP`)}</Tooltip>
              }
            >
              <Icon type="informationCircleOutline" className="mr-1"/>
            </OverlayTrigger>
            <Badge variant="dark" className="font-weight-normal" pill>
              <CopyText text={uid}>{truncDocId(uid)}</CopyText>
            </Badge>
          </Col>
        </Row>
        <hr/>
        <Form onSubmit={onSubmit}>
          <Prompt
            when={!!dirtyFields.size}
            message={() =>
              t('alerts:UNSAVED') as string
            }
          />
          <Form.Group>
            <Form.Label>{t('INPUT.LABEL')}</Form.Label>
            <Controller
              as={Form.Control}
              control={control}
              type="text"
              placeholder={t('INPUT.PLACEHOLDER')}
              name="name"
              rules={{
                required: true,
                minLength: 10,
                maxLength: 30,
              }}
              isInvalid={errors.name}
            />
            <Form.Text className="text-muted">
              {t('INPUT.TEXT')}
            </Form.Text>
          </Form.Group>
          <Form.Group className="mt-4">
            <Row>
              <Col>
                <h6 className="mb-3">{t('NOTIFICATIONS.TITLE')}</h6>
                {NotificationTypes.map(nt => (
                  (!nt.isOrganizer || profile.isOrganizer) && <CustomInput
                    name={`notifications.${nt.type}`}
                    label={t(`NOTIFICATIONS.LABEL.${nt.type}`)}
                    register={register}
                    type="switch"
                    key={nt.type}
                    {...!profile.isVerified && { disabled: true }}
                  />
                ))}
                {profile.isVerified ?
                  <Form.Text muted>
                    {t('NOTIFICATIONS.TEXT')}
                  </Form.Text> :
                  <Form.Text className="text-danger">
                    {t('NOTIFICATIONS.NOT_VERIFIED')}
                  </Form.Text>
                }
              </Col>
              <Col>
                <span className={styles.lang}>
                  <Form.Text className="d-block mt-n2 ml-n1 mb-3 text-muted">
                    <Icon type="languageOutline" className="mr-2"/>
                    <span className="align-middle">{t('NOTIFICATIONS.LOCALE')}</span>
                  </Form.Text>
                  {getLocales(t).map(locale => (
                      <CustomInput
                        name="notifications.locale"
                        label={locale[1]}
                        register={register}
                        value={locale[0]}
                        type="radio"
                        key={`notifications-locale-${locale[0]}`}
                        {...!profile.isVerified && { disabled: true }}
                      />
                      )
                    )}
                </span>
              </Col>
            </Row>
          </Form.Group>
          <Button variant="primary" type="submit" disabled={!dirtyFields.size}>
            {isProcessing && <Spinner animation="border" size="sm" as="span" role="status" aria-hidden="true" className="mr-2 align-middle" /> }
            <span className="align-middle">{t('SUBMIT')}</span>
          </Button>
        </Form>
      </Col>
    </Row>
  )
}