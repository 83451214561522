import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, ButtonGroup, Dropdown, DropdownButton, Spinner} from "react-bootstrap"
import styles from "pages/Member/Requests/Requests.module.scss"
import {MemberRequest} from "redux/reducers"
import {useTranslation} from "react-i18next"
import {useDispatch, useSelector} from "react-redux"
import {openDismissPopup, openRequestDetails, showAlert} from "redux/actions"
import {approveRequest} from "utils"
import {useFirebase, useFirestore} from "react-redux-firebase"
import classNames from "classnames"
import {Icon} from "components/Icon"
import {getAuthUid} from "redux/selectors"

type Props = {
  request: MemberRequest;
  as?: "list" | "popup";
  onHidePopup?: () => void;
  onProcessing?: (processing: boolean) => void;
};

export const RequestActions: React.FC<Props> = ({ request, as, onHidePopup, onProcessing}) => {

  const dispatch = useDispatch()
  const { t } = useTranslation('MemberRequests')
  const [ isProcessing, setProcessing ] = useState(false)
  // const [ request, setRequest ] = useState<MemberRequest | null>(null)
  const uid = useSelector(getAuthUid)
  const firebase = useFirebase()
  const firestore = useFirestore()

  useEffect(() => {
    onProcessing!(isProcessing)
  }, [isProcessing, onProcessing])

  const handleApprove = useCallback(() => {
    if (window.confirm(t('alerts:CONFIRM'))) {
      setProcessing(true)
      approveRequest(uid, request!, firestore, firebase).then(({isResolved}) => {
        dispatch(showAlert(t(isResolved ? 'alerts:REQUESTS.RESOLVED' : 'alerts:REQUESTS.APPROVED')))
      }).catch((error) => {
        dispatch(showAlert({ content: t(error, error), props: { variant: "danger" }}))
      })
        .finally(() => {
          setProcessing(false)
          if (as === "popup") { onHidePopup!() }
        })
    }
  }, [as, dispatch, firebase, firestore, onHidePopup, request, t, uid])

  const handleDismiss = useCallback(() => {
    if (as === "popup") { onHidePopup!() }
    dispatch(openDismissPopup(request!))
  }, [as, dispatch, onHidePopup, request])

  const votesCount = useMemo(():number => {
    return request && request!.votes ? Object.keys(request!.votes).length : 0
  }, [request])

  if (!request) return null

  return (
    (isProcessing || (request.votes && request.votes[uid]) ?

      <Button
        size={as === "popup" ? "sm" : undefined}
        variant={as === "popup" ? "dark" : "outline-secondary"}
        className={styles.actionsButton}
        onClick={() => dispatch(openRequestDetails(request))}
        disabled={isProcessing || as === "popup"}>
        <span className="align-middle">{t('ACTIONS.DETAILS', {count: votesCount, max: request.votesNeeded})}</span>
        {isProcessing ?
          <Spinner animation="border" size="sm" as="span" role="status" aria-hidden="true" className={classNames("ml-2 align-middle", as === "list" && "mr-n2")} />:
          <Icon type="checkMarkOutline" className={classNames("ml-2 align-middle", as === "list" && "mr-n2")}/>
        }
      </Button>:

        as === "popup" ?
        <DropdownButton as={ButtonGroup} size="sm" variant="dark" title={t('ACTIONS.DETAILS', {count: votesCount, max: request.votesNeeded})} id="bg-nested-dropdown">
          <Dropdown.Item onClick={handleApprove}>{t((request.votesNeeded - votesCount < 2) ? 'ACTIONS.RESOLVE' : 'ACTIONS.APPROVE')}</Dropdown.Item>
          {request.votesNeeded === 1 && request?.resolverId === uid && <Dropdown.Item>{t('ACTIONS.ASSIGN')}</Dropdown.Item>}
          <Dropdown.Divider/>
          <Dropdown.Item onClick={handleDismiss}>{t('ACTIONS.DISMISS')}</Dropdown.Item>
        </DropdownButton>:

        <Dropdown as={ButtonGroup} size="sm" alignRight className={styles.actionsMenu}>
          <Button variant="secondary" className={styles.actionsButton} onClick={() => dispatch(openRequestDetails(request))}>
            <span className="align-middle">{t('ACTIONS.DETAILS', {count: votesCount, max: request.votesNeeded})}</span>
          </Button>

          <Dropdown.Toggle split variant="secondary" id="dropdown-split-basic"/>

          <Dropdown.Menu>
            <Dropdown.Item onClick={handleApprove}>{t((request.votesNeeded - votesCount < 2) ? 'ACTIONS.RESOLVE' : 'ACTIONS.APPROVE')}</Dropdown.Item>
            {request.votesNeeded === 1 && request?.resolverId === uid && <Dropdown.Item>{t('ACTIONS.ASSIGN')}</Dropdown.Item>}
            <Dropdown.Divider/>
            <Dropdown.Item onClick={handleDismiss}>{t('ACTIONS.DISMISS')}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
    ))
}

RequestActions.defaultProps = {
  as: "list",
  onHidePopup: () => {},
  onProcessing: () => {}
}