import React, {useEffect, useState} from 'react'

import {isLoaded, useFirestoreConnect} from "react-redux-firebase"
import {useSelector} from "react-redux"
import {MemberRequest, MemberRequestType} from "redux/reducers"
import {Loading} from "components/Loading"

import {useTranslation} from "react-i18next"
import {Badge, Table} from "react-bootstrap"
import {Avatar} from "svg"

import {getDateFromTimestamp} from "utils"
import {RequestPreview} from "pages/Member/Requests/RequestPreview"
import {RequestActions} from "pages/Member/Requests/RequestActions"
import {RequestPopup} from "pages/Member/Requests/RequestPopup"

import {getActiveRequests, getAuthUid, queryActiveRequests} from "redux/selectors"
import {DismissPopup} from "pages/Member/Requests/DismissPopup"
import {SmartImage} from "components/SmartImage"


import styles from './Requests.module.scss'

type Props = {
  
};

export const Requests: React.FC<Props> = (props) => {

  const { t } = useTranslation('MemberRequests')

  useFirestoreConnect([
    queryActiveRequests
  ])

  const uid = useSelector(getAuthUid)
  const requests = useSelector(getActiveRequests)

  const [ activeRequests, setActiveRequests ] = useState<MemberRequest[]>([])

  useEffect(() => {
    setActiveRequests((requests || []).filter((r: MemberRequest) => r.author.id !== uid && (!r.resolverId || r.resolverId === uid)))
  }, [requests, uid])

  if (!isLoaded(requests)) return <Loading inline/>
  
  return (
    <div className={styles.Requests}>
      <h4>{t('TITLE')}</h4>
      <Table striped className="mt-3 mb-4">
        <thead>
        <tr>
          <th scope="row">{t('TABLE.CREATED')}</th>
          <th scope="row">{t('TABLE.AUTHOR')}</th>
          <th scope="row">{t('TABLE.CHANGES')}</th>
          <th scope="row"/>
        </tr>
        </thead>
        <tbody>
          {activeRequests.map((request:MemberRequest) => {
          const createdDate = getDateFromTimestamp(request.createdAt)
          return (
          <tr key={request.createdAt.seconds}>
            <td>
              <p className="mb-1">{createdDate.format("MMM DD, YYYY")}</p>
              <p className="text-muted mb-0">{createdDate.format("HH:mm:ss")}</p>
            </td>
            <td>
              <div className={styles.author}>
                <div className="d-lg-none d-xl-block mr-3">
                  <SmartImage
                    src={request.author.avatarUrl}
                    roundedCircle
                    className={styles.avatar}
                    placeholder={Avatar}
                    placeholderClass={styles.avatar}
                  /></div>
                <div>
                  <p className="mb-1">{request.author.displayName}</p>
                  <p className="text-muted mb-0">{request.author.email}</p>
                </div>
              </div>
            </td>
            <td className={styles.left}>
              <p className="mb-1"><Badge variant={request.resolverId ? "primary" : request.type === MemberRequestType.EDIT_CONTENT ? "info" : "secondary"}>{t(`TYPES.${request.type}`)}</Badge></p>
              <RequestPreview request={request}/>
            </td>
            <td className={styles.actions}>
              <RequestActions request={request}/>
            </td>
          </tr>
          )
        })}
        </tbody>
      </Table>
      {!activeRequests.length ?
        <p className="text-center my-5 text-muted">{t('EMPTY')}</p>:
        <div className="d-flex">
          <p className={styles.legend}><Badge variant="primary">&nbsp;</Badge> &mdash; {t('LEGEND.PERSONAL')}</p>
          <p className={styles.legend}><Badge variant="secondary">&nbsp;</Badge> &mdash; {t('LEGEND.GENERAL')}</p>
          <p className={styles.legend}><Badge variant="info">&nbsp;</Badge> &mdash; {t('LEGEND.CONTENT')}</p>
        </div>
      }

      <RequestPopup/>

      <DismissPopup/>
    </div>
  )
}