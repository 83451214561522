import {
  SIGNIN_WITH_PROVIDER,
  VERIFICATION_EMAIL_SENT
} from "../actions"

export type UserAppState = {
  authProvider: string | null;
  verificationSentOn: number | null;
}

const initialState:UserAppState = {
  authProvider: null,
  verificationSentOn: null,
}

// todo: remove ts-ignore
//@ts-ignore
export default function(state = initialState, action) {

  switch (action.type) {
    case SIGNIN_WITH_PROVIDER:
      return {...state, authProvider: action.payload}
    case VERIFICATION_EMAIL_SENT:
      return {...state, verificationSentOn: action.payload}
    default:
      return state
  }
}
