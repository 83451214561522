import React, {useEffect, useState} from 'react'

import { differenceWith as _differenceWith } from 'lodash'

import { Alert } from 'react-bootstrap'

import {useDispatch, useSelector} from "react-redux"
import {getGlobalAlerts} from "redux/selectors"
import {GlobalAlert} from "redux/reducers/global"
import {hideAlert} from "redux/actions/GlobalActions"

import {ALERT_AUTOHIDE_TIMEOUT} from "config"
import {CountDown} from "svg"

import styles from './AlertManager.module.scss'

type Props = {

};

type AlertEntity = GlobalAlert & {hidden?: boolean}

export const AlertManager: React.FC<Props> = (props) => {

  const dispatch = useDispatch()
  const globalAlerts = useSelector(getGlobalAlerts)
  const [ alerts, setAlerts ] = useState<AlertEntity[]>(globalAlerts)

  useEffect(() => {
    // Finding difference betwen Redux and local states to properly animate Alerts
    const deletedAlertsIds = _differenceWith(alerts, globalAlerts, (a, b) => a.id === b.id).map(a => a.id)
    const addedAlerts = _differenceWith(globalAlerts, alerts, (a, b) => a.id === b.id).map(alert => {
      const onClose = () => {
        dispatch(hideAlert(alert.id as string))
        if (typeof alert.props?.onClose === 'function') alert.props.onClose()
      }
      if (alert.autoHide) setTimeout(onClose, ALERT_AUTOHIDE_TIMEOUT)
      return {...alert, hidden: true}
    })
    const updatedAlerts = alerts.filter(a => !a.hidden).map(a => (deletedAlertsIds.includes(a.id) ? {...a, hidden: true} : a))

    setAlerts([...updatedAlerts, ...addedAlerts])
    requestAnimationFrame(() => {
      setAlerts([...updatedAlerts, ...addedAlerts.map(a => ({...a, hidden: false}))])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalAlerts])

  return (
    <div className={styles.AlertManager}>
      {Object.values(alerts).map(alert => {
      const onClose = () => {
        dispatch(hideAlert(alert.id as string))
        if (typeof alert.props?.onClose === 'function') alert.props.onClose()
      }
      return (
        <Alert key={alert.id} variant="primary" dismissible {...alert.props} onClose={onClose} show={!alert.hidden}>
          <span>{alert.content}</span>
          {alert.autoHide && <CountDown className={styles.countDown} style={{animationDuration: `${ALERT_AUTOHIDE_TIMEOUT/1000}s`}}/>}
        </Alert>
      )})}
    </div>
  )
}