import * as React from 'react'

import classNames from "classnames"
import { Link } from 'react-router-dom'
import { Container, Row, Col, Card, Nav  } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Helmet from 'react-helmet'
import _get from 'lodash/get'

import { useSelector } from 'react-redux'
import {isEmpty, isLoaded, useFirestoreConnect} from 'react-redux-firebase'
import {MemberProfile} from 'redux/reducers'

import {Footer, Header, Loading} from 'components'
import {HEROSHOT_THUMB_SIZE, META, ROUTE} from 'config'
import {UserPlaceholder} from "svg"

import styles from './DriversList.module.scss'
import {getActiveDrivers, getDefaultSeriesId, queryActiveDrivers} from "redux/selectors"
import {i18path, addProfileTranslations} from "i18n"
import {useTranslation} from "react-i18next"
import {useEffect, useState} from "react"
import {displayCarNumber, getThumbURL} from "utils"
import {SmartImage} from "components"

type Props = {
  match: {
    params: {
      type: "all" | "street" | "pro";
    }
  }
};

export const DriversList: React.FC<Props> = ({match: { params: {type}}}) => {

  const { t } = useTranslation('profiles')
  useFirestoreConnect([
    queryActiveDrivers
  ])
  const drivers = useSelector(getActiveDrivers)
  const defaultSeriesId = useSelector(getDefaultSeriesId)

  const [ tReady, setTReady ] = useState(false)

  useEffect(() => {
    if (isLoaded(drivers)) {
      if (!isEmpty(drivers)) addProfileTranslations(drivers)
      setTReady(true)
    }
  }, [drivers])

  // if (!ready) return <Loading/>

  return (
    <>
      <Helmet>
        <title>Drivers &mdash; {META.TITLE}</title>
      </Helmet>
      <Header/>
      <div className="promo-header drivers mb-3">
        <Container>
          <Row>
            <Col>
              <h1 className="brand-title">UDRC {type === "pro" ? "Pro" : type === "street" ? "Street" : ""} Drivers</h1>
            </Col>
            <Col className="d-flex align-items-center">
              <Nav variant="pills" className="brand-pills justify-content-end ml-auto">
                <Nav.Item><LinkContainer to={i18path(`${ROUTE.DRIVERS}/pro`)}><Nav.Link>Pro</Nav.Link></LinkContainer></Nav.Item>
                <Nav.Item><LinkContainer to={i18path(`${ROUTE.DRIVERS}/street`)}><Nav.Link>Street</Nav.Link></LinkContainer></Nav.Item>
                <Nav.Item><LinkContainer to={i18path(`${ROUTE.DRIVERS}/all`)}><Nav.Link>All</Nav.Link></LinkContainer></Nav.Item>
              </Nav>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className={classNames(styles.Drivers, "mb-5")}>
        <Row>
        {(!isLoaded(drivers) || !tReady) && <Loading/>}
        {isLoaded(drivers) && tReady && drivers && drivers.map((driver: MemberProfile, i:number) => (
            <Col lg={3} key={i} className="my-3">
              <Card bg="secondary" border="primary" className={styles.driverCard}>
                <Link to={i18path(`${ROUTE.DRIVERS}/${driver.id}`)} className={styles.driverLink}>
                  <SmartImage as={Card.Img} src={getThumbURL(driver.photo, HEROSHOT_THUMB_SIZE.sm)} placeholder={UserPlaceholder} imageClass={styles.photo} placeholderClass={styles.placeholder}/>
                  <Card.Body className={styles.cardBody}>
                    <Row className="align-items-start justify-content-between">
                      <Col sm={8} className="pr-sm-1">
                        <Card.Title className={styles.driverName}>{t(`${driver.id}.fullName`)}</Card.Title>
                        <Card.Subtitle className="text-muted">{driver.city}</Card.Subtitle>
                      </Col>
                      <Col sm={4} className={"pl-sm-1 mb-3 text-right"}>
                        <h5 className={styles.carNumber}>{displayCarNumber(_get(driver, ['tailNumber', defaultSeriesId!]))}</h5>
                      </Col>
                    </Row>
                  </Card.Body>
                </Link>

              </Card>
            </Col>
          )
        )}
        </Row>
      </Container>
      <Footer/>
    </>
  )
}