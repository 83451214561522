import * as React from 'react'
import { Spinner } from 'react-bootstrap'

import styles from './Loading.module.scss'
import classNames from "classnames"

type Props = {
  inline?: boolean;
};

export const Loading: React.FC<Props> = ({ inline }) => {
  return (
    <div className={classNames(styles.Loading, inline && styles.inline)}>
      <Spinner animation="border" variant="primary" />
    </div>
  )
}