import React from 'react'
import { Logo } from 'svg'

import Helmet from "react-helmet"

import { META } from "config"

import {useTranslation} from "react-i18next"
import styles from './ComingSoon.module.scss'

export const ComingSoon: React.FC = () => {

  const { t } = useTranslation('ComingSoon')

  return (
    <>
      <Helmet>
        <title>UDRC &mdash; {META.TITLE}</title>
      </Helmet>
      <div className={styles.ComingSoon}>
        <header className={styles.message}>
          <Logo width={200}/>
          <p className='lead'>{t('SOON')}</p>
        </header>
      </div>
    </>
  )
}