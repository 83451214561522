import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Container,
  Jumbotron,
  Row,
  Col,
  Card,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap'
import Helmet from 'react-helmet'

import {Footer, Header, Icon, LastResult, Loading, SmartButton} from "components"
import { META, ROUTE } from "config"

import {useFirestoreConnect, isLoaded, isEmpty} from "react-redux-firebase"
import {useSelector} from "react-redux"
import {MemberProfile} from "redux/reducers"
import { ErrorPage } from "pages"
import {getDateFromTimestamp, getAge, getDriverRank, displayCarNumber} from "utils"

import {Logo, CarPlaceholder, UserPlaceholder} from 'svg'

import styles from './Driver.module.scss'
import {
  getAuthUid,
  getDefaultSeriesId, getDriverById,
  getResultsBySeriesId, queryDriverById,
  queryResultsBySeriesId
} from "redux/selectors"
import {addProfileTranslations, i18path} from "i18n"
import {useTranslation} from "react-i18next"
import _get from "lodash/get"
import {SmartImage} from "components"
import {CarSpecsDetails} from "types"
import {LinkContainer} from "react-router-bootstrap"

const lastResults = [
  {
    eventName: "UDRC'2020 Stage 1 (Qualification)",
    eventKey: "udrc2020-stage1",

    data: [
      {
        number: 77,
        name: "Krulikovskiy",
        points: 90.5
      }
    ]
  },
  {
    eventName: "UDRC'2020 Stage 1 (Top-16)",
    eventKey: "udrc2020-stage1",
    data: [
      {
        number: 77,
        name: "Krulikovskiy",
        points: 1,
        win: true,
      },
      {
        number: 9,
        name: "Galeta",
        points: 0
      }
    ]
  },
  {
    eventName: "UDRC'2020 Stage 1 (Final)",
    eventKey: "udrc2020-stage1",
    data: [
      {
        number: 77,
        name: "Krulikovskiy",
        points: 0
      },
      {
        number: 17,
        name: "Zaycev",
        points: 1,
        win: true,
      }
    ]
  }
]

const getRequiredCarSpecs = () => Object.keys(CarSpecsDetails)
  .filter(spec => CarSpecsDetails[spec].required)

type Props = {
  
};

export const Driver: React.FC<Props> = (props) => {

  const { t, i18n } = useTranslation('Driver')
  const { driverId } = useParams<{driverId:string}>()
  const defaultSeriesId = useSelector(getDefaultSeriesId)
  const uid = useSelector(getAuthUid)

  useFirestoreConnect([
    queryDriverById(driverId),
    // querySpecsByDriverId(driverId),
    queryResultsBySeriesId(defaultSeriesId!)
  ])
  const results = useSelector(getResultsBySeriesId(defaultSeriesId!))
  // const specs: CarSpecData[] = useSelector(getSpecsByDriverId(driverId!))
  const driver:MemberProfile | undefined = useSelector(getDriverById(driverId!))

  const [{ rank, points }, setCurrentRank] = useState<{rank?: number, points?: number}>({})
  const [ tReady, setTReady ] = useState(false)

  useEffect(() => {
    if (isLoaded(driver, results)) {
      setCurrentRank(getDriverRank(results, driver!, driverId))
    }
  }, [driver, results, driverId])

  useEffect(() => {
    if (isLoaded(driver)) {
      if (!isEmpty(driver)) addProfileTranslations([ {...driver!, id: driverId}])
      setTReady(true)
    }
  }, [driver, driverId])

  if (!isLoaded(driver, results) || !tReady) return <>
    <Header/>
    <Loading/>
    <Footer/>
  </>

  //todo: check for flags.pro
  if (!driver /* || !driver.isDriver*/) return <ErrorPage/>
  
  return (
    <>
      <Helmet>
        <title>{t(`profiles:${driverId}.fullName`)} &mdash; {META.TITLE}</title>
      </Helmet>
      <Header/>
      <Jumbotron fluid className={styles.driverPromo}>
        <Container className={styles.driverInfo}>
          <h3 className="brand-title">{t('TITLE')}</h3>
          <br/>

          <div className={styles.driverSummary}>
            <h1 className={styles.driverName}>
              {t(`profiles:${driverId}.fullName`)}

              {driver.userId && driver.userId === uid && <LinkContainer to={i18path(ROUTE.PROFILE)}>
                <SmartButton iconType="createOutline" size="sm" variant="outline-light" className="ml-3">
                  {t('EDIT')}
                </SmartButton>
              </LinkContainer>}
            </h1>
          </div>
          <div className={styles.driverHeadshot}>
            <SmartImage src={driver.photo} placeholder={UserPlaceholder} className={styles.heroshot} imageClass={styles.photo} placeholderClass={driver.photo ? styles.placeholder : "invisible"}/>
            <div className={styles.number}>{displayCarNumber(_get(driver, ['tailNumber', defaultSeriesId!]))}</div>
          </div>
          <Container className={styles.driverStats}>
            <hr/>
            <Row>
              <Col lg={2}>
                <Row className="align-items-center">
                  <Col lg={6} className={styles.title}>{t('RANK')}</Col>
                  <Col lg={6} className={styles.value}>{rank}</Col>
                </Row>
              </Col>
              <Col lg={2}>
                <Row className="align-items-center">
                  <Col lg={6} className={styles.title}>{t('POINTS')}</Col>
                  <Col lg={6} className={styles.value}>{points}</Col>
                </Row>
              </Col>
            </Row>
          </Container>

        </Container>
      </Jumbotron>
      <Container>
        <Row>

          <Col lg={4}>
            <h4 className="brand-title mb-4 tbd">{t('INFO.TITLE')}</h4>
            {i18n.exists(`profiles:${driverId}.bio`) && <p>
              {t(`profiles:${driverId}.bio`)}
            </p>}
            {driver.birthDate && <Row className="p-2"><Col lg={6} className="text-muted">{t('INFO.AGE')}</Col><Col lg={6}>{getAge(new Date(driver?.birthDate))}</Col></Row>}
            {driver.debutYear && <Row className="p-2"><Col lg={6} className="text-muted">{t('INFO.DEBUT')}</Col><Col lg={6}>{driver.debutYear}</Col></Row>}
            <Row className="p-2"><Col lg={6} className="text-muted">{t('INFO.RESIDENCE')}</Col><Col lg={6}>{driver.city}, {driver.country}</Col></Row>
            <Row className="p-2"><Col lg={6} className="text-muted tbd">{t('INFO.CLUB')}</Col><Col lg={6}><Link to={i18path(`${ROUTE.LOCATIONS}/klovska`)}>Klovska (Kyiv)</Link></Col></Row>
            <Row className="p-2"><Col lg={6} className="text-muted tbd">{t('INFO.TEAM')}</Col><Col lg={6}><Link to={i18path(`${ROUTE.TEAMS}/dizrc`)}>DizRC</Link></Col></Row>
            <Row className="p-2"><Col lg={6} className="text-muted tbd">{t('INFO.SPONSOR')}</Col><Col lg={6}><Link to={i18path(`${ROUTE.HOME}`)}><Logo width={50} height={20}/></Link></Col></Row>
            <Row className="align-items-center p-2"><Col lg={6} className="text-muted tbd">{t('INFO.SOCIAL')}</Col><Col lg={6}>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><Icon type="logoFacebook" className={styles.socialIcon}/></a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><Icon type="logoInstagram" className={styles.socialIcon}/></a>
              <a href="https://youtube.com" target="_blank" rel="noopener noreferrer"><Icon type="logoYoutube" className={styles.socialIcon}/></a>
            </Col></Row>

          </Col>

          <Col lg={4}>

            <Card className="mb-3">
              <Card.Header>{t('SPECS')}</Card.Header>
              <SmartImage fluid
                  src={_get(driver, 'defaultCar.photo')}
                  placeholder={CarPlaceholder}
                  placeholderClass={styles.carPlaceholder}
              />
              <Card.Body>
                <div className={styles.specList}>
                  {getRequiredCarSpecs().map(spec => (
                    <p className="d-flex" key={spec}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={`specs-hint-${spec}`}>
                            {t(`common:SPECS.${spec}.TITLE`)}
                          </Tooltip>
                        }
                      >
                        <Icon type={spec} className={styles.specIcon}/>

                      </OverlayTrigger>
                      {driver.defaultCar ? <span>
                        <strong>{_get(driver.defaultCar, `specs.${spec}.vendor.name`)}</strong>&nbsp;
                        {_get(driver.defaultCar, `specs.${spec}.model.name`)}
                      </span>: "-"}
                    </p>
                  ))}
                </div>
              </Card.Body>
              <Card.Footer className="text-muted">
                {t('SPECS_UPDATED')} {(driver.defaultCar ? getDateFromTimestamp(_get(driver, ['defaultCar', 'updatedAt'])).fromNow() : "-")}
              </Card.Footer>
            </Card>
          </Col>

          <Col lg={4}>
            <h4 className="brand-title mb-4 tbd">{t('RESULTS')}</h4>

            {lastResults.map((result, i) => (
              <LastResult eventName={result.eventName} eventKey={result.eventKey} data={result.data} key={i}/>
            ))}

          </Col>


        </Row>
      </Container>
      <Footer/>
    </>
  )
}