import * as React from 'react'

import {EmailSettings} from "pages/Member/Settings/EmailSettings"
import {PasswordSettings} from "pages/Member/Settings/PasswordSettings"
import {AccountSettings} from "pages/Member/Settings/AccountSettings"

type Props = {

}

export const Settings:React.FC<Props> = (props) => {
  return (
    <>
      <AccountSettings/>
      <EmailSettings/>
      <PasswordSettings/>
    </>
  )
}