import React, {useEffect, useState} from 'react'

import styles from './CountDownTimer.module.scss'
import classNames from "classnames"

type Props = {
  max: number;
  timeout: number;
  onEnd?: () => void;
  className?: string;
};

export const CountDownTimer: React.FC<Props> = ({ max, timeout, className, onEnd }) => {

  const [countDown, setCountDown] = useState(timeout)

  useEffect(() => {
    let interval: number
    let countDown = timeout
    if (countDown > 0) {
      interval = window.setInterval(() => {
        if (countDown > 1) {
          countDown -= 1
          setCountDown(countDown)
        } else {
          window.clearInterval(interval)
          onEnd!()
        }
      }, 1000)

    }
    return () => window.clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <div className={classNames(styles.CountDown, className)}>
      {/*<CountDown className={styles.outer}/>*/}
      <svg className={styles.outer} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" enableBackground="new 0 0 40 40" xmlSpace="preserve">
          <circle r="18" cx="20" cy="20" style={{strokeDashoffset: 2 * Math.PI * 18 * (1 - countDown / max)}}/>
      </svg>
      <div className={styles.inner}>{countDown}</div>
    </div>
  )
}

CountDownTimer.defaultProps = {
  onEnd: () => {}
}