import React, {useCallback, useEffect, useState} from 'react'

import { useForm, Controller } from 'react-hook-form'
import {useDispatch, useSelector} from "react-redux"
import {isLoaded, useFirebase} from "react-redux-firebase"
import { Button, Form, Spinner } from 'react-bootstrap'

import {ANIMATION_TIMEOUT, FIREBASE_ERROR_CODES, EMAIL_PATTERN} from "config"

import {SocialLogin} from "./SocialLogin"
import {showAlert} from "redux/actions/GlobalActions"

import {signinWithProvider} from "redux/actions/UserActions"
import {useTranslation} from "react-i18next"
import {getAuth, getAuthError} from "redux/selectors"

import styles from './AuthPopup.module.scss'

export type ProcessingType = 'email'|'google'|'facebook'
type ProcessingState = { isProcessing: boolean; processingType: ProcessingType | null }

interface Props {
  onSuccess?: () => void;
  onRecover?: () => void;
  onProcessing?: (processing: boolean) => void;
}

export const SigninForm: React.FC<Props> = ({ onSuccess, onRecover, onProcessing}) => {

  const { t, i18n } = useTranslation('SigninForm')
  const tFB = i18n.getFixedT(null, 'firebase')
  
  const firebase = useFirebase()
  const auth = useSelector(getAuth)
  const authError = useSelector(getAuthError)
  
  const dispatch = useDispatch()

  // form validation hook & state
  const { handleSubmit, control, errors } = useForm()
  // form processing state
  const [{ isProcessing, processingType }, setProcessing] = useState<ProcessingState>({ isProcessing: false, processingType: null })

  useEffect(() => {
    onProcessing!(isProcessing)
  }, [isProcessing, onProcessing])

  const handleSuccess = useCallback((processingType: ProcessingType) => {
    setTimeout(() => {
      dispatch(showAlert({content: t('alerts:AUTH.SIGNIN_SUCCESS'), props: { variant: "success"}}))
    }, ANIMATION_TIMEOUT)

    // remember auth provider
    dispatch(signinWithProvider(processingType!))

    setProcessing({ isProcessing: false, processingType: null })
    onSuccess!()
  }, [dispatch, t, setProcessing, onSuccess])

  // form processing methods
  const startProcessing = useCallback((processingType: ProcessingType = 'email') =>
    setProcessing({ isProcessing: true, processingType }), [setProcessing])
  const stopProcessing = useCallback((processingType: ProcessingType = 'email', success: boolean = true) => {
    setProcessing({ isProcessing: false, processingType })
    if (success) handleSuccess(processingType)
  }, [setProcessing, handleSuccess])

  const loginWithEmail = useCallback((email: string, password: string) => {
    startProcessing()
    return firebase.login({ email, password })
      .then(() => stopProcessing())
      .catch(() => stopProcessing('email', false))
  }, [startProcessing, stopProcessing, firebase])

  const onSubmit = handleSubmit(({ email, password }) => { loginWithEmail(email, password) })

  return (
    <Form className={styles.form} onSubmit={onSubmit}>
      <fieldset className={styles.fieldset} disabled={isProcessing as boolean}>
        <Form.Group controlId="formBasicEmail">
          <Controller
            as={Form.Control}
            control={control}
            type="email"
            placeholder={t('EMAIL')}
            name="email"
            rules={{
              required: true,
              pattern: EMAIL_PATTERN
            }}
            isInvalid={errors.email || (authError && !isProcessing && processingType === 'email' && (authError.code === FIREBASE_ERROR_CODES.WRONG_PASSWORD || authError.code === FIREBASE_ERROR_CODES.USER_DISABLED))}
          />
          {
            authError &&
            !isProcessing && processingType === 'email' &&
            (authError.code === FIREBASE_ERROR_CODES.USER_NOT_FOUND || authError.code === FIREBASE_ERROR_CODES.USER_DISABLED) &&
            <Form.Text className="text-danger">
              {tFB(authError.code)}
            </Form.Text>
          }
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Controller
            as={Form.Control}
            control={control}
            type="password"
            placeholder={t('PASSWORD')}
            name="password"
            rules={{
              required: true,
              minLength: 8
            }}
            isInvalid={errors.password || (authError && !isProcessing && processingType === 'email' && authError.code === FIREBASE_ERROR_CODES.WRONG_PASSWORD)}
          />
          {
            authError &&
            !isProcessing &&
            processingType === 'email' &&
            authError.code === FIREBASE_ERROR_CODES.WRONG_PASSWORD &&
            <Form.Text className="text-danger">
              {tFB(authError.code)}
            </Form.Text>
          }
        </Form.Group>

        <Form.Group>
          <Button variant="primary" type="submit" block>
            { (!isLoaded(auth) || (isProcessing && processingType === "email")) && <Spinner animation="border" size="sm" as="span" role="status" aria-hidden="true" className="mr-2 ml-n4 align-middle" /> }
            <span className="align-middle">{t('SUBMIT')}</span>
          </Button>
        </Form.Group>
        {
          authError && !isProcessing &&
          processingType === 'email' &&
          authError.code !== FIREBASE_ERROR_CODES.WRONG_PASSWORD &&
          authError.code !== FIREBASE_ERROR_CODES.USER_NOT_FOUND &&
          authError.code !== FIREBASE_ERROR_CODES.USER_DISABLED &&
          <p className="text-danger text-center mt-4 small">
            {tFB(authError.code, authError.message)}
          </p>
        }
        <Form.Group className="text-center mb-0">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={e => { e.preventDefault(); onRecover!()}} href="#">{t('FORGOT_PASSWORD')}</a>
        </Form.Group>

        <div className={styles.or}>
          <small>{t('OR')}</small>
        </div>
        <SocialLogin
          isProcessing={isProcessing}
          processingType={processingType}
          onStartProcessing={startProcessing}
          onStopProcessing={stopProcessing}
          loginType="LOGIN"
          provider="google"
        />
        <SocialLogin
          isProcessing={isProcessing}
          processingType={processingType}
          onStartProcessing={startProcessing}
          onStopProcessing={stopProcessing}
          loginType="LOGIN"
          provider="facebook"
        />

        {authError && !isProcessing && (processingType === 'google' || processingType === 'facebook') &&
        <p className="text-danger text-center mt-3 mb-0 small">
          {tFB(authError.code, authError.message)}
        </p>}
      </fieldset>
    </Form>
  )
}

SigninForm.defaultProps = {
  onSuccess: () => {},
  onRecover: () => {},
  onProcessing: () => {}
}