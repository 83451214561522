/**
 * Capitalize line of text
 * @example "sometext" -> "Sometext"
 * @param text
 */
export const capitalize = (text:string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

/**
 * Gererate URL-like id from latin string name
 * @example "Wa'ter-melon!" -> "watermelon"
 * @param line
 */
export const createIdFromName = (line:string) => line.replace(/[^a-zA-Z]/gi, '').toLowerCase()

/**
 * Gererate URL-like id from latin string
 * @example "Wa'ter-melon!!11" -> "water-melon11"
 * @param line
 */
export const createIdFromLine = (line:string) => line.replace(/[^a-zA-Z0-9-]/gi, '').toLowerCase()

/**
 * Generate short id based on Date
 * @example shortId('prefix-') -> "prefix-161807f6409"
 * @param prefix
 */
export const createShortId = (prefix?: string) => {
  return `${prefix || ""}${(+new Date()).toString(16)}`
}

/**
 * Truncate long Document ID into shirt version with separator (e.g. ellipsis) inside
 * @example "8DbnRDIQHaHL8y4uq3zl" -> "8Dbn…q3zl"
 * @param docId {string} Document id (20 symbols)
 * @param separator {string="…"} Separator to insert between parts
 */
export const truncDocId = (docId:string, separator:string = "…") => `${docId.slice(0, 4)}${separator || ""}${docId.slice(-4)}`

/**
 * Remove token from original auth URL
 * @example `file.png?alt=media&token=b952a0c9-1d5d-4700-b14f-4aab6496de63` -> `file.png?alt=media`
 * @param url
 */
export const removeTokenFromURL = (url: string) => url.replace(/([&?])token=[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/, "")

/**
 * Get image thumb url from original URL
 * @example `getThumbURL("https://example.com/image.png", "640")` -> `"https://example.com/thumb@640_image.png"`
 * @param originalUrl {string}
 * @param size {string}
 */
export const getThumbURL = (originalUrl: string | undefined, size?: string) => {
if (!originalUrl || !size) return originalUrl

  originalUrl = removeTokenFromURL(originalUrl)

  const fileName = decodeURIComponent(originalUrl).split("/").pop()

  if (!fileName) return originalUrl

  return originalUrl.replace(fileName, `thumb@${size}_${fileName}`)
}

export const displayCarNumber = (number: number | undefined):string | undefined => {
  if (number === undefined) return number

  return `${number > 9 ? number: "0"+number}`
}