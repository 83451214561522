import {GlobalAppState} from "redux/reducers/global"
import {AppState} from "redux/reducers"

export const getGlobalState = (store:AppState):GlobalAppState => store.global

export const getAuthPopupMode = (state: AppState) => getGlobalState(state).authPopupMode
export const getRequestDetails = (state: AppState) => getGlobalState(state).requestDetails
export const getRequestDismiss = (state: AppState) => getGlobalState(state).requestDismiss
export const getCarDelete = (state: AppState) => getGlobalState(state).carDelete
export const getDefaultSeriesId = (state: AppState) => getGlobalState(state).defaultSeriesId

export const getGlobalAlerts = (state: AppState) => getGlobalState(state).alerts

export const getReauthenticate = (state: AppState) => getGlobalState(state).reauthenticate

