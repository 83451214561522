import React, {useEffect, useState} from 'react'

import {Badge, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap"

import styles from './Profile.module.scss'
import {EmptyProfile} from "pages/Member/Profile/EmptyProfile"
import {useTranslation} from "react-i18next"
import {ProfileRules} from "pages/Member/Profile/ProfileRules"
import {useSelector} from "react-redux"
import {ProfileForm} from "pages/Member/Profile/ProfileForm"
import {Loading} from "components/Loading"
import {isEmpty, isLoaded, useFirestoreConnect} from "react-redux-firebase"
import {getProfileStatus, getProfileStatusBadge, MemberProfileStatus} from "pages/Member/Profile/status"
import {
  getMyActiveRequests,
  getUserProfile,
  queryDriverById,
  getDriverById,
  queryCarsByUid, getAuthUid, getCarsByUid
} from "redux/selectors"
import {Icon} from "components/Icon"
import {UnlinkProfile} from "pages/Member/Profile/UnlinkProfile"
import {CarSelect} from "pages/Member/Profile/CarSelect"
import {i18path} from "i18n"
import {ROUTE} from "config"

type Props = {

};

export const Profile: React.FC<Props> = (props) => {

  const { t } = useTranslation('Profile')

  const [status, setProfileStatus] = useState<MemberProfileStatus | null>(null)

  const profile = useSelector(getUserProfile)
  const uid = useSelector(getAuthUid)

  useFirestoreConnect([
    queryDriverById(profile.profileId),
    queryCarsByUid(uid)
  ])

  const publicProfile = useSelector(getDriverById(profile.profileId))
  const myActiveRequests = useSelector(getMyActiveRequests)
  const myCars = useSelector(getCarsByUid(uid))

  useEffect(() => {
    if (isLoaded(myActiveRequests, publicProfile)) {
      setProfileStatus(getProfileStatus(myActiveRequests, publicProfile))
    }
  }, [myActiveRequests, publicProfile])

  if (!isLoaded(profile, myActiveRequests, publicProfile) || status === null) return <Loading inline/>

  if (!profile.agreeWithRules) return <ProfileRules/>
  if (!profile.profileId || isEmpty(publicProfile)) return <EmptyProfile/>

  return (
    <Row className={styles.Profile}>
      <Col lg={12}>
        <h4 className="d-flex justify-content-between align-items-center">
          {t('TITLE')}
          <span className="h5 mb-0">
            {t('STATUS')}&nbsp;
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="status-tooltip">
                  {t(`STATUS_TOOLTIP.${getProfileStatusBadge(status!).title}`)}
                </Tooltip>
              }
            >
              <Badge variant={getProfileStatusBadge(status!).variant} pill>
                {t(`STATUS_NAME.${getProfileStatusBadge(status!).title}`)}
              </Badge>
            </OverlayTrigger>
            <a href={i18path(`${ROUTE.DRIVERS}/${profile.profileId}`)}
               target="_blank"
               rel="noopener noreferrer"
               className="ml-2"
               title={t('PREVIEW')}
            >
              <Icon type="openOutline"/>
            </a>
          </span>
        </h4>
        <hr/>
        <ProfileForm status={status} badge={getProfileStatusBadge(status!)} profileData={publicProfile} profileId={profile.profileId}/>
        <CarSelect cars={myCars} profileId={profile.profileId} defaultCar={publicProfile.defaultCar}/>
        <UnlinkProfile status={status} profileId={profile.profileId}/>
      </Col>
    </Row>
  )
}