import {BadgeProps} from "react-bootstrap/Badge"
import {MemberProfile, MemberRequest, MemberRequestType} from "redux/reducers"

export enum MemberProfileStatus {
  new = 'new',
  linking = 'linking',
  unlinking = 'unlinking',
  in_review = 'in_review',
  active = 'active'
}

export interface MemberProfileBadge {
  variant: BadgeProps["variant"];
  title: string;
}

const checkIsLinking = (requests:MemberRequest[]) => {
  return !!requests.find((req:MemberRequest) => req.type === MemberRequestType.PROFILE_LINK && !req.isResolved)
}

const checkIsUnlinking = (requests:MemberRequest[]) => {
  return !!requests.find((req:MemberRequest) => req.type === MemberRequestType.PROFILE_UNLINK && !req.isResolved)
}

const checkIsReviewing = (requests:MemberRequest[]) => {
  return !!requests.find((req:MemberRequest) => req.type === MemberRequestType.PROFILE_CHANGE && !req.isResolved)
}

export const getProfileStatus = (requests: MemberRequest[], profile: MemberProfile) => {
  if (requests && checkIsUnlinking(requests)) return MemberProfileStatus.unlinking
  if (requests && checkIsLinking(requests)) return MemberProfileStatus.linking
  if (requests && checkIsReviewing(requests)) return MemberProfileStatus.in_review
  if (profile && !profile.isActive) return MemberProfileStatus.new

  return MemberProfileStatus.active
}

export const getProfileStatusBadge = (status: MemberProfileStatus):MemberProfileBadge => {
  switch (status) {
    case MemberProfileStatus.new:
      return {variant: 'info', title: 'NEW'}
    case MemberProfileStatus.linking:
      return {variant: 'info', title: 'LINKING'}
      case MemberProfileStatus.unlinking:
      return {variant: 'info', title: 'UNLINKING'}
    case MemberProfileStatus.in_review:
      return {variant: 'warning', title: "IN_REVIEW"}
    case MemberProfileStatus.active:
      return {variant: 'primary', title: "ACTIVE"}
  }
}