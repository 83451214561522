import React, {useState} from 'react'
import {Button, Col, Row} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import {MemberProfileStatus} from "pages/Member/Profile/status"
import {UnlinkPopup} from "pages/Member/Profile/UnlinkPopup"

// import styles from './UnlinkProfile.module.scss'

type Props = {
  status: MemberProfileStatus;
  profileId: string;
};

export const UnlinkProfile: React.FC<Props> = ({ status, profileId}) => {

  const { t } = useTranslation('UnlinkProfile')
  const [ show, setShow ] = useState(false)

  return (
    <Row className="mb-5">
      <Col lg={10}>
        <h4>{t('TITLE')}</h4>
        <hr className="mb-4"/>
        <p className="text-medium">
          {t('TEXT')}
        </p>
        <Button
          variant="danger"
          disabled={status !== MemberProfileStatus.active}
          onClick={() => setShow(true)}
        >
          {t('SUBMIT')}
        </Button>
      </Col>
      <UnlinkPopup show={show} onHide={() => setShow(false)}/>
    </Row>
  )
}