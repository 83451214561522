import * as React from 'react'
import { Container, Table } from 'react-bootstrap'

import styles from './ResultsTable.module.scss'
import {StageStanding} from "utils"
import {DriverName} from "components/DriverName"
import classNames from "classnames"

type Props = {
  standings: StageStanding[],
  title?: string;
};

export const ResultsTable: React.FC<Props> = ({ title, standings }) => {
  return (
    <Container className={styles.ResultsTable}>
      <h3 className="brand-title mb-3">{title}</h3>

      <Table striped>
        <thead>
        <tr>
          <th scope="row" className={styles.left}>Pos</th>
          <th scope="row" className={styles.left}>Car #</th>
          <th scope="row" className={styles.driver}>Driver</th>
          <th scope="row" colSpan={3}>Qualification</th>
          <th scope="row" className={styles.points}>Qual Rank</th>
          <th scope="row" className={styles.points}>Final Rank</th>
          <th scope="row" className={styles.points}>Qual Points</th>
          <th scope="row" className={styles.points}>Final Points</th>
          <th scope="row" className={styles.points}>Total Points</th>
        </tr>
        </thead>
        <tbody>
        {standings.map((standing, i) => (
          <tr key={i}>
            <td className={styles.left}>{i+1}</td>
            <td className={styles.left}>{standing.carNumber || "-"}</td>
            <td className={styles.left}>
              <DriverName driverId={standing.driverId} driver={standing.driver}/>
            </td>
            {Array.from(Array(3).keys()).map((attempt) => (
              <td key={attempt+1} className={classNames(standing.qualification[attempt] === standing.bestQual && "font-weight-bold")}>{standing.qualification[attempt] || "-"}</td>
            ))}
            <td>{standing.qualRank}</td>
            <td>{standing.finalRank}</td>
            <td>{standing.qualPoints}</td>
            <td>{standing.finalPoints}</td>
            <td>{standing.total}</td>
          </tr>
        ))}
        </tbody>
      </Table>
    </Container>
  )
}

ResultsTable.defaultProps = {
  title: "All Results"
}