import * as React from 'react'
import classNames from 'classnames'

import { Container, Row, Col, Nav } from 'react-bootstrap'

import { Logo } from "svg"
import styles from './Footer.module.scss'
import {Icon} from "components"

type Props = {

};

export const Footer: React.FC<Props> = (props) => {
  return (
    <footer className={classNames(styles.Footer, "footer mt-auto py-3 footer-secondary")}>
      <Container>
        <Row className="align-items-center">
          <Col><Logo width={75} height={30} className={styles.Logo}/></Col>
          <Col className="d-flex align-items-center justify-content-end">Connect with us:
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><Icon type="logoFacebook" className={styles.socialIcon}/></a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><Icon type="logoInstagram" className={styles.socialIcon}/></a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer"><Icon type="logoYoutube" className={styles.socialIcon}/></a>
          </Col>
        </Row>
        <hr/>
        <Row className="justify-content-between align-items-center">
          <Col className="text-muted">&copy; 2020 UDRC &mdash; Ukrainian Drift RC</Col>
          <Col>
            <Nav className="justify-content-end">
              <Nav.Link href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy policy</Nav.Link>
              <Nav.Link href="/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of use</Nav.Link>
              <Nav.Link href="#">Back to top</Nav.Link>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}