import React from 'react'

import {Icon} from "components/Icon"
import {Button, Col, Row} from "react-bootstrap"
import {useTranslation} from "react-i18next"

import styles from './Garage.module.scss'
import {LinkContainer} from "react-router-bootstrap"

type Props = {
  baseUrl: string;
};

export const EmptyCarsList: React.FC<Props> = ({ baseUrl }) => {

  const { t } = useTranslation('Garage')

  return (
    <Row className={styles.Garage}>
      <Col lg={10}>
        <h4>{t('TITLE.GARAGE')}</h4>
        <hr/>
        <div className="d-flex flex-column align-items-center p-4 px-5">
          <Icon type="garagePic" className={styles.pic}/>
          <h5 className="text-center mb-3">{t('EMPTY.TITLE')}</h5>
          <p className="text-center mb-5 text-muted">{t('EMPTY.TEXT')}</p>
          <div className="mt-n3 d-flex justify-content-center">
            <LinkContainer to={`${baseUrl}/new`}><Button>{t('BUTTON.ADD_FIRST')}</Button></LinkContainer>
          </div>
        </div>
      </Col>
    </Row>
  )
}