import * as React from 'react'
import { Container, Row, Col, Table, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { ROUTE } from 'config'

import { UserPlaceholder } from 'svg'

import styles from './Referees.module.scss'
import {i18path} from "i18n"

type Props = {
  
};

export const Referees: React.FC<Props> = (props) => {
  return (
    <div className={styles.Referees}>
      <Container>
        <Row>

          <Col md={4}>
            <h4 className="brand-title mb-4 tbd">Qualifying Points</h4>
            <Table className={styles.pointTable}>
              <thead>
              <tr>
                <th scope="row">Rank</th>
                <th scope="row">Points</th>
                <th scope="row">Rank</th>
                <th scope="row">Points</th>
                <th scope="row">Rank</th>
                <th scope="row">Points</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>1</td>
                <td>25</td>
                <td>6</td>
                <td>13</td>
                <td>11</td>
                <td>6</td>
              </tr>
              <tr>
                <td>2</td>
                <td>21</td>
                <td>7</td>
                <td>11</td>
                <td>12</td>
                <td>5</td>
              </tr>
              <tr>
                <td>3</td>
                <td>19</td>
                <td>8</td>
                <td>9</td>
                <td>13-16</td>
                <td>4</td>
              </tr>
              <tr>
                <td>4</td>
                <td>17</td>
                <td>9</td>
                <td>8</td>
                <td>17-20</td>
                <td>2</td>
              </tr>
              <tr>
                <td>5</td>
                <td>15</td>
                <td>10</td>
                <td>7</td>
                <td>21-32</td>
                <td>1</td>
              </tr>

              </tbody>
            </Table>
          </Col>
          <Col md={4}>
            <h4 className="brand-title mb-4 tbd">Competition Points</h4>
            <Table className={styles.pointTable}>
              <thead>
              <tr>
                <th scope="row">Rank</th>
                <th scope="row">Points</th>
                <th scope="row">Rank</th>
                <th scope="row">Points</th>
                <th scope="row">Rank</th>
                <th scope="row">Points</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>1</td>
                <td>200</td>
                <td>4</td>
                <td>140</td>
                <td>5-8</td>
                <td>110</td>
              </tr>
              <tr>
                <td>2</td>
                <td>180</td>
                <td>9-12</td>
                <td>80</td>
                <td>17-22</td>
                <td>20</td>
              </tr>
              <tr>
                <td>3</td>
                <td>160</td>
                <td>13-16</td>
                <td>40</td>
                <td>23-32</td>
                <td>10</td>
              </tr>

              </tbody>
            </Table>
          </Col>

          <Col md={4}>
            <h4 className="brand-title mb-4 tbd">Stage Referees</h4>

            <Row className={styles.referee}>
              <Col sm={4} className={styles.refereePhoto}>
                <Link to={i18path(`${ROUTE.REFEREES}/korniets`)} className={styles.refereeLink}>
                  <Image fluid src="/images/headshots/revus.png"/>
                </Link>
              </Col>
              <Col>
                <Link to={i18path(`${ROUTE.REFEREES}/korniets`)} className={styles.refereeLink}>
                  <p className="mb-1">Sergey Korniets</p>
                  <p className="text-muted mb-0">Kharkiv</p>
                </Link>
              </Col>
            </Row>

            <Row className={styles.referee}>
              <Col sm={4} className={styles.refereePhoto}>
                <Link to={i18path(`${ROUTE.REFEREES}/volnianskiy`)} className={styles.refereeLink}>
                  <UserPlaceholder className={styles.placeholder}/>
                </Link>
              </Col>
              <Col>
                <Link to={i18path(`${ROUTE.REFEREES}/volnianskiy`)} className={styles.refereeLink}>
                  <p className="mb-1">Oleksander Volnianskiy</p>
                  <p className="text-muted mb-0">Dnipro</p>
                </Link>
              </Col>
            </Row>

            <Row className={styles.referee}>
              <Col sm={4} className={styles.refereePhoto}>
                <Link to={i18path(`${ROUTE.REFEREES}/necros`)} className={styles.refereeLink}>
                  <UserPlaceholder className={styles.placeholder}/>
                </Link>
              </Col>
              <Col>
                <Link to={i18path(`${ROUTE.REFEREES}/volnianskiy`)} className={styles.refereeLink}>
                  <p className="mb-1">Nekros Huekros</p>
                  <p className="text-muted mb-0">Kyiv</p>
                </Link>
              </Col>
            </Row>

          </Col>
        </Row>
      </Container>
    </div>
  )
}