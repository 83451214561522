import React, {useCallback, useEffect, useState} from 'react'
import {Icon} from "components/Icon"
import {useDispatch} from "react-redux"
import {showAlert} from "redux/actions/GlobalActions"
import {useTranslation} from "react-i18next"
import classNames from "classnames"

const copyToClipboard = (text: string) => {
  const el = document.createElement('textarea')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  text: string;
  alertKey?: string;
  showTick?:boolean;
}

export const CopyText: React.FC<Props> = ({ text, alertKey, showTick, className, ...props}) => {

  const { t } = useTranslation('alerts')
  const dispatch = useDispatch()
  
  const [ copied, setCopied ] = useState(false)

  useEffect(() => {
    if (copied) window.setTimeout(() => setCopied(false), 3000)
  }, [copied])
  
  const copy = useCallback((e:React.MouseEvent) => {
    copyToClipboard(text)
    if (alertKey) dispatch(showAlert({ content: t(alertKey!) }))
    if (showTick) {
      setCopied(true)
    }
    e.preventDefault()
  }, [text, alertKey, dispatch, t, showTick])

  return (
    <span className={classNames(className, "text-nowrap")} {...props}>
      {props.children}
      {copied ? 
        <Icon type="checkMarkOutline" className="ml-2"/>:
        <a href="#/" onClick={copy} className="ml-2">
          <Icon type="copyOutline"/>
        </a>
      }
    </span>
  )
}

CopyText.defaultProps = {
  alertKey: 'SETTINGS.UID_COPIED',
  showTick: false
}