import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {Button, Form, Modal, Spinner} from "react-bootstrap"
import {useFirebase, useFirestore} from "react-redux-firebase"
import {Controller, useForm} from "react-hook-form"
import {useDispatch, useSelector} from "react-redux"
import {useTranslation} from "react-i18next"
import {getAuthUid, getRequestDismiss} from "redux/selectors"
import {closeDismissPopup, showAlert} from "redux/actions"

import styles from './Requests.module.scss'
import {CountDownTimer} from "components/CountDownTimer"
import {DISMISS_REQUEST_DELAY} from "config"
import {dismissRequest} from "utils"

type Props = {

};

export const DismissPopup: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation('DismissPopup')
  const dispatch = useDispatch()
  const firebase = useFirebase()
  const firestore = useFirestore()
  const uid = useSelector(getAuthUid)
  const requestDismiss = useSelector(getRequestDismiss)

  // form validation hook & state
  const { handleSubmit, control, errors, watch } = useForm()
  // form processing state
  const [ isProcessing, setProcessing] = useState(false)
  const [ finished, setFinished ] = useState(false)

  const confirm = watch('confirm', false)

  useEffect(() => {
    if (requestDismiss) setFinished(false)
  }, [requestDismiss])

  const enabled = useMemo(() => finished && confirm, [finished, confirm])

  const handleHide = useCallback(() => {
    dispatch(closeDismissPopup())
  }, [dispatch])

  const handleDismiss = useCallback((reason: string) => {
    if (!finished || !confirm) return

    setProcessing(true)

    dismissRequest(uid, requestDismiss!, firestore, firebase, reason).then(()=>{
      dispatch(showAlert({content: t('alerts:REQUESTS.DISMISSED')}))
    }).catch((error) => {
      dispatch(showAlert({ content: t(error, error), props: { variant: "danger" }}))
    }).finally(() => {
      setProcessing(false)
      handleHide()
    })
  }, [finished, confirm, uid, requestDismiss, firestore, firebase, dispatch, t, handleHide])

  const onSubmit = handleSubmit(({ reason }) => handleDismiss(reason))
  
  return (
    <Modal show={requestDismiss !== null} onHide={handleHide} backdrop={!isProcessing || 'static'} keyboard={!isProcessing}>
      <Form onSubmit={onSubmit}>
        <fieldset disabled={isProcessing}>
          <Modal.Header>
            <Modal.Title as="h5">{t('TITLE')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label className="required">{t('LABEL')}</Form.Label>
              <Controller
                control={control}
                as={<Form.Control as="textarea" rows="2"/>}
                name="reason"
                placeholder={t('PLACEHOLDER')}
                rules={{
                  required: true,
                  minLength: 5,
                  maxLength: 75
                }}
                className={styles.reason}
                isInvalid={errors.reason}
              />
              <Form.Text className={errors.reason ? "text-danger": "text-muted"}>
                {t('TEXT')}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Controller
                control={control}
                as={Form.Check}
                type="checkbox"
                id="confirm"
                name="confirm"
                valueName={"checked"}
                label={t('CONFIRM')}
                defaultValue={false}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="mr-2" type="submit" variant="danger" disabled={isProcessing || !enabled}>
              { isProcessing ?
                <Spinner animation="border" size="sm" as="span" role="status" aria-hidden="true" className="mr-2 align-middle" />:
                !finished && <CountDownTimer max={DISMISS_REQUEST_DELAY} timeout={DISMISS_REQUEST_DELAY} onEnd={() => setFinished(true)} className="mr-2"/>
              }
              <span className="align-middle">{t('DISMISS')}</span>
            </Button>
            <Button variant="secondary" onClick={handleHide}>{t('CANCEL')}</Button>
          </Modal.Footer>
        </fieldset>
      </Form>
    </Modal>
  )
}