import data from "data/geo.json"

export function getCountriesSelection() {
  return data.countries.map((c:string) => ({ value: c, label: c}))
}

export function getCitiesSelection(country?: string) {
  // @ts-ignore
  if (country) {
    // @ts-ignore
    return [...(data.cities[country] || []).sort().map((c:string) => ({ value: c, label: c})), { value: "Other", label: "Other"}]
  } else return []
}