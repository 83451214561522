import React, {useCallback, useEffect, useState} from 'react'

import { useForm, Controller } from 'react-hook-form'
import {useDispatch, useSelector} from "react-redux"
import {isLoaded, useFirebase} from "react-redux-firebase"
import { Button, Form, Spinner } from 'react-bootstrap'

import {ANIMATION_TIMEOUT, FIREBASE_ERROR_CODES, EMAIL_PATTERN} from "config"
import {showAlert} from "redux/actions"

import {SocialLogin} from "./SocialLogin"

import styles from './AuthPopup.module.scss'
import {verificationEmailSent} from "redux/actions/UserActions"
import {useTranslation} from "react-i18next"
import {getAuth, getAuthError} from "redux/selectors"

export type ProcessingType = 'email'|'google'|'facebook'
type ProcessingState = { isProcessing: boolean; processingType: ProcessingType | null }

interface Props {
  onSuccess?: () => void;
  onProcessing?: (processing: boolean) => void;
}

export const SignupForm: React.FC<Props> = ({ onSuccess, onProcessing }) => {

  const { t, i18n } = useTranslation('SignupForm')
  const tFB = i18n.getFixedT(null, 'firebase')
  const dispatch = useDispatch()
  const firebase = useFirebase()
  const auth = useSelector(getAuth)
  const authError = useSelector(getAuthError)

  // form validation hook & state
  const { handleSubmit, control, errors, watch } = useForm()
  // form processing state
  const [{ isProcessing, processingType }, setProcessing] = useState<ProcessingState>({ isProcessing: false, processingType: null })

  useEffect(() => {
    onProcessing!(isProcessing)
  }, [isProcessing, onProcessing])

  const handleSuccess = useCallback((processingType: ProcessingType) => {
    setTimeout(() => {
      dispatch(showAlert({content: t('alerts:AUTH.SIGNUP_SUCCESS'), props: { variant: "success"}}))
    }, ANIMATION_TIMEOUT)

    // send verification email if signed up with email
    if (processingType === 'email') {
      firebase.auth().onAuthStateChanged(function(user) {
        user?.sendEmailVerification()
        dispatch(verificationEmailSent(Date.now()))
      })
      dispatch(showAlert({content: t('alerts:AUTH.SIGNUP_VERIFICATION'), props: { variant: "success"}}))
    }

    setProcessing({ isProcessing: false, processingType: null })
    onSuccess!()
  }, [onSuccess, dispatch, t, firebase, setProcessing])

  // form processing methods
  const startProcessing = useCallback((processingType: ProcessingType = 'email') =>
    setProcessing({ isProcessing: true, processingType }), [setProcessing])
  const stopProcessing = useCallback((processingType: ProcessingType = 'email', success: boolean = true) => {
    setProcessing({ isProcessing: false, processingType })
    if (success) handleSuccess(processingType)
  }, [setProcessing, handleSuccess])

  const signupWithEmail = useCallback((name: string, email: string, password: string) => {
    startProcessing()

    firebase.createUser({ email, password }, { displayName: name, email })
      .then(() => stopProcessing())
      .catch(() => stopProcessing('email', false))
  }, [startProcessing, firebase, stopProcessing])

  const onSubmit = handleSubmit(({ name, email, password }) => { signupWithEmail(name, email, password) })

  return (
    <Form className={styles.form} onSubmit={onSubmit}>
      <fieldset className={styles.fieldset} disabled={isProcessing as boolean}>
        <Form.Group controlId="formFullName">
          <Controller
            as={Form.Control}
            control={control}
            type="text"
            placeholder={t('NAME')}
            name="name"
            rules={{
              required: true,
              minLength: 10
            }}
            isInvalid={errors.name}
          />
          <Form.Text className="text-muted">{t('NAME_COMMENT')}</Form.Text>
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Controller
            as={Form.Control}
            control={control}
            type="email"
            placeholder={t('EMAIL')}
            name="email"
            rules={{
              required: true,
              pattern: EMAIL_PATTERN
            }}
            isInvalid={errors.email || (authError && !isProcessing && processingType === 'email' && (authError.code === FIREBASE_ERROR_CODES.ALREADY_IN_USE))}
          />
          {
            authError &&
            !isProcessing && processingType === 'email' &&
            (authError.code === FIREBASE_ERROR_CODES.ALREADY_IN_USE) &&
            <Form.Text className="text-danger">
              {tFB(authError.code)}
            </Form.Text>
          }
        </Form.Group>

        <Form.Group controlId="formPassword">
          <Controller
            as={Form.Control}
            control={control}
            type="password"
            placeholder={t('PASSWORD')}
            name="password"
            rules={{
              required: true,
              minLength: 8
            }}
            isInvalid={errors.password}
          />
        </Form.Group>

        <Form.Group controlId="formConfirmPassword">
          <Controller
            as={Form.Control}
            control={control}
            type="password"
            placeholder={t('CONFIRM_PASSWORD')}
            name="confirmPassword"
            rules={{
              required: true,
              validate: (value) => value === watch('password')
            }}
            isInvalid={errors.confirmPassword}
          />
        </Form.Group>

        <Form.Group>
          <Button variant="primary" type="submit" block>
            { (!isLoaded(auth) || (isProcessing && processingType === "email")) && <Spinner animation="border" size="sm" as="span" role="status" aria-hidden="true" className="mr-2 ml-n4 align-middle" /> }
            <span className="align-middle">{t('SUBMIT')}</span>
          </Button>
        </Form.Group>
        {
          authError && !isProcessing &&
          processingType === 'email' &&
          authError.code !== FIREBASE_ERROR_CODES.ALREADY_IN_USE &&
          <p className="text-danger text-center mt-4 small">
            {tFB(authError.code, authError.message)}
          </p>
        }

        <div className={styles.or}>
          <small>{t('OR')}</small>
        </div>
        <SocialLogin
          isProcessing={isProcessing}
          processingType={processingType}
          onStartProcessing={startProcessing}
          onStopProcessing={stopProcessing}
          loginType="LOGIN"
          provider="google"
        />
        <SocialLogin
          isProcessing={isProcessing}
          processingType={processingType}
          onStartProcessing={startProcessing}
          onStopProcessing={stopProcessing}
          loginType="LOGIN"
          provider="facebook"
        />

        {authError && !isProcessing && (processingType === 'google' || processingType === 'facebook') &&
        <p className="text-danger text-center mt-3 mb-0 small">
          {tFB(authError.code, authError.message)}
        </p>}
      </fieldset>
    </Form>
  )
}

SignupForm.defaultProps = {
  onSuccess: () => {},
  onProcessing: () => {}
}