import React, {useCallback, useEffect, useState} from 'react'
import {Badge, Col, Form, InputGroup, Row} from "react-bootstrap"
import {useDispatch, useSelector} from "react-redux"
import {Controller, useForm} from "react-hook-form"
import {hideAlert, openAuthPopup, showAlert} from "redux/actions/GlobalActions"
import {FIREBASE_ERROR_CODES, AUTH_VARIANT, EMAIL_PATTERN, REAUTH_TIMEOUT} from "config"
import {useFirebase} from "react-redux-firebase"
import {verificationEmailSent} from "redux/actions/UserActions"
import {ResendButton} from "pages/Member/Settings/ResendButton"
import {useTranslation} from "react-i18next"
import {getAuthError, getReauthenticate, getUserProfile} from "redux/selectors"
import {REAUTH_ALERT_ID, reauthenticateAlert} from "pages/Member/Settings/reauthenticateAlert"
import {SmartButton} from "components"

export type ProcessingType = 'update'|'verify'
type ProcessingState = { isProcessing: boolean; processingType: ProcessingType | null }

type Props = {

}


export const EmailSettings: React.FC<Props> = (props) => {

  const dispatch = useDispatch()
  const { t } = useTranslation('EmailSettings')
  const firebase = useFirebase()
  const profile = useSelector(getUserProfile)
  const authError = useSelector(getAuthError)

  // form validation hook & state
  const { handleSubmit, errors, control, reset, formState: { dirtyFields } } = useForm({
    defaultValues: { email: profile.email }
  })

  useEffect(() => {
    reset({ email: profile.email })
  }, [reset, profile.email])

  // form processing state
  const [{ isProcessing, processingType }, setProcessing] = useState<ProcessingState>({ isProcessing: false, processingType: null })
  const [ isWaitingAuth, setWaitingAuth ] = useState<{resolve:()=>void, reject:()=>void} | null>(null)

  // waiting for reauth
  const reauthenticate = useSelector(getReauthenticate)

  useEffect(() =>{
    if (reauthenticate && isWaitingAuth) isWaitingAuth.resolve()
  }, [reauthenticate, isWaitingAuth])

  // form processing methods
  const startProcessing = useCallback((type: ProcessingType = 'update') =>
    setProcessing({ isProcessing: true, processingType: type }), [setProcessing])
  const stopProcessing = useCallback((type: ProcessingType = 'update', success: boolean = true) =>
    setProcessing({ isProcessing: false, processingType: success ? null : type }), [setProcessing])

  const handleSuccessUpdate = useCallback(() => {
    dispatch(showAlert({content: t('alerts:SETTINGS.EMAIL'), props: { variant: "success"}}))

    firebase.auth().currentUser?.sendEmailVerification()
    dispatch(verificationEmailSent(Date.now()))

    stopProcessing('update')
  }, [dispatch, stopProcessing, firebase, t])

  const updateEmailAddress = useCallback((email: string) => {
    if (email === profile.email) return

    startProcessing()

    firebase.updateEmail(email, true)
      .then(() => {handleSuccessUpdate()})
      .catch((e) => {
        if (e.code === FIREBASE_ERROR_CODES.REAUTH_REQUIRED) {
          dispatch(openAuthPopup(AUTH_VARIANT.CONFIRM))

          new Promise((resolve, reject) => {
            setWaitingAuth({resolve, reject})
            window.setTimeout(()=>reject(), REAUTH_TIMEOUT)
          })
          .then(() => { updateEmailAddress(email) })
          .catch(() => {
            reauthenticateAlert(dispatch, t, (e) => {
              e.preventDefault()
              dispatch(hideAlert(REAUTH_ALERT_ID))
              dispatch(openAuthPopup(AUTH_VARIANT.CONFIRM))
            })

            stopProcessing('update', false)
            reset({ email: profile.email })
          })
          .finally(() => setWaitingAuth(null))

        } else {
          console.log(e)
          stopProcessing('update', false)
        }
      })
  }, [dispatch, t, reset, startProcessing, stopProcessing, handleSuccessUpdate, firebase, profile, setWaitingAuth])

  const onSubmit = handleSubmit(({ email }) => { updateEmailAddress(email) })

  return (
    <Row className="mb-5">
      <Col lg={10}>
        <h4>{t('TITLE')}</h4>
        <hr/>
        <Form onSubmit={onSubmit}>
          <fieldset disabled={isProcessing as boolean}>
            <Form.Group>
              <Form.Label>
                {t('INPUT.LABEL')}
                {!profile.isVerified &&
                  <Badge variant="danger" pill className="ml-1">!</Badge>}
              </Form.Label>
              <InputGroup>
                <Controller
                  as={Form.Control}
                  control={control}
                  type="email"
                  placeholder={t('INPUT.PLACEHOLDER')}
                  name="email"
                  rules={{
                    required: true,
                    pattern: EMAIL_PATTERN
                  }}
                  isInvalid={errors.email || (authError && !isProcessing && processingType === 'update')}
                />
                {!profile.isVerified &&
                  <InputGroup.Append>
                    <ResendButton
                      onStartProcessing={() => startProcessing('verify')}
                      onStopProcessing={() => stopProcessing('verify')}
                    />
                  </InputGroup.Append>
                }
              </InputGroup>
              {
                authError &&
                !isProcessing && processingType === 'update' &&
                <Form.Text className="text-danger">
                  {authError.message}
                </Form.Text>
              }
              {profile.isVerified ?
                <Form.Text muted>
                  {t('INPUT.TEXT')}
                </Form.Text> :
                <Form.Text className="text-danger">
                  {t('INPUT.NOT_VERIFIED')}
                </Form.Text>
              }
            </Form.Group>
            <SmartButton
              isProcessing={isProcessing && processingType === "update"}
              variant="primary"
              type="submit"
              disabled={!dirtyFields.size}>
              {t('SUBMIT')}
            </SmartButton>
          </fieldset>
        </Form>
      </Col>
    </Row>
  )
}