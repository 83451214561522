export enum CarSpecsComponents {
  chassis = "chassis",
  motor = "motor",
  esc = "esc",
  servo = "servo",
  gyro = "gyro",
  radio = "radio",
  body = "body"
}

export const CarSpecsDetails:{[key:string]:{
    required: boolean;
    editable: boolean;
    delimiter?: boolean;
  }} = {
  [CarSpecsComponents.chassis]: {
    required: true,
    editable: false,
  },
  [CarSpecsComponents.motor]: {
    required: true,
    editable: true,
  },
  [CarSpecsComponents.esc]: {
    required: true,
    editable: true,
  },
  [CarSpecsComponents.servo]: {
    required: true,
    editable: true,
  },
  [CarSpecsComponents.gyro]: {
    required: true,
    editable: true,
  },
  [CarSpecsComponents.radio]: {
    required: false,
    editable: true,
    delimiter: true
  },
  [CarSpecsComponents.body]: {
    required: false,
    editable: true,
  }
}