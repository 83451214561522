import React, {useState} from 'react'
import {Button, Spinner} from "react-bootstrap"
import {Icon} from "components/Icon"
import {ProcessingType} from "components/Header/AuthPopup/SigninForm"
import {useDispatch, useSelector} from "react-redux"
import {getVerificationSentOn} from "redux/selectors/UserSelectors"
import {verificationEmailSent} from "redux/actions/UserActions"
import {showAlert} from "redux/actions"
import {useFirebase} from "react-redux-firebase"
import {RESEND_TIMEOUT} from "config"
import {CountDownTimer} from "components/CountDownTimer"
import {useTranslation} from "react-i18next"

type Props = {
  onStartProcessing: (processingType?: ProcessingType) => void;
  onStopProcessing: (processingType?: ProcessingType, success?: boolean) => void;
};

export const ResendButton: React.FC<Props> = ({
    onStartProcessing,
    onStopProcessing,
  }) => {

  const dispatch = useDispatch()
  const { t } = useTranslation('EmailSettings')

  const [isProcessing, setProcessing] = useState(false)

  const handleSuccessVerify = () => {
    dispatch(showAlert({content: 'alerts:AUTH.SIGNUP_VERIFICATION', props: { variant: "success"}}))
    dispatch(verificationEmailSent(Date.now()))
    stopProcessing()
  }

  const handleFailVerify = () => {
    dispatch(showAlert({content: 'alerts:GENERAL_ERROR', props: { variant: "danger"}}))
    stopProcessing()
  }

  // form processing methods
  const startProcessing = () => {
    setProcessing(true)
    onStartProcessing!()
  }
  const stopProcessing = () => {
    setProcessing(false)
    onStopProcessing!()
  }

  const firebase = useFirebase()

  const lastSent = useSelector(getVerificationSentOn)
  const resendCountDown = lastSent && (Date.now() - lastSent < RESEND_TIMEOUT) ? Math.floor((RESEND_TIMEOUT - (Date.now() - lastSent))/1000) : 0

  const sendVerification = () => {
    startProcessing()
    return firebase.auth().currentUser?.sendEmailVerification().then(() => {
      handleSuccessVerify()
    }).catch((e) => {
      handleFailVerify()
    })
  }

  return (
    <Button variant="outline-light" size="sm" onClick={sendVerification} disabled={!!resendCountDown}>
      {!!resendCountDown ?
        <CountDownTimer max={RESEND_TIMEOUT/1000} timeout={resendCountDown} onEnd={() => {dispatch(verificationEmailSent(null))}} className="mr-2"/> : (
          isProcessing ? <Spinner animation="border" size="sm" as="span" role="status" aria-hidden="true"
                                   className="mr-2 align-middle"/> :
              <Icon type="mailOutline" className="mr-2"/>

        )
      }
      <span className="align-middle">{t('RESEND')}</span>
    </Button>
  )
}