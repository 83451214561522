import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from "react-redux"
import { PersistGate } from 'redux-persist/integration/react'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore' // <- needed if using firestore
import 'firebase/storage'  // <- needed if using storage
import 'firebase/analytics'
import 'firebase/functions'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore' // <- needed if using firestore

import { configureStore } from 'redux/store'
import * as serviceWorker from './serviceWorker'
import {FIREBASE as firebaseConfig, FIREBASE_REGION} from 'config'

import './i18n'

import 'styles/index.scss'
import App from 'App'
import {Loading} from "components/Loading"

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  // enableClaims: true // Get custom claims along with the profile
}

// Initialize firebase instance
firebase.initializeApp(firebaseConfig)

// Initialize other services on firebase instance
firebase.firestore()
firebase.analytics()
firebase.app().functions(FIREBASE_REGION)

const { persistor, store } = configureStore()

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <PersistGate persistor={persistor}>
          {(ready: boolean) => (ready ? <App /> : <Loading/>)}
        </PersistGate>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
