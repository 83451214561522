import * as React from 'react'
import { Container, Button } from 'react-bootstrap'

import { ROUTE } from 'config'

import styles from './StagesList.module.scss'
import {EventStage} from "redux/reducers"
import {LinkContainer} from "react-router-bootstrap"
import {formatDateToReadable} from "utils"
import {i18path} from "i18n"

type Props = {
  events: EventStage[]
};

export const StagesList: React.FC<Props> = ({ events }) => {
  return (
    <>
    <div className={styles.StagesList}>
      <Container>
        <div className="text-center">
          <h4 className={styles.title}>Stage Bracket Results</h4>
          <ul className={styles.brackets}>
            {events.map((event, index) => (
              <li key={event.id}>
                <span className={styles.round}>Stage {index+1}</span>
                <p className="text-muted text-nowrap">{event.city}, {formatDateToReadable(event.date)}</p>
                {event.finished ?
                  <LinkContainer to={i18path(`${ROUTE.STANDINGS}/${event.series}/${event.id}`)}><Button variant="outline-primary">View
                    Results</Button></LinkContainer> :
                  <LinkContainer to={i18path(`${ROUTE.SCHEDULE}`)}><Button variant="outline-secondary">View
                    Schedule</Button></LinkContainer>
                }
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </div>
    </>
  )
}