import {AppState, SiteContentType, SiteContentStatus, MemberRequest, Car, CarStatus} from "redux/reducers"
import {ReduxFirestoreQuerySetting} from "react-redux-firebase"

// export const getFirestoreState = (state:AppState) => state.firestore;
export const getFirestoreData = (state:AppState) => state.firestore.data
export const getFirestoreOrdered = (state:AppState) => state.firestore.ordered

export const getDefaultSeries = (state:AppState) => getFirestoreOrdered(state).defaultSeries
export const getSeriesById = (seriesId: string) => (state:AppState) => getFirestoreData(state)[`series-${seriesId}`]
export const getDriverById = (driverId: string) => (state:AppState) => getFirestoreData(state)[`drivers-${driverId}`]
export const getResultsBySeriesId = (seriesId: string) => (state:AppState) => getFirestoreOrdered(state)[`results-${seriesId}`]
export const getResultsByEventId = (eventId: string) => (state:AppState) => getFirestoreOrdered(state)[`results-event-${eventId}`]
export const getSpecsByDriverId = (driverId: string) => (state:AppState) => getFirestoreOrdered(state)[`specs-${driverId}`]
export const getRacesByEventId = (eventId: string) => (state:AppState) => getFirestoreOrdered(state)[`races-${eventId}`]
export const getEventById = (eventId: string) => (state:AppState) => getFirestoreData(state)[`events-${eventId}`]

// export const getPublicProfile = (state:AppState) => getFirestoreData(state).publicProfile

export const getAllProfiles = (state:AppState) => getFirestoreOrdered(state).allProfiles
export const getAllProfilesData = (state:AppState) => getFirestoreData(state).allProfiles
export const getActiveDrivers = (state:AppState) => getFirestoreOrdered(state).activeDrivers
export const getActiveDriversData = (state:AppState) => getFirestoreData(state).activeDrivers

export const getActiveRequests = (state:AppState) => getFirestoreOrdered(state).activeRequests
export const getOthersActiveRequests = (uid:string) =>
  (state:AppState) => (getActiveRequests(state) || []).filter((r: MemberRequest) => r.author.id !== uid && (!r.resolverId || r.resolverId === uid) && (!r.votes || !r.votes[uid]))
export const getOthersActiveRequestCount = (uid: string) => (state:AppState) => getOthersActiveRequests(uid)(state).length

export const getMyRequests = (state:AppState) => getFirestoreOrdered(state).myRequests

// todo: be carefull with "|| []"
export const getMyActiveRequests = (state:AppState) => (getMyRequests(state) || []).filter((r:MemberRequest) => !r.isResolved)
export const getMyResolvedRequests = (state:AppState) => (getMyRequests(state) || []).filter((r:MemberRequest) => r.isResolved)

export const getOneContentByType = (type: SiteContentType, locale: string) => (state:AppState) => getFirestoreOrdered(state)[`content-one-${type}-${locale}`]
export const getAllContentByType = (type: SiteContentType, locale: string) => (state:AppState) => getFirestoreOrdered(state)[`content-${type}-${locale}`]
export const getContentById = (contentId: string) => (state:AppState) => getFirestoreData(state)[`content-${contentId}`]

export const getAllVendors = (state:AppState) => getFirestoreOrdered(state).allVendors
export const getCarsByUid = (uid:string) => (state:AppState) => getFirestoreOrdered(state)[`cars-owner-${uid}`]

export const getCarById = (id:string) => (state:AppState) => getFirestoreData(state)[`cars-${id}`]
export const getActiveCars = (state:AppState) => getFirestoreOrdered(state).activeCars


export const queryDriverById = (driverId:string):ReduxFirestoreQuerySetting => ({
  collection: 'profiles',
  doc: driverId,
  storeAs: `drivers-${driverId}`
})

export const querySpecsByDriverId = (driverId: string):ReduxFirestoreQuerySetting => ({
  collection: 'specs',
  where: [
    ['published', '==', true],
    ['driver', '==', driverId]
  ],
  limit: 1,
  storeAs: `specs-${driverId}`
})

export const queryResultsBySeriesId = (seriesId: string):ReduxFirestoreQuerySetting => ({
  collection: 'results',
  where: [
    ['series', '==', seriesId],
  ],
  storeAs: `results-${seriesId}`
})

export const queryResultsByEventId = (eventId: string):ReduxFirestoreQuerySetting => ({
  collection: 'results',
  where: [
    ['event', '==', eventId],
  ],
  storeAs: `results-event-${eventId}`
})

export const querySeriesById = (seriesId: string):ReduxFirestoreQuerySetting => ({
  collection: 'series',
  doc: seriesId,
  storeAs: `series-${seriesId}`
})

export const queryEventById = (eventId: string):ReduxFirestoreQuerySetting => ({
  collection: 'events',
  doc: eventId,
  storeAs: `events-${eventId}`
})

export const queryRacesByEventId = (eventId: string):ReduxFirestoreQuerySetting => ({
  collection: 'races',
  where: [
    ['event', '==', eventId],
  ],
  storeAs: `races-${eventId}`
})

export const queryDefaultSeries:ReduxFirestoreQuerySetting = { collection: 'series',
  where: [
    ['default', '==', true],
    ['active', '==', true]
  ],
  limit: 1,
  storeAs: "defaultSeries"
}

export const queryMyRequests = (uid: string):ReduxFirestoreQuerySetting => ({
  collection: "requests",
  where: [
    ['author.id', '==', uid],
    ['isAgreed', '==', false],
  ],
  storeAs: "myRequests"
})

export const queryActiveRequests:ReduxFirestoreQuerySetting = {
  collection: "requests",
  where: [
    ['isResolved', '==', false],
  ],
  orderBy: ["createdAt", "desc"],
  storeAs: "activeRequests"
}

export const queryAllProfiles:ReduxFirestoreQuerySetting = {
  collection: "profiles",
  storeAs: "allProfiles"
}

export const queryActiveDrivers:ReduxFirestoreQuerySetting = {
  collection: "profiles",
  where: [
    ['isActive', '==', true],
    ['isDriver', '==', true],
  ],
  limit: 30,
  storeAs: "activeDrivers"
}

export const queryActiveCars:ReduxFirestoreQuerySetting = {
  collection: "cars",
  where: [
    ['status', '==', 'active'],
  ],
  // orderBy: ["createdAt", "asc"],
  limit: 30,
  storeAs: "activeCars"

}

export const queryAllContentByType = (type: SiteContentType, locale: string):ReduxFirestoreQuerySetting => ({
  collection: "content",
  where: [
    ['type', '==', type],
    ['locale', '==', locale],
    ['status', '==', SiteContentStatus.published]
  ],
  storeAs: `content-${type}-${locale}`
})

export const queryOneContentByType = (type: SiteContentType, locale: string):ReduxFirestoreQuerySetting => ({
  collection: "content",
  where: [
    ['type', '==', type],
    ['locale', '==', locale],
    ['status', '==', SiteContentStatus.published]
  ],
  limit: 1,
  storeAs: `content-one-${type}-${locale}`
})

export const queryContentById = (contentId: string):ReduxFirestoreQuerySetting => ({
  collection: "content",
  doc: contentId,
  storeAs: `content-${contentId}`
})

export const queryAllVendors:ReduxFirestoreQuerySetting = {
  collection: "vendors",
  storeAs: "allVendors"
}

export const queryCarsByUid = (uid: string):ReduxFirestoreQuerySetting => ({
  collection: "cars",
  where: [
    ['owner.id', '==', uid],
  ],
  orderBy: ["createdAt", "asc"],
  storeAs: `cars-owner-${uid}`
})

export const queryCarById = (carId:string):ReduxFirestoreQuerySetting => ({
  collection: 'cars',
  doc: carId,
  storeAs: `cars-${carId}`
})
