import React, {useCallback, useState} from 'react'

import {Alert, Button, Col, Form, Nav, OverlayTrigger, Row, Tab, Tooltip} from "react-bootstrap"
import {Link, Prompt, useHistory} from 'react-router-dom'
import {i18path} from "i18n"
import {CARPHOTO, MAX_CARS_COUNT, ROUTE, URL_PATTERN} from "config"

import {Trans, useTranslation} from "react-i18next"

import styles from './Garage.module.scss'
import {Controller, useForm} from "react-hook-form"
import classNames from "classnames"
import _get from "lodash/get"
import {Icon} from "components/Icon"
import CreatableSelect from "react-select/creatable"
import {
  createIdFromLine,
  createShortId,
  removeTokenFromURL,
  selectorStyles,
  selectorTheme,
  SelectorValue
} from "utils"
import {Loading, SmartButton} from "components"
import {Car, CarStatus, Vendor, VendorModel} from "redux/reducers"
import {isLoaded, useFirebase, useFirestore, useFirestoreConnect} from "react-redux-firebase"
import {getAllVendors, getAuthUid, getUserProfile, queryAllVendors} from "redux/selectors"
import {useDispatch, useSelector} from "react-redux"
import {LinkContainer} from "react-router-bootstrap"
import {CarPhotos} from "pages/Member/Garage/CarPhotos"
import {showAlert} from "redux/actions"
import _pick from "lodash/pick"
// import {getCarStatusBadge} from "pages/Member/Garage/Garage"
import {CarSpecsComponents, CarSpecsDetails} from "types"


const populateWithNoName = (list: {value:string, label: string}[]) => {
  // list.unshift(
  //   { value: "custom", label: "-- Custom --"},
  //         { value: "unknown", label: "-- Unknown --"}
  // )

  return list
}

const getVendorsByType = (vendors:Vendor[], type:string) => populateWithNoName(vendors
  .filter(vendor => vendor.types.includes(type))
  .map(vendor => ({ value: vendor.id, label: vendor.name})))

const getModelsByVendorAndType = (vendors:Vendor[], modelType: string, vendorId: string | null) =>
  populateWithNoName((_get(vendors.find(vendor => vendor.id === vendorId) as object, 'models', []) as VendorModel[])
    .filter((model:VendorModel) => model.type === modelType)
    .map((model:VendorModel) => ({ value: model.id, label: model.name})))

const composeSpecsList = (
    vendor:{[key:string]:SelectorValue},
    model:{[key:string]:SelectorValue},
    model_url?:{[key:string]:string},
    vendor_website?:{[key:string]:string}
  ) =>
  Object.keys(vendor)
  .reduce((obj, spec) => ({
    ...obj,
    ...vendor[spec] && model[spec] && {[spec]: {
      vendor: {
        id: vendor[spec].value,
        name: vendor[spec].label,
        ...vendor[spec].isNew && {isNew: vendor[spec].isNew},
        ...vendor[spec].isNew && {website: vendor_website![spec]},
      },
      model: {
        id: model[spec].value,
        name: model[spec].label,
        ...model[spec].isNew && {isNew: model[spec].isNew},
        ...model[spec].isNew && {url: model_url![spec]},
      },
    }}
  }), {})

const decomposeSpecsList = (specs: {[key:string]:any}) =>
  ({
    vendor: Object.keys(specs)
      .reduce((obj, spec) => {
        const vendor = _get(specs, [spec, 'vendor'])

        return {
        ...obj,
        [spec]: vendor && {
          value: vendor.id,
          label: vendor.name,
          ...vendor.isNew && {isNew: vendor.isNew}
        }
      }}, {}),
    model: Object.keys(specs)
      .reduce((obj, spec) => {
        const model = _get(specs, [spec, 'model'])

        return {
          ...obj,
          [spec]: model && {
            value: model.id,
            label: model.name,
            ...model.isNew && {isNew: model.isNew}
          }
        }}, {}),
    model_url: {
      ...Object.keys(specs)
        .filter(spec => specs[spec].model.isNew && specs[spec].model.url)
        .reduce((obj, spec) => ({
          ...obj,
          [spec]: specs[spec].model.url
        }), {})
    },
    vendor_website: {
      ...Object.keys(specs)
        .filter(spec => specs[spec].vendor.isNew && specs[spec].vendor.website)
        .reduce((obj, spec) => ({
          ...obj,
          [spec]: specs[spec].vendor.website
        }), {})
    }
  })

type FormData = {
  name:string;
  story_en:string;
  story_uk: string;
  vendor: {[key:string]:SelectorValue} | undefined;
  model: {[key:string]:SelectorValue} | undefined;
  vendor_website: {[key:string]:string} | undefined;
  model_url: {[key:string]:string} | undefined;
  newPhoto?: string;
  removePhoto?: boolean;
}

type Props = {
  backUrl:string;
  carData?:Car
};

export const CarForm: React.FC<Props> = ({ backUrl, carData }) => {

  const dispatch = useDispatch()
  const { t } = useTranslation('CarForm')
  const {reset, register, triggerValidation, handleSubmit, control, errors, setValue, watch, formState: {dirtyFields}} = useForm({
    defaultValues: {
      name: "",
      story_en: "",
      story_uk: "",
      ...carData && _pick(carData, ['name', 'photo', 'story_en', 'story_uk']),
      ...carData && decomposeSpecsList(carData.specs)
    }
  } as {defaultValues:FormData})
  const firebase = useFirebase()
  const firestore = useFirestore()
  const history = useHistory()
  const uid = useSelector(getAuthUid)
  const profile = useSelector(getUserProfile)

  useFirestoreConnect([
    queryAllVendors
  ])
  const vendors = useSelector(getAllVendors)
  const selectedVendors = watch('vendor')
  const selectedModels = watch('model')
  
  // console.log(carData.specs)

  const [ isProcessing, setProcessing ] = useState(false)
  // const isDisabled = useMemo(() => isProcessing || (carData && carData.status !== CarStatus.active), [carData, isProcessing])

  const handleSuccess = useCallback(() => {
    dispatch(showAlert({content: t('alerts:CAR.SAVED'), props: { variant: "success"}}))
    setProcessing(false)
    reset()
    history.push(i18path(ROUTE.GARAGE))
  }, [dispatch, history, reset, t])

  const handleFail = useCallback((message: string) => {
    dispatch(showAlert({content: t('GENERAL_ERROR_MESSAGE', { message }), props: { variant: "danger"}}))
    setProcessing(false)
  }, [dispatch, setProcessing, t])

  const saveCarProfile = useCallback(async ({newPhoto, removePhoto, vendor, model, model_url, vendor_website, ...form}: any) => {
    setProcessing(true)

    if (newPhoto) {
      const uploaded = await firebase.uploadFile(
        `cars/${uid}`,
        newPhoto,
        undefined,
        { name: `${createShortId(CARPHOTO.FILENAME_PREFIX)}${CARPHOTO.FILENAME_EXTENSION}` })
      form.photo = removeTokenFromURL(await uploaded.uploadTaskSnapshot.ref.getDownloadURL())
    }

    if (removePhoto) {
      form.photo = null
    }

    const payload = {
      ...form,
      specs: composeSpecsList(vendor, model, model_url, vendor_website)
    }

    const batch = firestore.batch()

    // update existing car
    if (carData) {
      batch.update(firestore.doc(`cars/${carData.id}`), {
        ...payload,
        //@ts-ignore
        updatedAt: firestore.Timestamp.now(),
        isModerated: false,
        // status: CarStatus.review
      })

      // update linked profile
      if (carData.driver) {
        batch.update(firestore.doc(`profiles/${carData.driver.id}`), {
          'defaultCar.specs': payload.specs,
          ...payload.photo && {'defaultCar.photo': payload.photo},
          //@ts-ignore
          'defaultCar.updatedAt': firestore.Timestamp.now()
        })
      }

    // create new car
    } else {
      batch.set(firestore.collection('cars').doc(), {
        owner: {
          id: uid,
          ..._pick(profile, ['avatarUrl', 'displayName'])
        },
        ...payload,
        //@ts-ignore
        createdAt: firestore.Timestamp.now(),
        //@ts-ignore
        updatedAt: firestore.Timestamp.now(),
        isModerated: false,
        status: CarStatus.active
      })
      batch.update(firestore.doc(`users/${uid}`), {
        //@ts-ignore
        carsCount: firestore.FieldValue.increment(1)
      })
    }

    batch.commit()
        .then(() => {
          handleSuccess()
        })
        .catch((e) => handleFail(e.message))

  }, [firestore, carData, firebase, uid, profile, handleSuccess, handleFail])

  const onSubmit = handleSubmit((form) => saveCarProfile(form) )

  if (!isLoaded(vendors)) return <Loading inline/>
  if (!carData && profile.carsCount >= MAX_CARS_COUNT) return (
    <Alert variant="danger" className={styles.alert}>
      <Trans t={t} i18nKey="WARNING.LIMIT">
        F <Link to={i18path(ROUTE.GARAGE)}>R</Link>
      </Trans>
    </Alert>
  )

  return (
    <Form onSubmit={onSubmit} className={styles.CarForm}>
      <fieldset disabled={isProcessing}>
      <Prompt
        when={!!dirtyFields.size}
        message={() =>
          t('alerts:UNSAVED') as string
        }
      />
      <Row>
        <Col sm={8}>
          <Form.Group className={styles.CarSpecs}>

            <Form.Row className={styles.specsHead}>
              <Col sm={3}><Form.Label>{t('LABEL.COMPONENT')}</Form.Label></Col>
              <Col><Form.Label>{t('LABEL.VENDOR')}</Form.Label></Col>
              <Col><Form.Label>{t('LABEL.MODEL')}</Form.Label></Col>
            </Form.Row>

            {Object.values(CarSpecsComponents).map((spec, index) => (
              <Form.Row key={spec}>
                {CarSpecsDetails[spec].delimiter && <hr className={styles.delimiter}/>}
                <Form.Label column sm="3" className={classNames(
            (_get(errors, ['vendor', spec]) || _get(errors, ['model', spec])) && "invalid text-danger",
                    CarSpecsDetails[spec].required && "required"
                )}>
                  <Icon type={spec} className="text-muted mr-2"/>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`specs-hint-${spec}`}>
                        {t(`common:SPECS.${spec}.HINT`)}
                      </Tooltip>
                    }
                  >
                    <span className={styles.specsLabel}>{t(`common:SPECS.${spec}.TITLE`)}</span>
                  </OverlayTrigger>
                </Form.Label>
                <Col className="mb-2">
                  <Controller
                    control={control}
                    as={CreatableSelect}
                    name={`vendor[${spec}]`}
                    rules={{
                      required: CarSpecsDetails[spec].required
                    }}
                    isClearable={!CarSpecsDetails[spec].required}
                    isDisabled={isProcessing || (carData && !CarSpecsDetails[spec].editable)}
                    onCreateOption={(inputValue:any) => {
                      const value = createIdFromLine(inputValue)
                      if (!value) return

                      const isNew = !vendors.find((vendor:Vendor) => vendor.id === value)
                      setValue([
                        {[`vendor[${spec}]`]: { value, label: inputValue, isNew }},
                        {[`model[${spec}]`]: null}
                      ])
                    }}
                    formatCreateLabel={(value: string) => t('PLACEHOLDER.CREATE_VENDOR', { value })}
                    options={getVendorsByType(vendors, spec)}
                    // isDisabled={isDisabled}
                    className="select"
                    theme={selectorTheme}
                    styles={selectorStyles(!_get(errors, ['vendor', spec]))}
                    placeholder={t('PLACEHOLDER.VENDOR')}
                    onChange={([selected]) => {
                      setValue(`model[${spec}]`, null)
                      return selected
                    }}
                  />
                </Col>
                <Col>
                  <Controller
                    control={control}
                    as={CreatableSelect}
                    name={`model[${spec}]`}
                    rules={{
                      required: CarSpecsDetails[spec].required
                    }}
                    onCreateOption={(inputValue:any) => {
                      const value = createIdFromLine(inputValue)
                      // todo: trim the label
                      const label = inputValue
                      if (!value || !label) return

                      setValue(`model[${spec}]`, { value, label, isNew: true })
                    }}
                    isClearable={!CarSpecsDetails[spec].required}
                    isDisabled={isProcessing || !(selectedVendors && selectedVendors[spec]) || (carData && !CarSpecsDetails[spec].editable)}
                    formatCreateLabel={(value: string) => t('PLACEHOLDER.CREATE_MODEL', { value })}
                    options={getModelsByVendorAndType(vendors, spec, _get(selectedVendors, [spec, 'value'], null))}
                    className="select"
                    theme={selectorTheme}
                    styles={selectorStyles(!_get(errors, ['model', spec]))}
                    placeholder={t('PLACEHOLDER.MODEL')}
                  />
                </Col>
                {!carData && t(`TEXT.SPECS.${spec}`, "") &&
                <Col sm={{span: 9, offset: 3}} className="mb-3 mt-n2">
                  <Form.Text className="text-warning">
                    <Trans t={t} i18nKey={`TEXT.SPECS.${spec}`}/>
                  </Form.Text>
                </Col>}
                {selectedVendors && selectedVendors[spec] && selectedVendors[spec].isNew &&
                <Col sm={{span: 9, offset: 3}}>
                  <Form.Group>
                    <Form.Label className="required">{t('LABEL.WEBSITE')}</Form.Label>
                    <Controller
                      as={Form.Control}
                      control={control}
                      type="text"
                      placeholder={t('PLACEHOLDER.WEBSITE')}
                      name={`vendor_website[${spec}]`}
                      rules={{
                        required: true,
                        pattern: URL_PATTERN
                      }}
                      isInvalid={errors.vendor_website}
                    />
                    <Form.Text className={errors.vendor_website ? "text-danger" : "text-muted"}>
                      <Trans t={t} i18nKey="TEXT.WEBSITE">
                        <a target="_blank"
                           rel="noopener noreferrer"
                           href={`https://google.com/search?q=${encodeURI(`rc ${selectedVendors[spec].label} official website`)}`}>
                          _
                        </a>
                        _
                      </Trans>
                    </Form.Text>
                  </Form.Group>
                </Col>}
                {selectedModels && selectedModels[spec] && selectedModels[spec].isNew &&
                <Col sm={{span: 9, offset: 3}}>
                  <Form.Group>
                    <Form.Label className="required">{t('LABEL.PRODUCT')}</Form.Label>
                    <Controller
                      as={Form.Control}
                      control={control}
                      type="text"
                      placeholder={t('PLACEHOLDER.PRODUCT')}
                      name={`model_url[${spec}]`}
                      rules={{
                        required: true,
                        pattern: URL_PATTERN
                      }}
                      isInvalid={errors.model_url}
                    />
                    <Form.Text className={errors.model_url ? "text-danger" : "text-muted"}>
                      <Trans t={t} i18nKey="TEXT.PRODUCT">
                        <a target="_blank"
                           rel="noopener noreferrer"
                           href={`https://google.com/search?q=${encodeURI(`${selectedVendors[spec].label} ${selectedModels[spec].label} ${spec}`)}`}>
                          _
                        </a>
                        _
                      </Trans>

                    </Form.Text>
                  </Form.Group>
                </Col>}
              </Form.Row>
            ))}

            <Form.Text className={classNames(styles.specsFoot, (errors.vendor || errors.model) ? "text-danger" : "text-muted")}>{t('TEXT.COMPONENTS')}</Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>{t('LABEL.NAME')}</Form.Label>
            <Controller
              as={Form.Control}
              control={control}
              type="text"
              placeholder={t('PLACEHOLDER.NAME')}
              name="name"
              rules={{
                maxLength: 15
              }}
              isInvalid={errors.name}
            />
            <Form.Text className={errors.name ? "text-danger" : "text-muted"}>{t('TEXT.NAME')}</Form.Text>
          </Form.Group>

          <Form.Group className="position-relative">
            <Tab.Container defaultActiveKey="en" id="uncontrolled-tab-example">
              <Nav variant="pills" className={styles.localeTabs}>
                <Nav.Item>
                  <Nav.Link eventKey="en">{t('LABEL.EN')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="uk">{t('LABEL.UK')}</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="en">
                  <Form.Label>{t('LABEL.STORY_EN')}</Form.Label>
                  <Controller
                    control={control}
                    as={ <Form.Control as="textarea" rows="3" placeholder={t('PLACEHOLDER.STORY')} />}
                    name="story_en"
                    rules={{
                      maxLength: 500
                    }}
                    className={styles.story}
                    isInvalid={errors.story_en}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="uk">
                  <Form.Label>{t('LABEL.STORY_UK')}</Form.Label>
                  <Controller
                    control={control}
                    as={ <Form.Control as="textarea" rows="3" placeholder={t('PLACEHOLDER.STORY')} />}
                    name="story_uk"
                    rules={{
                      maxLength: 500
                    }}
                    className={styles.story}
                    isInvalid={errors.story_uk}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            <Form.Text className="text-muted">
              {t('TEXT.STORY')}
            </Form.Text>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <CarPhotos
            previousPhoto={_get(carData, 'photo')}
            register={register}
            setValue={setValue}
            triggerValidation={triggerValidation}
            isDirty={dirtyFields.has('newPhoto')}
            isRemoving={dirtyFields.has('removePhoto')}
            isValid={!errors.newPhoto}
          />
        </Col>
      </Row>
      <SmartButton disabled={!dirtyFields.size}  isProcessing={isProcessing} type="submit" className="mr-2">
        {t(carData ? 'UPDATE': 'CREATE')}
      </SmartButton>
      <LinkContainer exact to={backUrl}>
        <Button variant="secondary">{t('CANCEL')}</Button>
      </LinkContainer>

      </fieldset>
    </Form>
  )
}