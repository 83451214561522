import React, {useState} from 'react'

import {Button, Form, Modal, Spinner} from "react-bootstrap"

import {Controller, useForm} from "react-hook-form"
import {LASTNAME_EN_PATTERN} from "config"
import {createIdFromName} from "utils"
import {useDispatch, useSelector} from "react-redux"
import {useFirebase, useFirestore} from "react-redux-firebase"
import {useTranslation} from "react-i18next"
import {showAlert} from "redux/actions"
import {getAllProfilesData, getAuth} from "redux/selectors"

type Props = {
  show: boolean;
  onHide: () => void;
};

export const CreatePopup: React.FC<Props> = ({ show, onHide }) => {

  const { t } = useTranslation('CreatePopup')
  const dispatch = useDispatch()
  // form validation hook & state
  const { handleSubmit, control, errors } = useForm()
  // form processing state
  const [ isProcessing, setProcessing] = useState(false)
  const [ error, setError] = useState<string|null>(null)

  const firebase = useFirebase()
  const firestore = useFirestore()
  const auth = useSelector(getAuth)
  const profiles = useSelector(getAllProfilesData)

  const startProcessing = () => {
    setProcessing(true)
    setError(null)
  }
  const onError = (error: string) => {
    setProcessing(false)
    setError(error)
  }
  const onSuccess = () => {
    dispatch(showAlert({content: t('alerts:PROFILE.CREATED'), props: { variant: "primary"}}))

    setProcessing(false)
    onHide()
  }

  const createProfile = (lastName: string) => {

    const profileId = createIdFromName(lastName)

    if (profiles[profileId]) {
      return onError(t('ALREADY_EXISTS'))
    }

    startProcessing()

    return firestore.set(`profiles/${profileId}`, {
      userId: auth.uid,
      surname_en: lastName,
    })
      .then(() => {
        firebase.updateProfile({ profileId })
        onSuccess()
      })
      .catch((e) => onError(e.message))
  }
  const onSubmit = handleSubmit(({ lastname }) => { createProfile(lastname) })

  return (
    <Modal show={show} onHide={onHide} backdrop={!isProcessing || 'static'} keyboard={!isProcessing}>
      <Form onSubmit={onSubmit}>
        <fieldset disabled={isProcessing}>
        <Modal.Header>
          <Modal.Title className="h5">{t('TITLE')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group>
              <Form.Label className="required">
                {t('INPUT.LABEL')}
              </Form.Label>
              <Controller
                as={Form.Control}
                control={control}
                type="text"
                placeholder={t('INPUT.PLACEHOLDER')}
                name="lastname"
                // defaultValue={profile?.email}
                rules={{
                  required: true,
                  pattern: LASTNAME_EN_PATTERN
                }}
                isInvalid={errors.lastname}
              />
              <Form.Text className={error || errors.lastname ? "text-danger" : "text-muted"}>
                {(error && t(error, error)) || t('INPUT.TEXT')}
              </Form.Text>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="mr-2" type="submit">
            { isProcessing && <Spinner animation="border" size="sm" as="span" role="status" aria-hidden="true" className="mr-2 align-middle" /> }
            <span className="align-middle">{t('SUBMIT')}</span>
          </Button>
          <Button variant="secondary" onClick={onHide}>{t('CANCEL')}</Button>
        </Modal.Footer>
        </fieldset>
      </Form>
    </Modal>
  )
}