import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Alert, Button, Col, Form, ListGroup, ListGroupItem, Nav, Row, Tab} from "react-bootstrap"
import {useFirebase, useFirestore} from "react-redux-firebase"
import {FIREBASE_REGION, FUNCTIONS_EMULATOR_URL, PUBLISH_REGULATIONS_DELAY, ROUTE} from "config"
import {Icon} from "components/Icon"
import {SmartButton, Loading} from "components"

import {Controller, useForm} from "react-hook-form"
import {Trans, useTranslation} from "react-i18next"

import styles from "./UpdateRegulations.module.scss"
import moment from "moment"
import {showAlert} from "redux/actions"
import {useDispatch, useSelector} from "react-redux"
import {createRequest} from "utils"
import {SiteContentType, MemberRequestType, MemberRequest} from "redux/reducers"
import {getActiveRequests, getAuthUid, getUserProfile} from "redux/selectors"
import {i18path} from "i18n"
import {Link, Prompt} from 'react-router-dom'
import _pick from "lodash/pick"

const defaultValues = {
  title: "",
  scrollDown: false,
  comment: undefined
}

const checkIsReviewing = (requests:MemberRequest[]) => {
  return !!requests.find((req:MemberRequest) => req.type === MemberRequestType.EDIT_CONTENT && !req.isResolved)
}

type FetchMetaResult = {
  data: {
    meta: {[key:string]:any}[]
  }
}

type FetchDocResult = {
  data: {
    notModified: boolean,
    draft: string,
    meta: {[key:string]:any}[]
  }
}

type Props = {

};

export const UpdateRegulations: React.FC<Props> = (props) => {

  const { t } = useTranslation('UpdateRegulations')
  const dispatch = useDispatch()
  const firebase = useFirebase()
  const firestore = useFirestore()
  const uid = useSelector(getAuthUid)
  const profile = useSelector(getUserProfile)
  const activeRequests = useSelector(getActiveRequests)

  const isReviewing = useMemo(() => checkIsReviewing(activeRequests), [activeRequests])

  const { control, errors, register, reset, setValue, handleSubmit, triggerValidation } = useForm({
    defaultValues
  })

  const [ locale, setLocale ] = useState<string|undefined>(undefined)
  const [ draft, setDraft ] = useState<{__html: string, file: any}|undefined>(undefined)
  const [ meta, setMeta ] = useState<{[key:string]:any}[]|undefined|null>(undefined)
  const [ isProcessing, setProcessing ] = useState(false)
  const [ isFetching, setFetching ] = useState(false)

  useEffect(() => {
    register({ name: 'scrollDown' }, { required: true })
  }, [register])

  useEffect(() => {
    reset(draft && draft.file ? {title: draft.file.name} : undefined)
  }, [draft, reset])

  useEffect(() => {
    if (Array.isArray(meta) && meta.length && locale === undefined) {
      setLocale(meta[0].locale)
    }
  }, [meta, setLocale, locale])

  const fetchRegulations = useMemo(() => {
    // @ts-ignore
    const functions = firebase.app().functions(FIREBASE_REGION)
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_EMULATE_FUNCTIONS === 'true') {
      functions.useFunctionsEmulator(FUNCTIONS_EMULATOR_URL)
    }
    return functions.httpsCallable('fetchRegulations')
  }, [firebase])

  const handleSuccess = useCallback(() => {
    dispatch(showAlert({content: t('alerts:REGULATIONS.SAVED'), props: { variant: "primary"}}))
    setProcessing(false)
    setDraft(undefined)
  }, [dispatch, t])
  const handleError = useCallback((message: string) => {
    dispatch(showAlert({content: t('alerts:GENERAL_ERROR_MESSAGE', { message }), props: { variant: "danger"}}))
    setProcessing(false)
    setFetching(false)
  }, [dispatch, t])

  const fetchMeta = useCallback(() =>
    {
      setMeta(undefined)
      fetchRegulations().then(
        ({data: { meta: cloudMeta }}: FetchMetaResult) => {
          if (cloudMeta && Array.isArray(cloudMeta)) setMeta(cloudMeta.filter(file => Object.keys(file).length))
        }
      ).catch((e:any) => {
        setMeta(null)
      })
    }, [fetchRegulations])

  useEffect(() => {
    if (!isReviewing) {
      fetchMeta()
    } else setMeta(null)
  }, [fetchMeta, isReviewing])

  const fetchDraft = useCallback(() => {
    // console.log(locale, meta)
    const file = meta!.find(file => file.locale === locale)

    setFetching(true)

    if (file) fetchRegulations({ locale: file.locale }).then(
      ({ data: {notModified, draft, meta: cloudMeta}}: FetchDocResult) => {
        setFetching(false)

        if (notModified) {
          dispatch(showAlert({content: t('alerts:REGULATIONS.NOT_MODIFIED'), props: { variant: "warning"}}))
        } else {
          if (typeof draft === 'string' && draft.length) {
            setDraft({__html: draft, file})
          } else {
            dispatch(showAlert({content: t('alerts:REGULATIONS.NO_DATA'), props: { variant: "danger"}}))
          }
        }
        if (cloudMeta && Array.isArray(cloudMeta)) setMeta(cloudMeta.filter(file => Object.keys(file).length))
      }
    )
    .catch((e:any) => handleError(e.message))
  }, [dispatch, fetchRegulations, handleError, locale, meta, t])

  const saveDraft = useCallback((title, comment) => {
    setProcessing(true)
    return firestore.add('content', {
      title,
      type: "regulations",
      locale,
      content: draft!.__html,
      status: "draft",
      // @ts-ignore
      updatedAt: firestore.Timestamp.now()
    })
      .then(({id}) => {
        createRequest({
            id: uid,
            ..._pick(profile, ['avatarUrl', 'email', 'displayName'])
          }, null,
          MemberRequestType.EDIT_CONTENT, {
            contentId: id,
            contentType: SiteContentType.regulations,
            preview: {
              url: `${ROUTE.REGULATIONS}/${id}`,
              title: title,
            },
            source: {
              url: draft!.file.webViewLink,
              title: draft!.file.name
            },
            ...draft!.file.contentId && {replacedId: draft!.file.contentId}
          }, firestore, comment)
          .then(() => {
            handleSuccess()
          })
          .catch((e) => handleError(e.message))
      })
      .catch((e) => handleError(e.message))
  }, [firestore, locale, draft, uid, profile, handleSuccess, handleError])

  const onSubmit = handleSubmit(({ comment, title }) => { saveDraft(title, comment) })

  if (meta === undefined) return <Loading inline/>
  return (
    <Row>
      <Col lg={12} xl={10}>
      <h4>{t('TITLE')}</h4>
      <hr/>
        {Array.isArray(meta) && !!meta.length &&
        <Tab.Container id="regulations-update" defaultActiveKey={meta[0].locale} onSelect={(key) => setLocale(key)}>
          <Row className="mb-4">
            <Col sm={4}>
              <Form.Label className="mb-2 text-muted">
                <Icon type="languageOutline" className="mr-2"/>
                <span className="align-middle">{t('LABEL.LANGUAGE')}</span>
              </Form.Label>
              <Nav variant="pills" className={styles.pills}>
                {meta.map(file => (
                  <Nav.Item key={`nav-${file.locale}`}>
                    <Nav.Link eventKey={file.locale} disabled={isFetching || !!draft}>
                      <Icon type={file.locale} className="align-middle mr-2"/>
                      <span className="align-middle">{t(`common:LOCALES.${file.locale}`)}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col sm={8}>
              <Tab.Content>
                {meta.map(file => (
                  <Tab.Pane eventKey={file.locale} key={file.locale}>
                    <ListGroup variant="flush" className={styles.info}>
                      <ListGroupItem className="px-3 py-2">
                        <Row>
                          <Col lg={5} className="text-muted">{t('LABEL.PUBLISHED')}</Col>
                          <Col lg={7}>{file.contentUpdated ? moment(file.contentUpdated).fromNow() : "--"}</Col>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem className="px-3 py-2">
                        <Row>
                          <Col lg={5} className="text-muted">{t('LABEL.SOURCE_DOC')}</Col>
                          <Col lg={7}>
                            <a href={file.webViewLink} target="_blank" rel="noopener noreferrer">
                              <Icon type="documentTextOutline" className="mr-1"/>
                              <span className="align-middle">{file.name}</span>
                            </a>
                          </Col>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem className="px-3 py-2">
                        <Row>
                          <Col lg={5} className="text-muted">{t('LABEL.SOURCE_UPDATED')}</Col>
                          <Col lg={7}>{moment(file.modifiedTime).fromNow()}</Col></Row></ListGroupItem>
                    </ListGroup>
                  </Tab.Pane>
                ))}
              </Tab.Content>
              {!draft && locale && <SmartButton variant="secondary" className="mt-3 mr-2" isProcessing={isFetching} iconType="cloudDownloadOutline" onClick={fetchDraft}>{t('FETCH')}</SmartButton>}
            </Col>
          </Row>
        </Tab.Container>}
        {meta === null && (isReviewing ?
          <Alert variant="warning" className={styles.alert}>
            <Trans t={t} i18nKey="WARNING_REVIEW">
              F <Link to={i18path(ROUTE.REQUESTS)}>R</Link> W
            </Trans>
          </Alert>:
          <Alert variant="danger" className={styles.warning}>
            <Trans t={t} i18nKey="alerts:LOADING_ERROR">
              F <a href="#/" onClick={e => { e.preventDefault(); fetchMeta()}}>R</a>
            </Trans>
          </Alert>)}
      </Col>

      {draft && <Col lg={12} xl={10}><Form onSubmit={onSubmit}>
        <Prompt
          when={!!draft}
          message={() =>
            t('alerts:UNSAVED') as string
          }
        />
        <fieldset disabled={isProcessing}>
          <h4>{t('DRAFT_TITLE')}</h4>
          <hr/>
          <Form.Group>
            <Form.Label className="required">{t('LABEL.PAGE_TITLE')}</Form.Label>
            <Controller
              as={Form.Control}
              control={control}
              type="text"
              placeholder={t('PLACEHOLDER.PAGE_TITLE')}
              name="title"
              rules={{
                required: true,
                minLength: 2,
              }}
              isInvalid={errors.title}
            />
            <Form.Text className={errors.title ? "text-danger" : "text-muted"}>{t('TEXT.PAGE_TITLE')}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>{t('LABEL.PREVIEW')}</Form.Label>
            <div dangerouslySetInnerHTML={draft} className={styles.preview} onScroll={(e) => {
              const tgt = e.currentTarget
              if( tgt.scrollTop >= (tgt.scrollHeight - tgt.offsetHeight)) {
                setValue('scrollDown', true)
                triggerValidation('scrollDown')
              }
            }}/>
            <Form.Text className={errors.scrollDown ? "text-danger" : "text-muted"}>{t('TEXT.PREVIEW')}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label className="required">{t('LABEL.COMMENT')}</Form.Label>
            <Controller
              control={control}
              as={ <Form.Control as="textarea" rows="2" placeholder={t('PLACEHOLDER.COMMENT')} />}
              name="comment"
              rules={{
                maxLength: 300,
                required: true
              }}
              className={styles.bio}
              isInvalid={errors.comment}
            />
            <Form.Text className={errors.comment ? "text-danger" : "text-muted"}>{t('TEXT.COMMENT')}</Form.Text>
          </Form.Group>

          <SmartButton
            variant="primary"
            type="submit"
            isProcessing={isProcessing}
            delay={PUBLISH_REGULATIONS_DELAY}
            className="mr-2">{t('SUBMIT')}</SmartButton>
          <Button variant="secondary" disabled={isProcessing} onClick={() => setDraft(undefined)}>{t('CLEAR')}</Button>
          <Form.Text className="text-muted">
            {t('TEXT.SUBMIT')}
          </Form.Text>
        </fieldset>
      </Form></Col>}
    </Row>
  )
}