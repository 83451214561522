import React, {ChangeEvent, useEffect, useState} from 'react'

import styles from './Profile.module.scss'
import {Alert, Button, Col, Row, Form} from "react-bootstrap"
import {CountDownTimer} from "components/CountDownTimer"
import {Icon} from "components/Icon"
import {useFirebase} from "react-redux-firebase"
import {useTranslation} from "react-i18next"
import {ACCEPT_RULES_DELAY} from "config"

type Props = {

};

export const ProfileRules: React.FC<Props> = (props) => {

  const { t } = useTranslation('ProfileRules')
  const firebase = useFirebase()

  const [ enabled, setEnabled ] = useState(false)
  const [ finished, setFinished ] = useState(false)
  const [ checked, setChecked ] = useState(false)

  useEffect(() => {
    setEnabled(checked && finished)
  }, [checked, finished])

  const agreeWithRules = () => {
    if (enabled) firebase.updateProfile({agreeWithRules: true})
  }

  return (
    <Row className={styles.ProfileRules}>
    <Col lg={10}>
      <h4>{t('TITLE')}</h4>
      <hr/>
      <Alert variant="primary">
        {t('WARNING')}
      </Alert>
      <p className="p-2">
        {t('INTRO')}
      </p>
      <Row>
        <Col>
          <h5 className="brand-title mb-3">Profile Features</h5>
          <ul className={styles.list}>
            {typeof t('FEATURES_LIST', { returnObjects: true }) === 'object' && t<string[]>('FEATURES_LIST', { returnObjects: true })
              .map((item, key) => (
                <li key={`feature-${key}`}><Icon type="liOk"/>{item}</li>
              )
            )}
          </ul>
        </Col>
        <Col>
          <h5 className="brand-title mb-3">Profile Restrictions</h5>
          <ul className={styles.list}>
            {typeof t('RESTRICTIONS_LIST', { returnObjects: true }) === 'object' && t<string[]>('RESTRICTIONS_LIST', { returnObjects: true })
              .map((item, key) => (
                  <li key={`restriction-${key}`}><Icon type="liNo"/>{item}</li>
                )
              )}
          </ul>
        </Col>
      </Row>
      <p className="p-2">
        {t('VIOLATION')}
      </p>
      <p className="p-2">
        <Form.Check
          type="checkbox"
          id="agree"
          onChange={(e:ChangeEvent & {target: HTMLInputElement}) => setChecked(e.target.checked)}
          label={t('AGREE')}
        />
      </p>
      <div className="mt-4 d-flex justify-content-center">
        <Button className="mr-3" size="lg" disabled={!enabled}>
          {!finished && <CountDownTimer max={ACCEPT_RULES_DELAY} timeout={ACCEPT_RULES_DELAY} onEnd={() => setFinished(true)} className="mr-2"/>}
          <span className="align-middle" onClick={agreeWithRules}>{t('SUBMIT')}</span>
        </Button>
      </div>
    </Col>
    </Row>
  )
}