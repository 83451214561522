import React, {useCallback, useEffect, useRef, useState} from 'react'

import {ButtonGroup, Dropdown, DropdownButton, Form} from "react-bootstrap"
import {Icon} from "components/Icon"
import {Trans, useTranslation} from "react-i18next"
import {
  HEROSHOT_ASPECT_HEIGHT,
  HEROSHOT_ASPECT_WIDTH,
  HEROSHOT_MAX_FILESIZE_MB,
  HEROSHOT_MIN_HEIGHT,
  HEROSHOT_MIN_WIDTH, HEROSHOT_THUMB_SIZE
} from "config"
import classNames from "classnames"
import {UserPlaceholder} from "svg"

import styles from './Profile.module.scss'
import {getThumbURL} from "utils"
import {SmartImage} from "components"


const HeroshotRequirements = {
  aspectHeight: HEROSHOT_ASPECT_HEIGHT,
  aspectWidth: HEROSHOT_ASPECT_WIDTH,
  fileSize: HEROSHOT_MAX_FILESIZE_MB,
  minHeight: HEROSHOT_MIN_HEIGHT,
  minWidth: HEROSHOT_MIN_WIDTH
}

type Props = {
  previousPhoto?: string;
  register: (input: any, rules: any) => void;
  setValue: (name: string, value: any) => void;
  triggerValidation: (name:string) => void;
  isDirty: boolean;
  isRemoving: boolean;
  isValid: boolean;
};

export const ProfileHeroshot: React.FC<Props> = ({ previousPhoto, register, triggerValidation, setValue, isDirty, isValid, isRemoving}) => {

  const { t } = useTranslation('ProfileHeroshot')
  const [ photo, setPhoto ] = useState<string | undefined>()

  useEffect(() => {
    setPhoto(previousPhoto)
  }, [previousPhoto])

  const validateImage = useCallback(async (value:any) => new Promise (resolve => {
    if (!value) resolve(true)
    
    if (!value.size || value.size > HEROSHOT_MAX_FILESIZE_MB * 1024 * 1024) resolve(false)

    let reader = new FileReader()

    reader.onload = function (e) {
      let image = new window.Image()
      if (!e.target) resolve(false)
      image.src = e.target!.result as string

      //Validate the File Height and Width.
      image.onload = function () {
        const { width, height } = image
        const isValid =
          width/height === HEROSHOT_ASPECT_WIDTH / HEROSHOT_ASPECT_HEIGHT
          && width >= HEROSHOT_MIN_WIDTH
          && image.height >= HEROSHOT_MIN_HEIGHT
          // && hasAlpha(e.target!.result as ArrayBuffer)

        setPhoto(e.target!.result as string)
        resolve(isValid)
      }
      image.onerror = function () {
        resolve(false)
      }
    }
    reader.readAsDataURL(value)
  }), [setPhoto])

  const newPhotoInput = useRef<HTMLInputElement>(null)
  useEffect(() => {
    register({ name: 'newPhoto' }, { validate: async (value: any) => await validateImage(value) })
    register({ name: 'removePhoto' }, undefined)
  }, [register, validateImage])

  const revertHeroshot = useCallback(() => {
    setPhoto(previousPhoto)
    setValue('newPhoto', undefined)
    setValue('removePhoto', undefined)
    newPhotoInput.current!.value = ""
    triggerValidation('newPhoto')
  }, [setPhoto, previousPhoto, setValue, newPhotoInput, triggerValidation])

  const deleteHeroshot = useCallback(() => {
    setValue('removePhoto', true)
    setPhoto(undefined)
  }, [setValue, setPhoto])

  const onChange = useCallback(() => {
    if (newPhotoInput.current && newPhotoInput.current.files && newPhotoInput.current.files[0]) {
      setValue('newPhoto', newPhotoInput.current.files[0])
      setValue('removePhoto', undefined)
      triggerValidation('newPhoto')
    }
  }, [newPhotoInput, setValue, triggerValidation])

  return (
    <>
      <Form.Group>
        <input type="file"
           hidden
           name="newPhoto"
           accept="image/png"
           ref={newPhotoInput}
           onChange={onChange}
        />
        <Form.Label>{t('LABEL')}</Form.Label>
        <SmartImage
          src={isDirty ? photo : getThumbURL(photo, HEROSHOT_THUMB_SIZE.sm)}
          placeholder={UserPlaceholder}
          className={classNames(styles.Heroshot, !isValid && styles.invalid)}
          imageClass={classNames(isDirty && styles.photoPreview)}
          placeholderClass={classNames(styles.placeholder, isDirty && styles.preview)}>
          <DropdownButton
            title={
              <span>
                    <Icon type="createOutline" className="mr-1"/>
                    <span className="align-middle">{t('EDIT')}</span>
                  </span>} id="edit-photo-dropdown"
            as={ButtonGroup}
            size="sm"
            variant={"secondary"}
            className={styles.editHeroshot}>
            <Dropdown.Item onClick={() => newPhotoInput.current && newPhotoInput.current.click()}>{t('SELECT')}</Dropdown.Item>
            {(photo || isDirty) && <Dropdown.Item onClick={isDirty ? revertHeroshot : deleteHeroshot}>{isDirty ? t('CLEAR') : t('REMOVE')}</Dropdown.Item>}
            {isRemoving && <Dropdown.Item onClick={revertHeroshot}>{t('RESTORE')}</Dropdown.Item>}
          </DropdownButton>
        </SmartImage>
      </Form.Group>
      {isRemoving && <Form.Text className={"text-warning"}>
        <Trans t={t} i18nKey={'REMOVING'}/>
      </Form.Text>}
      {!isDirty && !isRemoving && <Form.Text className="text-muted">
        {t('TEXT')}
        <ul className={styles.requirements}>
          {typeof t('REQUIREMENTS', { returnObjects: true }) === 'object' &&
          t<string[]>('REQUIREMENTS', { returnObjects: true, ...HeroshotRequirements })
            .map((item, key) => (
                <li key={`req-${key}`}>{item}</li>
              )
            )}
        </ul>
      </Form.Text>}
      {isDirty && isValid &&
      <>
        <Form.Text className={"text-muted"}><Trans t={t} i18nKey={'HINT'}/></Form.Text>
        <Form.Text className={"text-warning"}><Trans t={t} i18nKey={'WARNING'}/></Form.Text>
      </>}
      {isDirty && !isValid && <Form.Text className={"text-danger"}>
        {t('INVALID')}
        <ul className={styles.requirements}>
          {typeof t('REQUIREMENTS', { returnObjects: true }) === 'object' &&
          t<string[]>('REQUIREMENTS', { returnObjects: true, ...HeroshotRequirements })
            .map((item, key) => (
                <li key={`req-${key}`}>{item}</li>
              )
            )}
        </ul>
      </Form.Text>}
    </>
  )
}