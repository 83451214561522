import React from 'react'

import {Col, Container, Row} from "react-bootstrap"
import {SiteContent} from "redux/reducers"
import classNames from "classnames"

import {getDateFromTimestamp} from "utils"

import styles from './ContentPage.module.scss'
import {useTranslation} from "react-i18next"

type Props = {
  content: SiteContent
};

export const RegulationsPage: React.FC<Props> = ({content}) => {
  const { t } = useTranslation('ContentPage')
  return (
    <>
      <div className="promo-header regulations mb-5">
        <Container>
          <Row>
            <Col lg={8}><h1 className="brand-title">{content.title}</h1></Col>
            <Col className="d-flex align-items-center">
              <div className="brand-pill bg-transparent border border-primary mr-auto ml-auto">
                <span className="align-middle">{t('REGULATIONS.PUBLISHED')} {getDateFromTimestamp(content.updatedAt).format('L')}</span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className={styles.ContentPage}>
        <div className={classNames("col-lg-9 offset-lg-1", styles.regulations)} dangerouslySetInnerHTML={{__html:content.content}}/>
      </Container>
    </>
  )
}