import * as React from 'react'
import { Container, Table } from 'react-bootstrap'

import styles from './StandingsTable.module.scss'
import {Standing} from "utils"
import {EventStage} from "redux/reducers"
import {DriverName} from "components/DriverName"

type Props = {
  standings: Standing[];
  stages: EventStage[];
  title?: string;
};

export const StandingsTable: React.FC<Props> = ({title, standings, stages }) => {
  return (
    <Container className={styles.StandingsTable}>
      <h2 className="brand-title">{title}</h2>
      <br/>
      <Table striped>
        <thead>
          <tr>
            <th scope="row" rowSpan={2} className={styles.left}>Rank</th>
            <th scope="row" rowSpan={2} className={styles.left}>Car #</th>
            <th scope="row" rowSpan={2} className={styles.left}>Driver</th>
            {stages.map((stage, index) => (
              <th key={index} scope="row" colSpan={2} className={styles.round}>{stage.city}</th>
            ))}
            <th scope="row" rowSpan={2} className={styles.round}>Total</th>
          </tr>
          <tr className={styles.sub}>
            {stages.map((stage, index) => (
              <React.Fragment key={index}>
                <th>Q</th>
                <th>F</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {standings.map((standing, i) => (
            <tr key={i}>
              <td className={styles.left}>{i+1}</td>
              <td className={styles.left}>{standing.carNumber || "-"}</td>
              <td className={styles.left}>
                <DriverName driverId={standing.driverId} driver={standing.driver}/>
              </td>
              {stages.map((stage, si) => (
                <React.Fragment key={si}>
                  <td>{standing.stages[stage.id] ? standing.stages[stage.id].q : "-"}</td>
                  <td>{standing.stages[stage.id] ? standing.stages[stage.id].f : "-"}</td>
                </React.Fragment>
              ))}
              <td>{standing.total}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

StandingsTable.defaultProps = {
  title: "All Standings"
}