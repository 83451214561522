import React from 'react'
import {useDispatch, useSelector} from "react-redux"
import {isEmpty, isLoaded, useFirebase} from "react-redux-firebase"
import { Nav, Button, Spinner, NavDropdown } from 'react-bootstrap'

import {Icon} from "components/Icon"

import {openAuthPopup, showAlert} from "redux/actions/GlobalActions"
import {LinkContainer} from "react-router-bootstrap"

import styles from './Header.module.scss'
import {AUTH_VARIANT, ROUTE} from "config"
import {useTranslation} from "react-i18next"
import {getAuth, getUserProfile} from "redux/selectors"
import {i18path} from "i18n";

type Props = {

};

export const UserMenu: React.FC<Props> = (props) => {

  const { t } = useTranslation('UserMenu')

  const dispatch = useDispatch()
  const handleOpen = () => dispatch(openAuthPopup(AUTH_VARIANT.SIGNIN))

  const firebase = useFirebase()
  const auth = useSelector(getAuth)
  const profile = useSelector(getUserProfile)
  const isSigning = false
  const logout = () => {
    if (window.confirm(t('alerts:CONFIRM'))) {
      firebase.logout().then(() => dispatch(showAlert({content: t('alerts:LOGOUT'), props: { variant: "danger"}})))
    }
  }

  return (
    <Nav>
      {
        isLoaded(auth) && !isEmpty(auth)
          ?
          <NavDropdown title={<>
            <Icon type="personCircleOutline" className="mr-1"/>
            <span className="align-middle">{profile.displayName}</span>
          </>} id="usermenu" className={styles.UserMenu}>
            <LinkContainer to={i18path(ROUTE.GARAGE)}><NavDropdown.Item active={false}>{t('GARAGE')}</NavDropdown.Item></LinkContainer>
            <LinkContainer to={i18path(ROUTE.PROFILE)}><NavDropdown.Item active={false}>{t('PROFILE')}</NavDropdown.Item></LinkContainer>
            <NavDropdown.Divider />
            <LinkContainer to={i18path(ROUTE.SETTINGS)}><NavDropdown.Item active={false}>{t('SETTINGS')}</NavDropdown.Item></LinkContainer>

            <NavDropdown.Divider />
            <NavDropdown.Item onClick={logout} active={false}>
              <Icon type="logOut" className="mr-1"/>
              <span className="align-middle">{t('LOGOUT')}</span>
            </NavDropdown.Item>
          </NavDropdown>
          :
          <Button size="sm" variant="outline-light" onClick={handleOpen}>
            {
              (!isLoaded(auth) || isSigning) ?
                <Spinner animation="border" size="sm" as="span" role="status" aria-hidden="true" className="mr-1"/> :
                <Icon type="personOutline" className="mr-1"/>
            }
            <span className="align-middle">{t('SIGNIN')}</span>
          </Button>
      }
    </Nav>
  )
}