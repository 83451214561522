import React, {useCallback, useState} from 'react'

import {Button, Form, Modal} from "react-bootstrap"
import {useFirestore} from "react-redux-firebase"
import {useForm} from "react-hook-form"
import {useDispatch, useSelector} from "react-redux"
import {Trans, useTranslation} from "react-i18next"
import {getAuthUid, getCarDelete} from "redux/selectors"
import {closeCarDeletePopup, showAlert} from "redux/actions"

import {CAR_DELETE_DELAY, ROUTE} from "config"
import {CustomInput, Icon, SmartButton} from "components"
import {CarStatus} from "redux/reducers"
import {i18path} from "i18n"

enum CarDeleteVariant {
  disable = "disable",
  delete = "delete"
}

type Props = {

};

export const CarDeletePopup: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation('CarDeletePopup')
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const carDelete = useSelector(getCarDelete)
  const uid = useSelector(getAuthUid)

  // form validation hook & state
  const { handleSubmit, register, watch } = useForm()
  // form processing state
  const [ isProcessing, setProcessing] = useState(false)

  const selectedVariant = watch('variant')

  const handleHide = useCallback(() => {
    dispatch(closeCarDeletePopup())
  }, [dispatch])

  const handleDelete = useCallback((variant: CarDeleteVariant) => {
    if (!variant) return

    setProcessing(true)
    const batch = firestore.batch()
    const carRef = firestore.doc(`cars/${carDelete!.id}`)
    if (variant === CarDeleteVariant.delete) {
      batch.delete(carRef)
    } else {
      batch.update(carRef, {
        status: CarStatus.disabled,
        driver: null,
      })
    }

    if (carDelete?.driver) {
      batch.update(firestore.doc(`profiles/${carDelete.driver.id}`), {
        defaultCar: null
      })
    }

    batch.update(firestore.doc(`users/${uid}`), {
      //@ts-ignore
      carsCount: firestore.FieldValue.increment(-1)
    })
    
    batch.commit()
        .then(() => dispatch(showAlert({content: t(variant === CarDeleteVariant.delete ? 'alerts:CAR.DELETED' : 'alerts:CAR.DISABLED')})))
        .catch((error:string) => dispatch(showAlert({ content: t(error, error), props: { variant: "danger" }})))
        .finally(() => {
          setProcessing(false)
          handleHide()
        })
  }, [carDelete, dispatch, firestore, handleHide, t, uid])

  const onSubmit = handleSubmit(({ variant }) => handleDelete(variant))

  return (
    <Modal show={carDelete !== null} onHide={handleHide} backdrop={!isProcessing || 'static'} keyboard={!isProcessing}>
      <Form onSubmit={onSubmit}>
        <fieldset disabled={isProcessing}>
          <Modal.Header>
            <Modal.Title as="h5">{t('TITLE')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>{t(carDelete?.status === CarStatus.disabled ? 'LABEL.VARIANT_DISABLED': 'LABEL.VARIANT')}</Form.Label>
            <Form.Group>
              <CustomInput
                name="variant"
                disabled={carDelete?.status === CarStatus.disabled}
                label={<Trans t={t} i18nKey={'LABEL.DISABLE'}/>}
                value={CarDeleteVariant.disable}
                register={register}
                type="radio"
              />
            </Form.Group>
            <Form.Group>
              <CustomInput
                name="variant"
                label={<Trans t={t} i18nKey={'LABEL.DELETE'}/>}
                value={CarDeleteVariant.delete}
                register={register}
                type="radio"
              />
            </Form.Group>
            {carDelete?.driver && <Form.Label className="text-warning">
              <Icon type="warningOutline" className="mr-2"/>
              <Trans t={t} i18nKey={'LABEL.LINKED'}>
                <strong>_</strong> _ <a href={i18path(`${ROUTE.DRIVERS}/${carDelete.driver.id}`)} target="_blank" rel="noreferrer noopener">_</a>
              </Trans>
            </Form.Label>}
          </Modal.Body>
          <Modal.Footer>
            <SmartButton
              disabled={!selectedVariant}
              variant="danger"
              delay={CAR_DELETE_DELAY}
              isProcessing={isProcessing}
              type="submit"
            >
              {t('SUBMIT')}
            </SmartButton>
            <Button variant="secondary" onClick={handleHide}>{t('CANCEL')}</Button>
          </Modal.Footer>
        </fieldset>
      </Form>
    </Modal>
  )
}