import _get from 'lodash/get'
import {EventResult, MemberProfile, EventStage, RaceData, Car, CarStatus} from 'redux/reducers'

export interface Standing {
  carNumber: number;
  driverId: string;
  driver: MemberProfile | undefined;
  stages: {[key:string]: {q?: number; f?: number}};
  total: number;
  bestStageTotal: number;
  bestQual: number;
}

export interface StageStanding {
  carNumber: number;
  driverId: string;
  driver: MemberProfile | undefined;
  qualification: number[];
  bestQual: number;
  qualRank: number;
  qualPoints: number;
  finalRank: number;
  finalPoints: number;
  total: number;
}

export interface BracketPair {
  driver1: BracketDriver,
  driver2: BracketDriver,
  result1: number;
  result2: number;
}

export interface BracketDriver {
  id: string;
  name: string;
  number: number | null;
}

export interface BracketData {
  round: number;
  pairs: BracketPair[]
}

interface Ranking {
  total: number;
  bestQual: number;
  bestStageTotal: number;
}

/**
 *
 */
const sortByStandings = (a: Ranking, b: Ranking):number => {
  return (b.total - a.total || b.bestStageTotal - a.bestStageTotal || b.bestQual - a.bestQual || -1)
}

/**
 * Get all series standings for all drivers
 * @param events {EventStage[]}
 * @param results {EventResult[]}
 * @param drivers {MemberProfile[]}
 */
export function getStandingsData(events: EventStage[], results: EventResult[], drivers: {[key: string]: MemberProfile}):Standing[] {

  let standings:{[key:string]: Standing} = {}

  results.forEach((result, index) => {

    const driver = _get(drivers, result.driver),
      //TODO: remove series/
      carNumber = _get(driver, ['tailNumber', result.series])

    if (!standings[result.driver]) {
      standings[result.driver] = {
        driverId: result.driver,
        driver,
        stages: {},
        total: 0,
        bestStageTotal: 0,
        bestQual: 0,
        carNumber
      }
    }

    //TODO: use _get() here
    if (result.qualPoints && result.finalPoints && result.total && !standings[result.driver].stages[result.event]) {
      standings[result.driver].stages[result.event] = {
        q: result.qualPoints,
        f: result.finalPoints
      }
      standings[result.driver].total += result.total
      standings[result.driver].bestStageTotal = Math.max(standings[result.driver].bestStageTotal, result.total)
    }
    if (result.bestQual) {
      standings[result.driver].bestQual = Math.max(standings[result.driver].bestQual, result.bestQual)
    }

  })

  // {
  //   carNumber: 77,
  //   driverId: "krulikovskiy",
  //   driverName: "Anton Krulikovskiy",
  //   stages: {
  //     "udrc2020-stage1": {q: 12, f: 180},
  //   },
  //   total: 192
  // }
  return Object.values(standings).sort(sortByStandings)

}

/***/
export function getStageStandingsData(event: EventStage, races: RaceData[], results: EventResult[], drivers: {[key: string]: MemberProfile}): StageStanding[] {

  let standings:{[key:string]: StageStanding} = {}

  results.forEach((result, index) => {

    const driver = _get(drivers, result.driver),
      //TODO: remove /series
      carNumber = _get(driver, ['tailNumber', `${result.series}`])

    if (!standings[result.driver]) {
      standings[result.driver] = {
        finalPoints: result.finalPoints,
        finalRank: result.finalRank,
        qualPoints: result.qualPoints,
        qualRank: result.qualRank,
        qualification: [],
        bestQual: result.bestQual,
        driverId: result.driver,
        driver,
        total: 0,
        carNumber
      }
    }
    if (result.total) {
      standings[result.driver].total += result.total
    }

  })

  races.filter(race => race.round === 0 && !race.driver2).forEach((race, index) => {
    if (standings[race.driver1]) standings[race.driver1].qualification[race.attempt-1] = race.result1
  })

  // const result = [{
  //   carNumber: 77,
  //   driverId: "karpenko",
  //   driver: undefined,
  //   qualification: [55, 0, 71],
  //   qualRank: 11,
  //   qualPoints: 6,
  //   finalRank: 11,
  //   finalPoints: 80,
  //   total: 86
  // }]

  return Object.values(standings).sort((a, b) => b.total - a.total || b.bestQual - a.bestQual)

}


export function getBracketData(event: EventStage, races: RaceData[], drivers: {[key: string]: MemberProfile}) {
  let standings:{[key:string]: BracketData} = {}

  races.filter(race => race.round > 0).sort((a, b) => a.matchup - b.matchup).forEach(race => {
    if (!standings[`round${race.round}`]) {
      standings[`round${race.round}`] = {
        round: race.round,
        pairs: []
      }
    }
    standings[`round${race.round}`].pairs.push({
      driver1: { id: race.driver1, name: _get(drivers, [race.driver1, "surname_en"]), number: _get(drivers, [race.driver1, "tailNumber", event.series]) || null },
      driver2: { id: race.driver2, name: _get(drivers, [race.driver2, "surname_en"]), number: _get(drivers, [race.driver2, "tailNumber", event.series]) || null },
      result1: race.result1,
      result2: race.result2
    })
  })

  //[
  // {
  //   round: 32,
  //   pairs: [
  //     {driver1: {id: "krut", name: "krut", number: 0}, driver2: null, result1: 1, result2: 0},
  //     {driver1: {id: "ivanenko", name: "ivanenko", number: 0}, driver2: {id: "galeta", name: "galeta", number: 0}, result1: 0, result2: 1},
  //   ]
  // }
  //]

  return Object.values(standings).sort((a, b) => b.round - a.round)

}

/**
 * Get driver's rank and points
 * @param driver {MemberProfile} Driver profile to get a rank for
 * @param results {EventResult[]} All results for current series
 * @param driverId {string} Driver's id to get rank for
 * @returns { rank?: number, points?: number }
 */
export function getDriverRank(results: EventResult[], driver: MemberProfile, driverId: string):{rank?: number; points?: number} {

  let standings:{[key:string]: Ranking} = {}

  results.forEach((result, index) => {
    if (!standings[result.driver]) standings[result.driver] = {bestQual: 0, bestStageTotal: 0, total: 0}
    //TODO: remaster this due to getStagingData() sorting
    standings[result.driver].total += result.total
    standings[result.driver].bestStageTotal += Math.max(standings[result.driver].bestStageTotal, result.total)
    standings[result.driver].bestQual += Math.max(standings[result.driver].bestQual, result.bestQual)
  })
  
  const result = Object
    .entries(standings)
    .sort((a, b) => sortByStandings(a[1], b[1]))
    .reduce((acc:{[key:string]: {rank: number; points: number}}, curr, index) => {
      acc[curr[0]] = { rank: index+1, points: curr[1].total }
      return acc
    }, {})

  // return { rank: 1, points: 200 }
  return result[driverId] || {}
}

/**
 * Get string car name from car specs + name
 * @param car {Car}
 */
export const getCarName = (car:Car):string =>
  `${_get(car, 'specs.chassis.vendor.name')}  ${_get(car, 'specs.chassis.model.name')} ${car.name ? " "+car.name : ""}`

/**
 * Sort the list ofr user's cars
 */
export const sortCars = (cars:Car[]) => cars ?
  cars.slice()
    .sort((a, b) => a.status === CarStatus.disabled ? 1 : -1)
    .sort((a, b) => a.driver ? -1 : 1)
  : []