import React, {ReactNode, useEffect, useRef, useState} from 'react'
import classNames from "classnames"
import {Image} from "react-bootstrap"
import {ImageProps} from "react-bootstrap/Image"

import styles from './SmartImage.module.scss'

interface Props extends ImageProps {
  src: string | undefined | null;
  className?: string;
  imageClass?: string;
  placeholderClass?:string;
  children?: ReactNode
  as?: React.ElementType
  //todo: add ReactNode as variant
  placeholder?: React.ElementType// | ReactNode
}

export const SmartImage: React.FC<Props> = ({
  src,
  className,
  imageClass,
  placeholderClass,
  children,
  as,
  placeholder,
  ...props
}) => {
  const As = as || Image
  const Placeholder = placeholder || null

  const [ isLoaded, setLoaded ] = useState(false)
  const [ error, setError ] = useState(false)

  useEffect(() => {
    // noinspection PointlessBooleanExpressionJS
    setLoaded(!!(image.current && image.current.complete))
  }, [src])
  
  const image = useRef<HTMLImageElement>(null)

  return (
    <div className={classNames(styles.SmartImage, src && !isLoaded && !error && "loading", className && className)}>
      {src && <As
        className={classNames(
          styles.photo,
          src && !isLoaded && "transparent",
          imageClass
        )}
        ref={image}
        onLoad={()=>setLoaded(true)}
        onError={()=>setError(true)}
        src={src as string}
        {...props}
      />}
      {Placeholder && <Placeholder className={classNames(
        styles.placeholder,
        (src && isLoaded) && "transparent",
        !src && "empty",
        placeholderClass
      )}/>}
      {children}
    </div>
  )
}