import * as React from 'react'
import {Link} from "react-router-dom"
import {ROUTE} from "config"
import {capitalize} from "utils"
import {MemberProfile} from "redux/reducers"
import {i18path} from "i18n"
import {useTranslation} from "react-i18next"

type Props = {
  driverId: string;
  driver?: MemberProfile;
};

export const DriverName: React.FC<Props> = ({driverId, driver}) => {
  const { t } = useTranslation('profiles')

  return (
    <>
      {driver ? <Link to={i18path(`${ROUTE.DRIVERS}/${driverId}`)}>{t(`${driverId}.fullName`)}</Link> : driverId ? capitalize(driverId) : "(unknown driver)"}
    </>
  )
}