export * from './GlobalActions'

// Global Reducer Actions
export const SET_DEFAULT_SERIES = 'SET_DEFAULT_SERIES'

export const OPEN_AUTH_POPUP = 'OPEN_AUTH_POPUP'
export const CLOSE_AUTH_POPUP = 'CLOSE_AUTH_POPUP'

export const SHOW_ALERT = 'SHOW_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'

export const OPEN_REQUEST_DETAILS = 'OPEN_REQUEST_DETAILS'
export const CLOSE_REQUEST_DETAILS = 'CLOSE_REQUEST_DETAILS'

export const OPEN_DISMISS_POPUP = 'OPEN_DISMISS_POPUP'
export const CLOSE_DISMISS_POPUP = 'CLOSE_DISMISS_POPUP'

export const OPEN_CAR_DELETE_POPUP = 'OPEN_CAR_DELETE_POPUP'
export const CLOSE_CAR_DELETE_POPUP = 'CLOSE_CAR_DELETE_POPUP'

// User Reducer Actions
export const SIGNIN_WITH_PROVIDER = 'SIGNIN_WITH_PROVIDER'
export const VERIFICATION_EMAIL_SENT = 'VERIFICATION_EMAIL_SENT'

export const REAUTHENTICATE = 'REAUTHENTICATE'