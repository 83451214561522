import * as React from 'react'
import classNames from "classnames"
import { Container, Row, Col } from 'react-bootstrap'

import { Link } from 'react-router-dom'
import {ROUTE} from "config"
import {BracketData, BracketDriver, BracketPair, capitalize} from "utils"
import {i18path} from "i18n"

import styles from './Bracket.module.scss'
import {useTranslation} from "react-i18next"

type Props = {
  data: BracketData[];
};

type DriverRowProps = {
  driver?: BracketDriver | null;
  result?: number;
  win?: boolean;
}

const DriverRow = ({ driver, win, result}: DriverRowProps) => {

  const { t } = useTranslation('profiles')

  return (
    <Row className={classNames(styles.row, win && styles.win, !driver && styles.bye)}>
      <Col lg={10}>
        {driver ?
          driver.name ?
            <Link to={i18path(`${ROUTE.DRIVERS}/${driver.id}`)} className={classNames(styles.rowLink, "stretched-link")}>{t(`${driver.id}.surname`)}</Link>
          : capitalize(driver.id)
          : "BYE"}
      </Col>
      <Col lg={2} className="text-right pl-0">
        {result && result !== 1 ? result : ""}
      </Col>
    </Row>
  )
}

const Pair = ({driver1, driver2, result1, result2}: BracketPair) => (
  <div className={classNames(styles.pair, (!driver1 || !driver2) && styles.bye)}>
    {driver1 ?
      <DriverRow driver={driver1} result={result1} win={result1 > result2}/>:
      <DriverRow/>
    }
    {driver2 ?
      <DriverRow driver={driver2} result={result2} win={result2 > result1}/>:
      <DriverRow/>
    }
  </div>
)

const Connectors: React.FC<{count: number}> = ({ count }) => {
  const connectors = []

  for (let i = 1; i <= count; i++) {
    connectors.push(<div key={i} className={styles.connector}/>)
  }

  return <div className={styles.connectors}>{connectors}</div>
}

export const Bracket: React.FC<Props> = ({data}) => {
  return (
    <Container>
      <Row className="mb-4">
        <Col sm={3} className=""><h5 className="brand-title">Top-16</h5></Col>
        <Col sm={3} className="pl-0"><h5 className="brand-title">Top-8</h5></Col>
        <Col sm={3} className="pl-0"><h5 className="brand-title">Semifinal</h5></Col>
        <Col sm={3} className="pl-0"><h5 className="brand-title">Final & Play-off</h5></Col>
      </Row>
      <div className={styles.Bracket}>
        {data.map((bracket, bi) => (
          <div key={bi} className={classNames(styles.round, styles[`round${bracket.round}`])}>
            {/*<h5 className="brand-title">{bracket.round !== 2 ? `Top-${bracket.round}` : "Final & Play-Off"}</h5>*/}
            <div className={styles.roundInner}>
              {bracket.pairs.map((pair, pi) => (
                <Pair {...pair} key={pi}/>
              ))}
            </div>
            <Connectors count={bracket.pairs.length / 2}/>
          </div>
        ))}
      </div>
    </Container>
    
  )
}