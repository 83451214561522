import React, {useCallback, useEffect, useRef, useState} from 'react'

import {ButtonGroup, Dropdown, DropdownButton, Form} from "react-bootstrap"
import {Icon} from "components/Icon"
import {Trans, useTranslation} from "react-i18next"
import {
  CARPHOTO, CARPHOTO_THUMB_SIZE
} from "config"
import classNames from "classnames"

import {getThumbURL} from "utils"

import {SmartImage} from "components"
import {CarPlaceholder} from "svg"

import styles from './Garage.module.scss'

const PhotoRequirements = {
  aspectHeight: CARPHOTO.ASPECT_HEIGHT,
  aspectWidth: CARPHOTO.ASPECT_WIDTH,
  fileSize: CARPHOTO.MAX_FILESIZE_MB,
  minHeight: CARPHOTO.MIN_HEIGHT,
  minWidth: CARPHOTO.MIN_WIDTH
}

type Props = {
  previousPhoto?: string;
  register: (input: any, rules: any) => void;
  setValue: (name: string, value: any) => void;
  triggerValidation: (name:string) => void;
  isDirty: boolean;
  isRemoving: boolean;
  isValid: boolean;
};

export const CarPhotos: React.FC<Props> = ({ previousPhoto, register, triggerValidation, setValue, isDirty, isValid, isRemoving}) => {

  const { t } = useTranslation('CarPhotos')
  const [ photo, setPhoto ] = useState<string | undefined>()

  useEffect(() => {
    setPhoto(previousPhoto)
  }, [previousPhoto])

  const validateImage = useCallback(async (value:any) => new Promise (resolve => {
    if (!value) resolve(true)

    if (!value.size || value.size > PhotoRequirements.fileSize * 1024 * 1024) resolve(false)

    let reader = new FileReader()

    reader.onload = function (e) {
      let image = new window.Image()
      if (!e.target) resolve(false)
      image.src = e.target!.result as string

      //Validate the File Height and Width.
      image.onload = function () {
        const { width, height } = image
        const isValid =
          width/height === PhotoRequirements.aspectWidth / PhotoRequirements.aspectHeight
          && width >= PhotoRequirements.minWidth
          && image.height >= PhotoRequirements.minHeight

        setPhoto(e.target!.result as string)
        resolve(isValid)
      }
      image.onerror = function () {
        resolve(false)
      }
    }
    reader.readAsDataURL(value)
  }), [setPhoto])

  const newPhotoInput = useRef<HTMLInputElement>(null)
  useEffect(() => {
    register({ name: 'newPhoto' }, { required: !previousPhoto, validate: async (value: any) => await validateImage(value) })
    register({ name: 'removePhoto' }, undefined)
  }, [previousPhoto, register, validateImage])

  const revertPhoto = useCallback(() => {
    setPhoto(previousPhoto)
    setValue('newPhoto', undefined)
    setValue('removePhoto', undefined)
    newPhotoInput.current!.value = ""
    triggerValidation('newPhoto')
  }, [setPhoto, previousPhoto, setValue, newPhotoInput, triggerValidation])

  // const deletePhoto = useCallback(() => {
  //   setValue('removePhoto', true)
  //   setPhoto(undefined)
  // }, [setValue, setPhoto])

  const onChange = useCallback(() => {
    if (newPhotoInput.current && newPhotoInput.current.files && newPhotoInput.current.files[0]) {
      setValue('newPhoto', newPhotoInput.current.files[0])
      setValue('removePhoto', undefined)
      triggerValidation('newPhoto')
    }
  }, [newPhotoInput, setValue, triggerValidation])

  return (
    <div className={styles.CarPhotos}>
      <Form.Group>
        <input type="file"
               hidden
               name="newPhoto"
               accept="image/jpeg,image/png"
               ref={newPhotoInput}
               onChange={onChange}
        />
        <Form.Label className="required">{t('LABEL')}</Form.Label>
        <SmartImage fluid
            src={isDirty ? photo : getThumbURL(photo, CARPHOTO_THUMB_SIZE.sm)}
            className={classNames(!isValid && styles.invalid)}
            placeholderClass={styles.placeholder}
            placeholder={CarPlaceholder}
        >
          <DropdownButton
            title={
              <span>
                    <Icon type="createOutline" className="mr-1"/>
                    <span className="align-middle">{t('EDIT')}</span>
                  </span>} id="edit-photo-dropdown"
            as={ButtonGroup}
            size="sm"
            variant={"secondary"}
            className={styles.editPhoto}>
            <Dropdown.Item onClick={() => newPhotoInput.current && newPhotoInput.current.click()}>{t('SELECT')}</Dropdown.Item>
            {/*{(photo || isDirty) && <Dropdown.Item onClick={isDirty ? revertHeroshot : deletePhoto}>{isDirty ? t('CLEAR') : t('REMOVE')}</Dropdown.Item>}*/}
            {isDirty && <Dropdown.Item onClick={revertPhoto}>{t('CLEAR')}</Dropdown.Item>}
            {isRemoving && <Dropdown.Item onClick={revertPhoto}>{t('RESTORE')}</Dropdown.Item>}
          </DropdownButton>
        </SmartImage>
        {/*<SmartButton iconType="imageOutline" variant="secondary" block onClick={() => newPhotoInput.current && newPhotoInput.current.click()}>{t('ADD')}</SmartButton>*/}
      </Form.Group>
      {/*{isRemoving && <Form.Text className={"text-warning"}>*/}
      {/*  <Trans t={t} i18nKey={'REMOVING'}/>*/}
      {/*</Form.Text>}*/}
      {!isDirty && !isValid && <Form.Text className={"text-danger"}><Trans t={t} i18nKey={'REQUIRED'}/></Form.Text>}
      {!isDirty && !isRemoving && <Form.Text className="text-muted">
        {t('TEXT')}
        <ul className={styles.requirements}>
          {typeof t('REQUIREMENTS', { returnObjects: true }) === 'object' &&
          t<string[]>('REQUIREMENTS', { returnObjects: true, ...PhotoRequirements })
            .map((item, key) => (
                <li key={`req-${key}`}>{item}</li>
              )
            )}
        </ul>
      </Form.Text>}
      {isDirty && isValid && <Form.Text className={"text-warning"}><Trans t={t} i18nKey={'WARNING'}/></Form.Text>}
      {isDirty && !isValid && <Form.Text className={"text-danger"}>
        {t('INVALID')}
        <ul className={styles.requirements}>
          {typeof t('REQUIREMENTS', { returnObjects: true }) === 'object' &&
          t<string[]>('REQUIREMENTS', { returnObjects: true, ...PhotoRequirements })
            .map((item, key) => (
                <li key={`req-${key}`}>{item}</li>
              )
            )}
        </ul>
      </Form.Text>}
    </div>
  )
}