import React, {useState, Fragment, useCallback} from 'react'

import {Col, ListGroup, ListGroupItem, Modal, Row} from "react-bootstrap"
import {useDispatch, useSelector} from "react-redux"
import {getRequestDetails} from "redux/selectors"
import {closeRequestDetails} from "redux/actions"
import {RequestDetails} from "./RequestDetails"

import classNames from "classnames"
import {useTranslation} from "react-i18next"
import {RequestActions} from "pages/Member/Requests/RequestActions"
import {getDateFromTimestamp, truncDocId} from "utils"

import styles from './Requests.module.scss'
import {CopyText, SmartImage} from "components"
type Props = {

};

export const RequestPopup: React.FC<Props> = (props) => {

  const { t } = useTranslation('RequestPopup')
  const dispatch = useDispatch()
  const requestDetails = useSelector(getRequestDetails)
  const [ isProcessing, setProcessing ] = useState(false)

  const handleHide = () => dispatch(closeRequestDetails())
  const onProcessing = useCallback((processing: boolean) =>
    setProcessing(processing), [setProcessing])

  return (
    <Modal
      scrollable
      show={requestDetails !== null}
      onHide={handleHide}
      backdrop={!isProcessing || 'static'}
      keyboard={!isProcessing}
      className={styles.RequestPopup}
    >
      <Modal.Header className="align-items-center">
        <Modal.Title as="h5">{t('TITLE')}</Modal.Title>
        <RequestActions as="popup" request={requestDetails!} onHidePopup={handleHide} onProcessing={onProcessing}/>
      </Modal.Header>
      <Modal.Body>
        {requestDetails && <Fragment key={requestDetails.author.id}>
          <h6>{t('AUTHOR')}</h6>
          <div className={classNames(styles.section, styles.author)}>
            <div className="mr-3 p-1">
              <SmartImage src={requestDetails.author.avatarUrl} roundedCircle className={styles.avatar}/>
            </div>
            <div>
              <p className="mb-2">{requestDetails.author.displayName}</p>
              <p className="text-muted mb-2">{requestDetails.author.email}</p>
              <p className="text-muted mb-2">UID: <CopyText text={requestDetails.author.id} showTick alertKey="">{truncDocId(requestDetails.author.id)}</CopyText></p>
              {/*{author.profileId && <p className="text-muted mb-0">{t('PROFILE_ID')} <a href={i18path(`${ROUTE.DRIVERS}/${author.profileId}`)} target="_blank" rel="noopener noreferrer">{author.profileId}</a></p>}*/}
              {/*{!author.profileId && <p className="text-warning mb-0"><em>{t('NO_PROFILE')}</em></p>}*/}
            </div>
          </div>
          <h6>{t('DESCRIPTION')}</h6>
          <div className={styles.section}>
            <ListGroup variant="flush" className="px-2">
              <ListGroupItem className="p-1">
                <Row>
                  <Col lg={6} className="text-muted">{t('REQUEST_ID')}</Col>
                  <Col lg={6}><CopyText text={requestDetails.id} showTick alertKey="">{truncDocId(requestDetails.id)}</CopyText></Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem className="p-1">
                <Row>
                  <Col lg={6} className="text-muted">{t('CREATED')}</Col>
                  <Col lg={6}>{getDateFromTimestamp(requestDetails.createdAt).fromNow()}</Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem className="p-1">
                <Row>
                  <Col lg={6} className="text-muted">{t('TYPE')}</Col>
                  <Col lg={6}>{t(`MemberRequests:TYPES_DESCRIPTION.${requestDetails.type}`)}</Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </div>
          <h6>{t('CHANGES')}</h6>
          <div className={styles.section}>
            <RequestDetails request={requestDetails}/>
          </div>
          {/*TODO: GET BACK HERE AFTER POPULATING WITH APPROVERS*/}
          {/*{requestDetails.votes && Object.keys(requestDetails.votes).length && <><h6>{t('APPROVED_BY')}</h6>*/}
          {/*{Object.keys(requestDetails.votes).map(uid => (*/}
          {/*  <div className={styles.approver} key={uid}>*/}
          {/*    <div className="mr-2">{users![uid].avatarUrl ? <Image src={users![uid].avatarUrl} roundedCircle className={styles.avatar}/> : <Icon type="avatar" className={styles.avatar}/>}</div>*/}
          {/*    <div>{users![uid].displayName}</div>*/}
          {/*  </div>*/}
          {/*))}</>}*/}
        </Fragment>}
      </Modal.Body>
      <Modal.Footer/>
    </Modal>
  )
}