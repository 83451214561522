import React, {useEffect} from 'react'
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import {ComingSoon, ContentPage, ContentPageMode, Driver, DriversList, Home, Results, Standings, CarPage, CarsListPage} from "pages"
import {ROUTE} from "config"
import {Loading, PrivateRoute, ScrollToTop} from "components"
import {isLoaded, useFirestoreConnect} from "react-redux-firebase"
import {useDispatch, useSelector} from "react-redux"
import {setDefaultSeriesId} from "redux/actions/GlobalActions"
import {getDefaultSeries, getDefaultSeriesId, queryDefaultSeries} from "redux/selectors"
import {Member} from "pages/Member"

import i18n from "i18next"
import {getCurrentLocale} from "i18n"
import {FirebaseAnalytics} from "components/Analytics/FirebaseAnalytics"
import {SiteContentType} from "redux/reducers"
import moment from "moment"
import 'moment/locale/uk'


function App() {
  const dispatch = useDispatch()

  //TODO: We could use storeAs to store default series without separate reducer/action for that
  useFirestoreConnect([
    queryDefaultSeries
  ])
  const series = useSelector(getDefaultSeries)
  const defaultSeriesId = useSelector(getDefaultSeriesId)

  useEffect(() => {
    if (isLoaded(series) && series[0]) {
      dispatch(setDefaultSeriesId(series[0].id))
    }
  }, [series, dispatch])

  if (!isLoaded(series)) return <Loading/>
  
  return (
    <BrowserRouter>
      <FirebaseAnalytics/>
      <Switch>

        <Route path={`/:locale([a-z]{2})?/*`}>
          {
            // todo: use custom element with useRouteMatch() to remove ts-ignore
            //@ts-ignore
            ({match: { url, params: { locale } }}) => {

              if (locale && locale !== getCurrentLocale()) {
                i18n.changeLanguage(locale)
                moment.locale(locale)
              }
              const currentLocale = getCurrentLocale()
              if (locale !== currentLocale && currentLocale !== 'cimode') return (
                <Redirect to={locale ? url.replace(locale, currentLocale) : `/${currentLocale}${url}`}/>
              )

              const getRoot = () => {
                return locale ? `/${locale}` : ""
              }
              
              return (
                <Switch>
                  <Route path={`${getRoot()}${ROUTE.HOME}`} component={Home}/>
                  <Route path={`${getRoot()}${ROUTE.SCHEDULE}`} component={Home}/>
                  <Route path={`${getRoot()}${ROUTE.STANDINGS}/:seriesId/:stageId`} component={Results}/>
                  <Route path={`${getRoot()}${ROUTE.STANDINGS}/:seriesId`} component={Standings}/>
                  <Route path={`${getRoot()}${ROUTE.REGULATIONS}/:id?`}>
                    <ContentPage contentType={SiteContentType.regulations} mode={ContentPageMode.one}/>
                  </Route>

                  <Route path={`${getRoot()}${ROUTE.DRIVERS}/:type(pro|street|all)`} component={DriversList}/>
                  <Route path={`${getRoot()}${ROUTE.DRIVERS}/:driverId`} component={Driver}/>
                  <Route exact path={`${getRoot()}${ROUTE.CARS}`} component={CarsListPage}/>
                  <Route path={`${getRoot()}${ROUTE.CARS}/:carId`} component={CarPage}/>
                  <Route path={`${getRoot()}${ROUTE.LOCATIONS}`} component={Home}/>
                  <Route path={`${getRoot()}${ROUTE.PARTNERS}`} component={Home}/>

                  <Route path={`${getRoot()}${ROUTE.NEWS}`} component={Home}/>
                  <Route path={`${getRoot()}${ROUTE.ARTICLES}`} component={Home}/>
                  <Route path={`${getRoot()}${ROUTE.PHOTO_VIDEO}`} component={Home}/>

                  <PrivateRoute path={`${getRoot()}${ROUTE.MEMBERS}`}>
                    <Member/>
                  </PrivateRoute>

                  <Redirect exact from={`${getRoot()}${ROUTE.DRIVERS}`} to={`${getRoot()}${ROUTE.DRIVERS}/pro`}/>
                  {defaultSeriesId && <Redirect exact from={`${getRoot()}${ROUTE.STANDINGS}`} to={`${getRoot()}${ROUTE.STANDINGS}/${defaultSeriesId}`}/>}
                  <Route component={ComingSoon}/>
                </Switch>
              )}}
        </Route>

      </Switch>
      <ScrollToTop/>
    </BrowserRouter>
  )
}

export default App
