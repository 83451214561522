import moment from 'moment'
/**
 * Get age by birthdate
 * @param birthday {Date}
 * @return Full age in years
 */
export function getAge(birthday: Date):number {
  const ageDifMs = Date.now() - birthday.getTime()
  const ageDate = new Date(ageDifMs)
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

/**
 *
 * @param date {string} Date in format 2020-05-02
 * @returns {string} Date in format 2 May 2020
 */
export function formatDateToReadable(date: string | Date) {
  const d = typeof date === "string" ? new Date(date) : date
  const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
  const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)

  return `${da} ${mo} ${ye}`
}

/**
 * Helper function to get readable date from Firebase timestamp
 * @param timestamp {timestamp}
 */
export function getDateFromTimestamp(timestamp: any) {
  return moment(timestamp.toDate())
}