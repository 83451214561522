import React, {useEffect, useState} from 'react'

import styles from './EmptyProfile.module.scss'
import {Button, Form, Modal, Spinner} from "react-bootstrap"
import Select from "react-select"
import {isLoaded, useFirebase, useFirestore} from "react-redux-firebase"
import {MemberProfile, MemberRequestType} from "redux/reducers"
import {Controller, useForm} from "react-hook-form"
import {useDispatch, useSelector} from "react-redux"
import {createRequest} from "utils"
import {useTranslation} from "react-i18next"
import {selectorStyles, selectorTheme} from "utils"
import {showAlert} from "redux/actions"
import _pick from "lodash/pick"
import {getAuthUid, getUserProfile} from "redux/selectors"

type SelectOption = {
  value: string;
  label: string;
}

type Props = {
  show: boolean;
  onHide: () => void;
  profiles: MemberProfile[]
};

export const LinkPopup: React.FC<Props> = ({ show, onHide, profiles }) => {

  const { t } = useTranslation('LinkPopup')
  const dispatch = useDispatch()
  // form validation hook & state
  const { handleSubmit, control, errors } = useForm()
  // form processing state
  const [ isProcessing, setProcessing] = useState(false)
  const [ error, setError] = useState<string|null>(null)

  const [options, setOptions]= useState<SelectOption[]>([])

  useEffect(() => {
    if (isLoaded(profiles)) {
      setOptions(profiles.filter((p:MemberProfile) => !p.userId).map((p:MemberProfile) => ({ value: p.id, label: t(`profiles:${p.id}.fullName`)})))
    }
  }, [profiles, t])

  const firebase = useFirebase()
  const firestore = useFirestore()
  const uid = useSelector(getAuthUid)
  const profile = useSelector(getUserProfile)

  const startProcessing = () => {
    setProcessing(true)
    setError(null)
  }
  const onError = (error: string) => {
    setProcessing(false)
    setError(error)
  }
  const onSuccess = () => {
    dispatch(showAlert({content: t('alerts:PROFILE.LINKED'), props: { variant: "primary"}}))

    setProcessing(false)
    onHide()
  }

  const linkProfile = (profileId: string, comment: string) => {
    startProcessing()

      return createRequest({
        id: uid,
        ..._pick(profile, ['avatarUrl', 'email', 'displayName'])
      }, null, MemberRequestType.PROFILE_LINK, { profileId }, firestore, comment)
      .then(() => {
        firebase.updateProfile({ profileId })
        onSuccess()
      })
      .catch((e) => onError(e.message))
  }
  const onSubmit = handleSubmit(({profileId: { value }, comment}) => { linkProfile(value, comment) })
  
  return (
    <Modal show={show} onHide={onHide} backdrop={!isProcessing || 'static'} keyboard={!isProcessing}>
      <Form onSubmit={onSubmit}>
        <fieldset disabled={isProcessing}>
          <Modal.Header>
            <Modal.Title className="h5">{t('TITLE')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label className="required">
                {t('SELECT.LABEL')}
              </Form.Label>
              <Controller
                control={control}
                as={Select}
                name="profileId"
                rules={{
                  required: true
                }}
                options={options}
                className="select"
                theme={selectorTheme}
                styles={selectorStyles(!errors.profileId)}
                placeholder={t('SELECT.PLACEHOLDER')}
                isClearable
              />
              <Form.Text className={error || errors.profileId ? "text-danger" : "text-muted"}>
                {(error && t(error, error)) || t('SELECT.TEXT')}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">
                {t('COMMENT.LABEL')}
              </Form.Label>
              <Controller
                control={control}
                as={ <Form.Control as="textarea" rows="2" />}
                name="comment"
                rules={{
                  required: true,
                  minLength: 5,
                  maxLength: 150
                }}
                className={styles.comment}
                isInvalid={errors.comment}
                placeholder={t('COMMENT.PLACEHOLDER')}
              />
              <Form.Text className={errors.comment ? "text-danger" : "text-muted"}>
                {t('COMMENT.TEXT')}
              </Form.Text>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="mr-2" type="submit">
              { isProcessing && <Spinner animation="border" size="sm" as="span" role="status" aria-hidden="true" className="mr-2 align-middle" /> }
              <span className="align-middle">{t('SUBMIT')}</span>
            </Button>
            <Button variant="secondary" onClick={onHide}>{t('CANCEL')}</Button>
          </Modal.Footer>
        </fieldset>
      </Form>
    </Modal>
  )
}