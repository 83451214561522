import React, {useCallback, useEffect, useState} from 'react'

import { useForm, Controller } from 'react-hook-form'
import {useDispatch, useSelector} from "react-redux"
import {useFirebase} from "react-redux-firebase"
import { Button, Form, Spinner } from 'react-bootstrap'

import {ANIMATION_TIMEOUT, FIREBASE_ERROR_CODES} from "config"

import {SocialLogin} from "./SocialLogin"
import {setReauthenticate, showAlert} from "redux/actions/GlobalActions"

import styles from './AuthPopup.module.scss'
import {getAuthProvider} from "redux/selectors/UserSelectors"
import {useTranslation} from "react-i18next"
import {getAuthError} from "redux/selectors"

export type ProcessingType = 'email'|'google'|'facebook'
type ProcessingState = { isProcessing: boolean; processingType: ProcessingType | null }

interface Props {
  onSuccess?: () => void;
  onRecover?: () => void;
  onProcessing?: (processing: boolean) => void;
}

export const ConfirmForm: React.FC<Props> = ({ onSuccess, onRecover, onProcessing}) => {

  const { t } = useTranslation('ConfirmForm')
  const dispatch = useDispatch()

  // form validation hook & state
  const { handleSubmit, control, errors } = useForm()
  // form processing state
  const [{ isProcessing, processingType }, setProcessing] = useState<ProcessingState>({ isProcessing: false, processingType: null })

  useEffect(() => {
    onProcessing!(isProcessing)
  }, [isProcessing, onProcessing])

  const handleSuccess = useCallback(() => {
    setTimeout(() => {
      dispatch(showAlert({content: t('alerts:AUTH.CONFIRMED_AUTHORITY'), props: { variant: "success"}}))
    }, ANIMATION_TIMEOUT)
    setProcessing({ isProcessing: false, processingType: null })
    dispatch(setReauthenticate(true))
    dispatch(setReauthenticate(false))
    onSuccess!()
  }, [dispatch, t, setProcessing, onSuccess])

  // form processing methods
  const startProcessing = useCallback((processingType: ProcessingType = 'email') => setProcessing({ isProcessing: true, processingType }), [setProcessing])
  const stopProcessing = useCallback((processingType: ProcessingType = 'email', success: boolean = true) => {
    setProcessing({ isProcessing: false, processingType })
    if (success) handleSuccess()
  }, [setProcessing, handleSuccess])

  // Authentication hooks & states
  const firebase = useFirebase()
  const authError = useSelector(getAuthError)

  const currentProvider = useSelector(getAuthProvider)

  const confirmWithPassword = useCallback((password: string) => {
    startProcessing()

    const user = firebase.auth().currentUser
    // @ts-ignore
    const credential = firebase.auth.EmailAuthProvider.credential(user?.email, password)

    // @ts-ignore
    return firebase.reauthenticate({credential})
      .then(() => stopProcessing())
      // @ts-ignore
      .catch((e) => { console.log(e); stopProcessing('email', false)})
  }, [startProcessing, firebase, stopProcessing])
  const onSubmit = handleSubmit(({ password }) => { confirmWithPassword(password) })

  return (
    <Form className={styles.form} onSubmit={onSubmit}>
      <fieldset className={styles.fieldset} disabled={isProcessing as boolean}>
        {(!currentProvider || currentProvider === 'email') &&
          <>
            <Form.Group controlId="formEmail">
              <Controller
                as={Form.Control}
                control={control}
                type="password"
                placeholder={t('PASSWORD')}
                name="password"
                rules={{
                  required: true,
                  minLength: 8
                }}
                isInvalid={errors.password || (authError && !isProcessing && processingType === 'email' && (authError.code === FIREBASE_ERROR_CODES.WRONG_PASSWORD || authError.code === FIREBASE_ERROR_CODES.USER_DISABLED))}
              />
              {
                authError &&
                !isProcessing &&
                processingType === 'email' &&
                authError.code === FIREBASE_ERROR_CODES.WRONG_PASSWORD &&
                <Form.Text className="text-danger">
                  {authError.message}
                </Form.Text>
              }
            </Form.Group>

            <Form.Group>
              <Button variant="primary" type="submit" block>
                { isProcessing && processingType === "email" && <Spinner animation="border" size="sm" as="span" role="status" aria-hidden="true" className="mr-2 ml-n4 align-middle" /> }
                <span className="align-middle">{t('SUBMIT')}</span>
              </Button>
            </Form.Group>
            {
              authError && !isProcessing &&
              processingType === 'email' &&
              authError.code !== FIREBASE_ERROR_CODES.WRONG_PASSWORD &&
              authError.code !== FIREBASE_ERROR_CODES.USER_NOT_FOUND &&
              authError.code !== FIREBASE_ERROR_CODES.USER_DISABLED &&
              <p className="text-danger text-center mt-4 small">
                {authError.message}
              </p>
            }
            <Form.Group className="text-center mb-0">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={e => { e.preventDefault(); onRecover!() }} href="#">{t('FORGOT_PASSWORD')}</a>
            </Form.Group>
          </>}

        {!currentProvider &&
          <div className={styles.or}>
            <small>{t('OR')}</small>
          </div>}
        {(!currentProvider || currentProvider === 'google') &&
          <SocialLogin
            isProcessing={isProcessing}
            processingType={processingType}
            onStartProcessing={startProcessing}
            onStopProcessing={stopProcessing}
            loginType="CONFIRM"
            provider="google"
          />}
        {(!currentProvider || currentProvider === 'facebook') &&
          <SocialLogin
            isProcessing={isProcessing}
            processingType={processingType}
            onStartProcessing={startProcessing}
            onStopProcessing={stopProcessing}
            loginType="CONFIRM"
            provider="facebook"
          />}

        {authError && !isProcessing && (processingType === 'google' || processingType === 'facebook') &&
        <p className="text-danger text-center mt-3 mb-0 small">
          {authError.message}
        </p>}
      </fieldset>
    </Form>
  )
}

ConfirmForm.defaultProps = {
  onSuccess: () => {},
  onRecover: () => {},
  onProcessing: () => {},
}