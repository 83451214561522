import React from 'react'

import {Car, CarStatus} from "redux/reducers"
import {Link, useLocation} from 'react-router-dom'

import {Badge, Button, ButtonGroup, Card, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import {getCarName, getDateFromTimestamp, getThumbURL} from "utils"
import {CarPlaceholder} from "svg"

import styles from './Garage.module.scss'
import classNames from "classnames"
import {LinkContainer} from "react-router-bootstrap"
import {SmartButton, SmartImage} from "components"
import {CARPHOTO_THUMB_SIZE, MAX_CARS_COUNT, ROUTE} from "config"
import {CarDeletePopup} from "pages/Member/Garage/CarDeletePopup"
import {useDispatch} from "react-redux"
import {openCarDeletePopup} from "redux/actions"
import _pick from 'lodash/pick'
import {i18path} from "i18n"

type Props = {
  cars: Car[]
  baseUrl: string;
};

export const CarsList: React.FC<Props> = ({ cars, baseUrl }) => {

  const { t } = useTranslation('Garage')
  const dispatch = useDispatch()
  const location = useLocation()

  return (
    <>
      <div className="d-flex align-items-center">
        <h4 className="mb-0">{t('TITLE.GARAGE')}</h4>
        {cars.length < MAX_CARS_COUNT &&
        <LinkContainer to={`${baseUrl}/new`}>
          <SmartButton className="ml-auto" size="sm" iconType="addCircleOutline">{t('BUTTON.ADD')}</SmartButton>
        </LinkContainer>}
      </div>
      <hr className="mb-0"/>
      <Row className={styles.CarsList}>
        {cars.map((car, index) => {
          return (
            <Col lg={4} key={car.id}>
              <Card {...car.driver && {border: "primary"}} className={classNames("my-3", car.status === CarStatus.disabled && styles.disabled)}>
                {/*<Card.Header>#{index}</Card.Header>*/}
                <Link to={i18path(`${ROUTE.CARS}/${car.id}`)}>
                  <SmartImage fluid
                      src={getThumbURL(car.photo, CARPHOTO_THUMB_SIZE.sm)}
                      placeholder={CarPlaceholder}
                      placeholderClass={styles.carPlaceholder}
                      imageClass={styles.carImage}
                  >
                  {(car.driver || car.status === CarStatus.disabled) &&
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="status-tooltip">
                        {t(`TOOLTIP.${car.driver ? "PRO" : "SOLD"}`)}
                      </Tooltip>
                    }
                  >
                    <Badge pill className={styles.specialBadge} variant={car.driver ? "primary" : "secondary"}>
                      {t(`BADGE.${car.driver ? "PRO" : "SOLD"}`)}
                    </Badge>
                  </OverlayTrigger>}
                  </SmartImage>
                </Link>
                <Card.Footer className={styles.carFooter}>
                  <h5><Link className={styles.carLink} to={i18path(`${ROUTE.CARS}/${car.id}`)}>{getCarName(car)}</Link></h5>
                  <p className="text-muted">{t('UPDATED')} {(car.updatedAt ? getDateFromTimestamp(car.updatedAt).fromNow() : "-")}</p>
                  <ButtonGroup size="sm" className="btn-block">
                    <LinkContainer to={location.pathname + "/" + car.id}>
                      <SmartButton iconType="createOutline" variant="secondary">Edit</SmartButton>
                    </LinkContainer>
                    <Button variant="danger" onClick={() => dispatch(openCarDeletePopup(_pick(car, ['id', 'status', 'driver'])))}>Delete</Button>
                  </ButtonGroup>
                </Card.Footer>
              </Card>
            </Col>
        )})}
        <CarDeletePopup/>
      </Row>
    </>
  )
}