import firebase from "firebase"
import {ExtendedFirebaseInstance, ExtendedFirestoreInstance, ExtendedStorageInstance} from "react-redux-firebase"
import {MemberRequest, MemberRequestAuthor, MemberRequestType, SiteContentStatus} from "redux/reducers"
import _get from 'lodash/get'
import _pick from 'lodash/pick'
import _omit from 'lodash/omit'
import {FIREBASE_ERROR_CODES, FIREBASE_REGION, VOTES_NEEDED} from "config"

interface RequestSnapshot {
  [key: string]: any
}

enum ProcessErrors {
  incorrect_payload = 'alerts:REQUESTS.INCORRECT_PAYLOAD',
  unknown_type = 'alerts:REQUESTS.UNKNOWN_TYPE',
  incorrect_request = 'alerts:REQUESTS.INCORRECT_REQUEST',
}

/**
  * Create new request
  * @param author {MemberRequestAuthor} Author short ref
  * @param resolverId {string|null} Resolver (assignee) UID
  * @param type {MemberRequestType} Request type
  * @param payload {[key: string]: any} Payload of request. Might have different format for different request types.
  * @param firestore {ExtendedFirestoreInstance} Firestore instance
  * @param comment {string} Author's comment for request. Might be empty for different types of requests.
  */
export function createRequest(author: MemberRequestAuthor, resolverId: string | null, type: MemberRequestType, payload: {[key: string]: any}, firestore: ExtendedFirestoreInstance, comment?: string) {
  return firestore.add('requests', {
    author,
    resolverId,
    // @ts-ignore
    createdAt: firestore.Timestamp.now(),
    resolvedAt: null,
    isResolved: false,
    isApproved: false,
    isAgreed: false,
    type,
    votesNeeded: VOTES_NEEDED[type],
    payload,
    ...(comment && {comment})
  })
}
export function _createRequest(author: MemberRequestAuthor, resolverId: string | null, type: MemberRequestType, payload: {[key: string]: any}, comment?: string) {
  return firebase.firestore().collection('requests').doc().set({
    author,
    resolverId,
    createdAt: firebase.firestore.Timestamp.now(),
    resolvedAt: null,
    isResolved: false,
    isApproved: false,
    isProcessed: false,
    isAgreed: false,
    type,
    votesNeeded: VOTES_NEEDED[type],
    payload,
    ...(comment && {comment})
  })
}

/**
 * Approve (vote) request and resolve if possible
 * @param resolverId {string} Approver's UID to fill in request
 * @param request {MemberRequest} Member's request object
 * @param firestore {ExtendedFirestoreInstance} Firestore Instance
 * @param firebase {ExtendedFirebaseInstance & ExtendedStorageInstance} Firebase instance with Storage functions
 * @param resolveIfPossible=true {boolean} Flag to resolve request is maximum approve votes exceeded
 */
export function approveRequest(resolverId: string, request: MemberRequest, firestore: ExtendedFirestoreInstance, firebase: ExtendedFirebaseInstance & ExtendedStorageInstance, resolveIfPossible = true): Promise<{ isResolved: boolean }> {
  const requestId = request.id

  const docRef = firestore.collection("requests").doc(requestId)

  return docRef.get().then(doc => {
    if (doc.exists && doc.get('isResolved') === false) {
      const votes = doc.get('votes')
      const votesCount = votes ? Object.keys(votes).length : 0
      const votesNeeded = doc.get('votesNeeded')

      // resolve request if already possible
      if (resolveIfPossible && votesNeeded - votesCount === 1) {
        return resolveRequest(resolverId, request, firestore, firebase).then(() => ({isResolved: true}))
      } else {
        return docRef.update({
          votes: {...votes, [resolverId]: true}
        }).then(() => ({ isResolved: false }))
      }

    } else {
      return Promise.reject(ProcessErrors.incorrect_request)
    }
  })
}


/**
 * Resolve (final approve) request
 * @param resolverId {string} Approver's UID to fill in request
 * @param request {MemberRequest} Member's request object
 * @param firestore {ExtendedFirestoreInstance} Firestore Instance
 * @param firebase {ExtendedFirebaseInstance & ExtendedStorageInstance} Firebase instance with Storage functions
 */
export function resolveRequest(resolverId:string, request: MemberRequest, firestore: ExtendedFirestoreInstance, firebase: ExtendedFirebaseInstance & ExtendedStorageInstance): Promise<void> {
  const resolve = () => {
    switch (request.type) {
      case MemberRequestType.PROFILE_LINK:
        return resolveLinkProfile(request, firestore)
      case MemberRequestType.PROFILE_UNLINK:
        return resolveUnlinkProfile(request, firestore)
      case MemberRequestType.PROFILE_CHANGE:
        return resolveChangeProfile(request, firestore, firebase)
      case MemberRequestType.EDIT_CONTENT:
        return resolveEditContent(request, firestore)
      default:
        return Promise.reject(ProcessErrors.unknown_type)
    }
  }

  return resolve().then((snapshot) => closeRequest(resolverId, request, firestore, snapshot, true))
}

/**
 * Dismiss request
 * @param resolverId {string} Approver's UID to fill in request
 * @param request {MemberRequest} Member's request object
 * @param firestore {ExtendedFirestoreInstance} Firestore Instance
 * @param firebase {ExtendedFirebaseInstance & ExtendedStorageInstance} Firebase instance with Storage functions
 * @param reason {string} Reason of dismissal
 */
export function dismissRequest(resolverId: string, request: MemberRequest, firestore: ExtendedFirestoreInstance, firebase: ExtendedFirebaseInstance & ExtendedStorageInstance, reason: string): Promise<void> {
  const dismiss = () => {
    switch (request.type) {
      case MemberRequestType.PROFILE_LINK:
        return dismissLinkProfile(request, firestore)
      case MemberRequestType.PROFILE_CHANGE:
        return dismissChangeProfile(request, firebase)
      case MemberRequestType.EDIT_CONTENT:
        return dismissEditContent(request, firestore)

      case MemberRequestType.PROFILE_UNLINK:
        return Promise.resolve()
      default:
        return Promise.reject(ProcessErrors.unknown_type)
    }
  }

  return dismiss()
    .then(() => closeRequest(resolverId, request, firestore, undefined, false, reason))
}


/**
 * Close Request
 * @param resolverId {string} Approver's UID to fill in request
 * @param request {MemberRequest} Member's request object
 * @param firestore {ExtendedFirestoreInstance} Firestore Instance
 * @param isApproved {boolean} If request was approved or declined
 */
function closeRequest(resolverId: string, request: MemberRequest, firestore: ExtendedFirestoreInstance, snapshot: RequestSnapshot | undefined, isApproved = true, reason?: string): Promise<void> {

  const docRef = firestore.collection("requests").doc(request.id)

  return docRef.get().then(function(doc) {
    if (doc.exists) {
      return docRef.update({
        resolverId,
        // @ts-ignore
        resolvedAt: firestore.Timestamp.now(),
        isResolved: true,
        isAgreed: false,
        votes: {
          ...doc.get('votes'),
          [resolverId]: true
        },
        ...snapshot && {snapshot},
        isApproved,
        ...reason && {reason}
      })
    } else {
      return Promise.reject(ProcessErrors.incorrect_request)
    }
  })
}

/**
 * Mark request as closed and result agreed by author
 * @param request {MemberRequest} Request to agree
 * @param firestore {ExtendedFirestoreInstance} Firestore Instance
 */
export function agreeRequest(request: MemberRequest, firestore: ExtendedFirestoreInstance) {

  const docRef = firestore.collection("requests").doc(request.id)

  return docRef.get().then(function(doc) {
    if (doc.exists) {
      return docRef.update({
        //@ts-ignore
        agreedAt: firestore.Timestamp.now(),
        isAgreed: true,
      })
    } else {
      return Promise.reject(ProcessErrors.incorrect_request)
    }
  })
}

// ------------------------------- //
// --- RESOLVE ACTIONS BY TYPE --- //
// ------------------------------- //

/**
 * Resolve request for linking profile
 * @description Payload format: `{ profileId: string }`
 * @param request {MemberRequest} Request to resolve
 * @param firestore {ExtendedFirestoreInstance} Firestore Instance
 */
function resolveLinkProfile(request: MemberRequest, firestore: ExtendedFirestoreInstance):Promise<RequestSnapshot> {
  const profileId = _get(request, ['payload', 'profileId'])
  if (!profileId) return Promise.reject(ProcessErrors.incorrect_payload)

  const docRef = firestore.collection("profiles").doc(profileId)

  return docRef.get().then(function(doc) {
    if (doc.exists && !doc.get('userId')) {
      return docRef.update({
          userId: request.author.id
        }).then(() => ({
          userId: null
        }))
    } else {
      // doc.data() will be undefined in this case
      return Promise.reject(ProcessErrors.incorrect_payload)
    }
  })
}

/**
 * Resolve request for unlinking profile
 * @description Payload format: `{ profileId: string }`
 * @param request {MemberRequest} Request to resolve
 * @param firestore {ExtendedFirestoreInstance} Firestore Instance
 */
function resolveUnlinkProfile(request: MemberRequest, firestore: ExtendedFirestoreInstance):Promise<RequestSnapshot> {
  const profileId = _get(request, ['payload', 'profileId'])
  if (!profileId) return Promise.reject(ProcessErrors.incorrect_payload)

  const docRef = firestore.collection("profiles").doc(profileId)

  return docRef.get().then(function(doc) {
    const userId = doc.get('userId') || null

    if (doc.exists && userId) {
      return docRef.update({
          userId: null
        }).then(() => {
          firestore.collection("users").doc(userId).update({
            profileId: null
          })
        }).then(() => ({
          userId
        }))
    } else {
      // doc.data() will be undefined in this case
      return Promise.reject(ProcessErrors.incorrect_payload)
    }
  })
}

/**
 * Resolve request for change profile
 * @description Payload format: `{ profileId: string, ...{[key: string]: any}}`
 * @param request {MemberRequest} Request to resolve
 * @param firestore {ExtendedFirestoreInstance} Firestore Instance
 */
async function resolveChangeProfile(request: MemberRequest, firestore: ExtendedFirestoreInstance, firebase: ExtendedFirebaseInstance & ExtendedStorageInstance): Promise<RequestSnapshot> {
  const { profileId, ...clearPayload } = request.payload
  if (!profileId) return Promise.reject(ProcessErrors.incorrect_payload)
  const payload = {
    ...clearPayload,
    isActive: true
  }

  const docRef = firestore.collection("profiles").doc(profileId)
  const doc = await docRef.get()

  if (doc.exists) {
    let snapshot = _pick(doc.data(), Object.keys(payload))

    if (snapshot['photo']) {
      const { fullPath } = firebase.storage().refFromURL(snapshot['photo'])

      if (fullPath) {
        // @ts-ignore
        const deleteImageWithThumbs = firebase.app().functions(FIREBASE_REGION).httpsCallable('deleteImageWithThumbs')
        // await firebase.deleteFile(fullPath)
        await deleteImageWithThumbs({path: fullPath})
          .then(() => Promise.resolve())
          .catch((e:any) => {
            if (e && e.code === FIREBASE_ERROR_CODES.OBJECT_NOT_FOUND) return Promise.resolve()
            return Promise.reject(e.message)
          })
        snapshot = _omit(snapshot, 'photo')
      }
    }

    return docRef.update(payload).then(() => snapshot)
  } else {
    // doc.data() will be undefined in this case
    return Promise.reject(ProcessErrors.incorrect_payload)
  }
}

/**
 * Resolve Edit content request
 * @param request {MemberRequest}
 * @param firestore {ExtendedFirestoreInstance}
 */
async function resolveEditContent(request: MemberRequest, firestore: ExtendedFirestoreInstance):Promise<RequestSnapshot> {
  const contentId = _get(request, ['payload', 'contentId'])
  const replacedId = _get(request, ['payload', 'replacedId'])
  if (!contentId) return Promise.reject(ProcessErrors.incorrect_payload)
  let snapshot:{[key:string]:any} = {}

  const draftRef = firestore.collection("content").doc(contentId)

  const doc = await draftRef.get()
  if (doc.exists) {
    if (replacedId) {
      const docRef = firestore.collection("content").doc(replacedId)

      const doc = await docRef.get()
      if (doc.exists) {
        await docRef.update({status: SiteContentStatus.disabled})
        snapshot.contentId = replacedId
      }
    }
    await draftRef.update({status: SiteContentStatus.published})
    return Promise.resolve(snapshot)
  } else {
    return Promise.reject(ProcessErrors.incorrect_payload)
  }


}

// ------------------------------- //
// --- DISMISS ACTIONS BY TYPE --- //
// ------------------------------- //

/**
 * Dismiss request for linking profile
 * @description We should clear `profileId` field of the user
 * @param request {MemberRequest} Request to resolve
 * @param firestore {ExtendedFirestoreInstance} Firestore Instance
 */
async function dismissLinkProfile(request: MemberRequest, firestore: ExtendedFirestoreInstance):Promise<void> {

  const docRef = firestore.collection("users").doc(request.author.id)

  const doc = await docRef.get()
  if (doc.exists) {
    return docRef.update({
      profileId: null
    })
  } else {
    return Promise.reject(ProcessErrors.incorrect_payload)
  }
}

/**
 * Dismiss request for changing profile
 * @description We should remove new `photo` if it was uploaded
 * @param request {MemberRequest} Request to resolve
 * @param firebase {ExtendedFirebaseInstance & ExtendedStorageInstance} Firebase instance with Storage functions
 */
function dismissChangeProfile(request: MemberRequest, firebase: ExtendedFirebaseInstance & ExtendedStorageInstance):Promise<void> {
  const photo = _get(request, ['payload', 'photo'])
  if (!photo) return Promise.resolve()

  const { fullPath } = firebase.storage().refFromURL(photo)

  if (!fullPath) return Promise.resolve()

  // @ts-ignore
  const deleteImageWithThumbs = firebase.app().functions(FIREBASE_REGION).httpsCallable('deleteImageWithThumbs')

  return deleteImageWithThumbs({path: fullPath})
    .then(() => Promise.resolve())
    .catch((e:any) => {
      if (e && e.code === FIREBASE_ERROR_CODES.OBJECT_NOT_FOUND) return Promise.resolve()
      return Promise.reject(e.message)
    })
}

/**
 * Dismiss request for content editing
 * @description We should remove the draft
 * @param request {MemberRequest} Request to resolve
 * @param firestore {ExtendedFirestoreInstance} Firestore instance
 */
async function dismissEditContent(request: MemberRequest, firestore: ExtendedFirestoreInstance):Promise<void> {
  const contentId = _get(request, ['payload', 'contentId'])
  if (!contentId) return Promise.resolve()

  const docRef = firestore.collection("content").doc(contentId)

  const doc = await docRef.get()
  if (doc.exists) {
    return docRef.delete()
  } else {
    return Promise.reject(ProcessErrors.incorrect_payload)
  }
}