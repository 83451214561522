import React from 'react'
import {MemberRequest, MemberRequestType} from "redux/reducers"
import {i18path} from "i18n"
import {ROUTE} from "config"
import {Trans, useTranslation} from "react-i18next"

type Props = {
  request: MemberRequest
};

export const RequestPreview: React.FC<Props> = ({ request }) => {

  const { t } = useTranslation('MemberRequests')

  switch (request.type) {
    case MemberRequestType.PROFILE_CHANGE: {
      const { profileId, ...clearPayload } = request.payload

      return <p className="text-muted mb-0">
        <Trans t={t} i18nKey={`PREVIEWS.${request.type}`} count={Object.keys(clearPayload).length}>
          Profile ID: <a href={i18path(`${ROUTE.DRIVERS}/${profileId}`)} target="_blank" rel="noopener noreferrer">{{profileId}}</a>; <strong>0</strong> changes
        </Trans>
      </p>
    }
    case MemberRequestType.PROFILE_UNLINK:
    case MemberRequestType.PROFILE_LINK: {
      const { profileId } = request.payload

      return <p className="text-muted mb-0">
        <Trans t={t} i18nKey={`PREVIEWS.${request.type}`} >
          Profile ID: <a href={i18path(`${ROUTE.DRIVERS}/${profileId}`)} target="_blank" rel="noopener noreferrer">{{profileId}}</a>
        </Trans>
      </p>
    }
    case MemberRequestType.CAR_NUMBER:
      const { seriesId, number } = request.payload

      return <p className="text-muted mb-0">
        <Trans t={t} i18nKey={`PREVIEWS.${request.type}`}>
          Series ID: <a href={i18path(`${ROUTE.SERIES}/${seriesId}`)} target="_blank" rel="noopener noreferrer">{{seriesId}}</a>; Number: <strong>{{number}}</strong>
        </Trans>
      </p>

    case MemberRequestType.EDIT_CONTENT: {
      const { contentType } = request.payload

      return <p className="text-muted mb-0">
        <Trans t={t} i18nKey={`PREVIEWS.${request.type}`}>
          Type: <strong>{{type: t(`CONTENT_TYPE.${contentType}`)}}</strong>
        </Trans>
      </p>
    }

    default:
      return null

  }
}