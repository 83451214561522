import {MemberRequestType} from "redux/reducers"

export const FUNCTIONS_EMULATOR_URL = "http://localhost:5001"

export const ROUTE = {
  HOME: "/home",

  UDRC: "/udrc",
  COMMUNITY: "/community",

  SCHEDULE: "/udrc/schedule",
  STANDINGS: "/udrc/standings",
  RESULTS: "/udrc/results",
  REGULATIONS: "/udrc/regulations",

  CARS: "/cars",
  PARTNERS: "/partners",
  DRIVERS: "/drivers",
  LOCATIONS: "/locations",
  REFEREES: "/referees",

  TEAMS: "/teams",

  INFO:"/info",
  NEWS: "/news",
  ARTICLES: "/articles",
  PHOTO_VIDEO: "/photos-videos",

  // PASSWORD: "/password",
  // SIGNUP: "/signup",
  // SIGNIN: "/signin",

  MEMBERS: "/u",

  REQUESTS: "/u/requests",
  SERIES: "/u/series",
  CONTENT: "/u/content",
  UPDATE_REGULATIONS: "/u/regulations",

  PROFILE: "/u/profile",
  GARAGE: "/u/garage",
  TEAM: "/u/team",
  PARTICIPATIONS: "/u/participations",
  RACES: "/races",
  SETTINGS: "/u/settings"
}

export const META = {
  TITLE: "Ukrainian Drift RC",
  MEMBER_AREA_TITLE: "UDRC Member's Area"
}

export const FIREBASE_REGION = 'europe-west1'

export const FIREBASE = {
  apiKey: "AIzaSyDbDj2uoLajKMmU6SxJY-B8Gd-8qitJ5Bs",
  authDomain: "udrccomua.firebaseapp.com",
  databaseURL: "https://udrccomua.firebaseio.com",
  projectId: "udrccomua",
  storageBucket: "udrccomua.appspot.com",
  messagingSenderId: "45173053720",
  appId: "1:45173053720:web:a7b0fe98b1a9b18446a66d",
  measurementId: "G-CM3MJSCNC1"
}

export const FIREBASE_ERROR_CODES = {
  USER_NOT_FOUND: "auth/user-not-found",
  USER_DISABLED: "auth/user-disabled",
  WRONG_PASSWORD: "auth/wrong-password",
  TOO_MANY_REQUESTS: "auth/too-many-requests",
  ACCOUNT_EXISTS: "auth/account-exists-with-different-credential",
  UNATHORIZED_DOMAIN: "auth/unauthorized-domain",
  ALREADY_IN_USE: "auth/email-already-in-use",
  REAUTH_REQUIRED: "auth/requires-recent-login",
  
  OBJECT_NOT_FOUND: "storage/object-not-found"
}

export const AUTH_VARIANT = {
  SIGNIN: "SIGNIN",
  SIGNUP: "SIGNUP",
  RECOVER: "RECOVER",
  CONFIRM: "CONFIRM"
}

export const ALERT_AUTOHIDE_TIMEOUT = 5000
export const ANIMATION_TIMEOUT = 300
export const RESEND_TIMEOUT = 60000
export const REAUTH_TIMEOUT = 30000

//TODO: REPLACE THIS WITH 30 BEFORE RELEASE
export const PUBLISH_REGULATIONS_DELAY = 30 //30
//TODO: REPLACE THIS WITH 30 BEFORE RELEASE
export const ACCEPT_RULES_DELAY = 5 //30
//TODO: REPLACE THIS WITH 15 BEFORE RELEASE
export const DISMISS_REQUEST_DELAY = 5 //15

export const CAR_DELETE_DELAY = 5

export const VOTES_NEEDED = {
  //TODO: CHANGE THIS to 2 BEFORE RELEASE
  [MemberRequestType.PROFILE_LINK]: 1, //2
  //TODO: CHANGE THIS to 2 BEFORE RELEASE
  [MemberRequestType.PROFILE_UNLINK]: 1, //2
  [MemberRequestType.PROFILE_CHANGE]: 1,
  //TODO: CHANGE THIS to 2 BEFORE RELEASE
  [MemberRequestType.CAR_NUMBER]: 1, //2
  [MemberRequestType.EVENT_APPLY]: 1,
  //TODO: CHANGE THIS to 2 BEFORE RELEASE
  [MemberRequestType.EDIT_CONTENT]: 1 // 2
}

export const HEROSHOT_ASPECT_WIDTH = 4 // 1100
export const HEROSHOT_ASPECT_HEIGHT = 3 // 825
export const HEROSHOT_MIN_WIDTH = 1024
export const HEROSHOT_MIN_HEIGHT = 768
export const HEROSHOT_MAX_FILESIZE_MB = 1
export const HEROSHOT_FILENAME_PREFIX = "heroshot-"
export enum HEROSHOT_THUMB_SIZE {
  sm = "320",
  lg = "1024"
}

export const CARPHOTO = {
  ASPECT_WIDTH: 16,
  ASPECT_HEIGHT: 9,
  MIN_WIDTH: 960,
  MIN_HEIGHT: 540,
  MAX_FILESIZE_MB: 3,
  FILENAME_PREFIX: "car-",
  FILENAME_EXTENSION: ".jpg"
}
export enum CARPHOTO_THUMB_SIZE {
  sm = "320",
  lg = "960"
}

export const EMAIL_PATTERN = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)
export const LASTNAME_EN_PATTERN = new RegExp(/^[a-z ,.'-]+$/i)
export const LASTNAME_PATTERN = new RegExp(/^[a-zа-яґєії ,.'-]+$/i)
export const DATE_PATTERN = new RegExp(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/i)
export const URL_PATTERN = new RegExp(/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i)

export const MAX_CARS_COUNT = 6