import React, {useState, useEffect} from 'react'
import Helmet from 'react-helmet'

import {
  Header,
  Footer,
  Leaderboard,
  Bracket,
  ResultsTable,
  Referees,
  Loading
} from "components"
import {useParams} from "react-router-dom"
import {useSelector} from "react-redux"
import {isEmpty, isLoaded, useFirestoreConnect} from "react-redux-firebase"
import {EventStage, Series} from "redux/reducers"
import { META } from 'config'
import {BracketData, getBracketData, getStageStandingsData, StageStanding} from "utils"
import {ErrorPage} from "pages"
import {addProfileTranslations} from "i18n"
import {
  getActiveDrivers, getActiveDriversData, getEventById, getRacesByEventId, getResultsByEventId, getSeriesById,
  queryActiveDrivers,
  queryEventById,
  queryRacesByEventId,
  queryResultsByEventId,
  querySeriesById
} from "redux/selectors"

// import styles from './Results.module.scss'

type Props = {

};

interface ResultsData {
  standings: StageStanding[];
  leaders: StageStanding[];
  bracket: BracketData[]
}

export const Results: React.FC<Props> = (props) => {

  const { seriesId, stageId } = useParams<{seriesId:string, stageId: string}>()

  useFirestoreConnect([
    querySeriesById(seriesId),
    queryEventById(stageId),
    queryRacesByEventId(stageId),
    queryResultsByEventId(stageId),
    queryActiveDrivers
  ])

  const series:Series | undefined = useSelector(getSeriesById(seriesId))
  const event:EventStage | undefined = useSelector(getEventById(stageId))
  const races = useSelector(getRacesByEventId(stageId))
  const results = useSelector(getResultsByEventId(stageId))
  const drivers = useSelector(getActiveDriversData)
  const driversOrdered = useSelector(getActiveDrivers)

  const [{ standings, leaders, bracket }, setStandings] = useState<ResultsData>({standings: [], leaders: [], bracket: []})

  useEffect(() => {
    if (isLoaded(series, event, races, results, drivers)) {
      const standings = getStageStandingsData(event!, races, results, drivers)
      const leaders = standings.slice(0, 3)
      const bracket = getBracketData(event!, races, drivers)
      setStandings({ standings, leaders, bracket })
    }
  }, [series, event, races, results, drivers])

  const [ tReady, setTReady ] = useState(false)

  useEffect(() => {
    if (isLoaded(driversOrdered)) {
      if (!isEmpty(driversOrdered)) addProfileTranslations(driversOrdered)
      setTReady(true)
    }
  }, [driversOrdered])

  if (isLoaded(series, event, races, results, drivers) && isEmpty(series, event)) return <ErrorPage message="We couldn't find the results of this tournament throughout our archives."/>

  return (
    <>
      <Helmet>
        {isLoaded(event) && <title>{event!.name} Results &mdash; {META.TITLE}</title>}
      </Helmet>
      <Header/>
      {isLoaded(series, event, races, results, drivers) && tReady ? <>
        <Leaderboard title={`${event!.name.toUpperCase()} Leaderboard`} leaders={leaders} seriesId={seriesId} promo="stage"/>
        <Bracket data={bracket}/>
        <Referees/>
        <ResultsTable standings={standings} title={`${event!.name.toUpperCase()} Results`}/>
      </> : <Loading/>}
      <Footer/>
    </>
  )
}