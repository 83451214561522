import React from 'react'
import {MemberRequest, MemberRequestType} from "redux/reducers"
import {i18path} from "i18n"
import {HEROSHOT_THUMB_SIZE, ROUTE} from "config"
import {useTranslation} from "react-i18next"
import {Col, ListGroup, ListGroupItem, Row} from "react-bootstrap"
import {Icon} from "components/Icon"
import {TFunction} from "i18next"

import styles from './Requests.module.scss'
import {getThumbURL, truncDocId} from "utils"
import {CopyText, SmartImage} from "components"
import {UserPlaceholder} from "svg"

type Props = {
  request: MemberRequest
};

const renderPayloadValue = (name: string, value: any, t:TFunction) => {
  switch (name) {
    case "photo":
      return value ? <>
        <SmartImage
          placeholder={UserPlaceholder}
          src={getThumbURL(value, HEROSHOT_THUMB_SIZE.sm)}
          className={styles.Heroshot}
          imageClass={styles.image}
          placeholderClass={styles.placeholder}
        />
        <a href={value} target="_blank" rel="noopener noreferrer">
          <Icon type="openOutline" className="mr-1"/>
          <span className="align-middle">{t('PAYLOAD.OPEN_PHOTO')}</span>
        </a>
      </> : <em className="text-warning">{t('PAYLOAD.REMOVED')}</em>

    case "preview":
      return <a href={i18path(value.url)} target="_blank" rel="noopener noreferrer">
        <Icon type="openOutline" className="mr-1"/>
        <span className="align-middle">{value.title}</span>
      </a>

    case "source":
      return <a href={value.url} target="_blank" rel="noopener noreferrer">
        <Icon type="documentTextOutline" className="mr-1"/>
        <span className="align-middle">{value.title}</span>
      </a>

    case "contentType":
      return t(`MemberRequests:CONTENT_TYPE.${value}`)
    case "contentId":
    case "replacedId":
      return <CopyText text={value} showTick alertKey="">{truncDocId(value)}</CopyText>

    default:
      return renderPayloadValueType(value)
  }
}

const renderPayloadValueType = (value: any) => {
  switch (typeof value) {
    case "boolean":
      return value ?
        <Icon type="checkMarkCircleOutline"/>:
        <Icon type="removeCircleOutline" className="text-muted"/>
    default:
      return value
  }
}

export const RequestDetails: React.FC<Props> = ({ request }) => {

  const { t } = useTranslation('RequestDetails')

  switch (request.type) {
    case MemberRequestType.PROFILE_CHANGE: {
      const {profileId, ...clearPayload} = request.payload

      return <ListGroup variant="flush" className="px-2">
        <ListGroupItem className="p-1">
          <Row>
            <Col lg={6} className="text-muted">{t('PROFILE_ID')}</Col>
            <Col lg={6}><a href={i18path(`${ROUTE.DRIVERS}/${profileId}`)} target="_blank"
                           rel="noopener noreferrer">{profileId}</a>
            </Col>
          </Row>
        </ListGroupItem>

        {Object.keys(clearPayload).map(key => (
          <ListGroupItem className="p-1" key={key}>
            <Row>
              <Col lg={6} className="text-muted">{t(`ProfileForm:LABEL.${key.toUpperCase()}`)}</Col>
              <Col lg={6}>{renderPayloadValue(key, clearPayload[key], t)}</Col>
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
    }
    case MemberRequestType.PROFILE_UNLINK:
    case MemberRequestType.PROFILE_LINK: {
      const {payload: {profileId, reason}, comment} = request


      return <ListGroup variant="flush" className="px-2">
        <ListGroupItem className="p-1">
          <Row>
            <Col lg={6} className="text-muted">{t('PROFILE_ID')}</Col>
            <Col lg={6}><a href={i18path(`${ROUTE.DRIVERS}/${profileId}`)} target="_blank"
                           rel="noopener noreferrer">{profileId}</a></Col>
          </Row>
        </ListGroupItem>
        {reason && <ListGroupItem className="p-1">
          <Row>
            <Col lg={6} className="text-muted">{t('REASON')}</Col>
            <Col lg={6}><em>{reason}</em></Col>
          </Row>
        </ListGroupItem>}
        <ListGroupItem className="p-1">
          <Row>
            <Col lg={6} className="text-muted">{t('COMMENT')}</Col>
            <Col lg={6}><em>{comment || t('EMPTY')}</em></Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    }
    case MemberRequestType.CAR_NUMBER:
      const {seriesId, number} = request.payload

      return <ListGroup variant="flush" className="px-2">
        <ListGroupItem className="p-1">
          <Row>
            <Col lg={6} className="text-muted">{t('SERIES_ID')}</Col>
            <Col lg={6}><a href={i18path(`${ROUTE.SERIES}/${seriesId}`)} target="_blank"
                           rel="noopener noreferrer">{seriesId}</a></Col>
          </Row>
        </ListGroupItem>
        <ListGroupItem className="p-1">
          <Row>
            <Col lg={6} className="text-muted">{t('CAR_NUMBER')}</Col>
            <Col lg={6}><strong>{number}</strong></Col>
          </Row>
        </ListGroupItem>
      </ListGroup>

    case MemberRequestType.EDIT_CONTENT: {
      const {payload: {url, title, ...clearPayload}, comment} = request

      return <ListGroup variant="flush" className="px-2">
        {Object.keys(clearPayload).map(key => (
          <ListGroupItem className="p-1" key={key}>
            <Row>
              <Col lg={6} className="text-muted">{t(`PAYLOAD.${key.toUpperCase()}`)}</Col>
              <Col lg={6}>{renderPayloadValue(key, clearPayload[key], t)}</Col>
            </Row>
          </ListGroupItem>
        ))}
        <ListGroupItem className="p-1">
          <Row>
            <Col lg={6} className="text-muted">{t('COMMENT')}</Col>
            <Col lg={6}><em>{comment || t('EMPTY')}</em></Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    }
    default:
      return <em className="text-center text-muted">{t('NOT_IMPLEMENTED')}</em>

  }
}