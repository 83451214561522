import React, {useCallback, useState} from 'react'

import {useDispatch, useSelector} from "react-redux"
import { Button, Modal } from 'react-bootstrap'

import {Icon} from "components"

import {AUTH_VARIANT} from "config"
import {getAuthPopupMode} from "redux/selectors"
import {closeAuthPopup, openAuthPopup} from "redux/actions"

import {SigninForm} from "./SigninForm"
import {SignupForm} from "./SignupForm"
import {RecoverForm} from "./RecoverForm"
import {ConfirmForm} from "./ConfirmForm"

import {Trans, useTranslation} from "react-i18next"

import styles from './AuthPopup.module.scss'


type Props = {

}

export const AuthPopup: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation('AuthPopup')

  const dispatch = useDispatch()
  const popupMode = useSelector(getAuthPopupMode)
  const [ isProcessing, setProcessing ] = useState(false)

  const handleClose = useCallback(() => {
    dispatch(closeAuthPopup())
  }, [dispatch])

  const handleSignup = useCallback(() => {
    dispatch(openAuthPopup(AUTH_VARIANT.SIGNUP))
  }, [dispatch])

  const handleRecover = useCallback(() => {
    dispatch(openAuthPopup(AUTH_VARIANT.RECOVER))
  }, [dispatch])

  const onProcessing = useCallback((processing: boolean) => setProcessing(processing), [setProcessing])

  return (
    <Modal show={popupMode !== null} onHide={handleClose} backdrop={!isProcessing || 'static'} keyboard={!isProcessing} className={styles.AuthPopup}>
      <Modal.Header>
        <Modal.Title className="h5">{popupMode && t(`TITLE.${popupMode}`)}</Modal.Title>
        {popupMode === AUTH_VARIANT.SIGNIN && <Button size="sm" variant="dark" onClick={handleSignup}>
          <Icon type="personAddOutline" className="mr-1"/>
          <span className="align-middle">{t('CREATE_ACCOUNT')}</span>
        </Button>}
      </Modal.Header>
      <Modal.Body>
        {popupMode === AUTH_VARIANT.SIGNIN && <SigninForm onSuccess={handleClose} onRecover={handleRecover} onProcessing={onProcessing}/>}
        {popupMode === AUTH_VARIANT.SIGNUP && <SignupForm onSuccess={handleClose} onProcessing={onProcessing}/>}
        {popupMode === AUTH_VARIANT.RECOVER && <RecoverForm onSuccess={handleClose} onProcessing={onProcessing}/>}
        {popupMode === AUTH_VARIANT.CONFIRM && <ConfirmForm onSuccess={handleClose} onRecover={handleRecover} onProcessing={onProcessing}/>}
      </Modal.Body>
      <Modal.Footer>
        {popupMode && <p className="flex-fill text-muted text-center small mb-0">
          <Trans t={t} i18nKey={`FOOTER.${popupMode}`}>
            By clicking Sign in with Email or Continue with Google or Facebook, you agree to UDRC's
            <a href="/terms-of-use" target="_blank">Terms of Use</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>.
            UDRC may send you communications. We'll never post without your permission.
          </Trans>
        </p>}
      </Modal.Footer>
    </Modal>
  )
}