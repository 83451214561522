import React from 'react'
import {useDispatch, useSelector} from "react-redux"
import { Route, RouteProps } from 'react-router-dom'
import {isEmpty, isLoaded} from "react-redux-firebase"
import {AUTH_VARIANT} from "config"
import {ErrorPage} from "pages"
import {openAuthPopup} from "redux/actions/GlobalActions"
import {getAuth} from "redux/selectors"

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
export const PrivateRoute:React.FC<RouteProps> = ({ children, ...rest }) => {

  const dispatch = useDispatch()
  const auth = useSelector(getAuth)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          children
        ) : (
          <ErrorPage
            title={'NOT_ALLOWED.TITLE'}
            message={'NOT_ALLOWED.MESSAGE'}
            action={() => dispatch(openAuthPopup(AUTH_VARIANT.SIGNIN))}
            buttonTitle={'NOT_ALLOWED.BUTTON'}
          />
        )
      }
    />
  )
}
