import React, {useCallback, useState} from 'react'
import {Button, Form, Modal, Spinner} from "react-bootstrap"
import {Controller, useForm} from "react-hook-form"
import Select from "react-select"
import styles from "pages/Member/Profile/EmptyProfile/EmptyProfile.module.scss"
import {selectorStyles, selectorTheme} from "utils"
import {useTranslation} from "react-i18next"
import {showAlert} from "redux/actions"
import {createRequest} from "utils"
import {MemberRequestType} from "redux/reducers"
import {useFirestore} from "react-redux-firebase"
import {useDispatch, useSelector} from "react-redux"
import {getAuthUid, getUserProfile} from "redux/selectors"
import _pick from "lodash/pick"

type Props = {
  show: boolean;
  onHide: () => void;
};

export const UnlinkPopup: React.FC<Props> = ({ show, onHide }) => {

  const { t } = useTranslation('UnlinkPopup')
  const dispatch = useDispatch()

  // form validation hook & state
  const { handleSubmit, control, errors } = useForm()
  // form processing state
  const [ isProcessing, setProcessing ] = useState(false)
  const [ error, setError] = useState<string|null>(null)

  const firestore = useFirestore()
  const uid = useSelector(getAuthUid)
  const profile = useSelector(getUserProfile)

  const getOptions = useCallback(() => {
    if (typeof t('SELECT.REASONS', { returnObjects: true}) === 'object')
      return t<string[]>('SELECT.REASONS', { returnObjects: true}).map(reason => ({
        value: reason,
        label: reason
      }))

    return null
  }, [t])

  const startProcessing = () => {
    setProcessing(true)
    setError(null)
  }
  const onError = (error: string) => {
    setProcessing(false)
    setError(error)
  }
  const onSuccess = () => {
    dispatch(showAlert({content: t('alerts:PROFILE.UNLINKED'), props: { variant: "primary"}}))

    setProcessing(false)
    onHide()
  }

  const unlinkProfile = (reason: string, comment: string) => {
    startProcessing()

    return createRequest({
      id: uid,
      ..._pick(profile, ['avatarUrl', 'email', 'displayName'])
    }, null, MemberRequestType.PROFILE_UNLINK, { profileId: profile.profileId, reason }, firestore, comment)
      .then(() => {
        // firebase.updateProfile({ profileId: null })
        onSuccess()
      })
      .catch((e) => onError(e.message))
  }
  const onSubmit = handleSubmit(({reason: { value: reason }, comment}) => { unlinkProfile(reason, comment) })

  return (
    <Modal show={show} onHide={onHide} backdrop={!isProcessing || 'static'} keyboard={!isProcessing}>
      <Form onSubmit={onSubmit}>
        <fieldset disabled={isProcessing}>
          <Modal.Header>
            <Modal.Title className="h5">{t('TITLE')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label className="required">
                {t('SELECT.LABEL')}
              </Form.Label>
              <Controller
                control={control}
                as={Select}
                name="reason"
                rules={{
                  required: true
                }}
                options={getOptions()}
                className="select"
                theme={selectorTheme}
                styles={selectorStyles(!errors.reason)}
                placeholder={t('SELECT.PLACEHOLDER')}
                isClearable
              />
              <Form.Text className={error || errors.reason ? "text-danger" : "text-muted"}>
                {(error && t(error, error)) || t('SELECT.TEXT')}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label className="required">
                {t('COMMENT.LABEL')}
              </Form.Label>
              <Controller
                control={control}
                as={ <Form.Control as="textarea" rows="2" />}
                name="comment"
                rules={{
                  required: true,
                  minLength: 5,
                  maxLength: 150
                }}
                className={styles.comment}
                isInvalid={errors.comment}
                placeholder={t('COMMENT.PLACEHOLDER')}
              />
              <Form.Text className={errors.comment ? "text-danger" : "text-muted"}>
                {t('COMMENT.TEXT')}
              </Form.Text>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="mr-2" type="submit" variant="danger">
              { isProcessing && <Spinner animation="border" size="sm" as="span" role="status" aria-hidden="true" className="mr-2 align-middle" /> }
              <span className="align-middle">{t('SUBMIT')}</span>
            </Button>
            <Button variant="secondary" onClick={onHide}>{t('CANCEL')}</Button>
          </Modal.Footer>
        </fieldset>
      </Form>
    </Modal>
  )
}