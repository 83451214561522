import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  Alert,
  Col,
  Form,
  Nav,
  Row,
  Tab,
} from "react-bootstrap"
import Datepicker from 'react-datepicker'
import {useDispatch, useSelector} from "react-redux"
import {Controller, useForm} from "react-hook-form"
import {useFirebase, useFirestore} from "react-redux-firebase"
import {MemberProfile, MemberRequestType} from "redux/reducers"
import {showAlert} from "redux/actions"
import _pick from 'lodash/pick'

import {HEROSHOT_FILENAME_PREFIX, LASTNAME_EN_PATTERN, LASTNAME_PATTERN} from "config"
import {MemberProfileBadge, MemberProfileStatus} from "pages/Member/Profile/status"

import moment from "moment"
import classNames from "classnames"
import Select from "react-select"
import {removeTokenFromURL, selectorStyles, selectorTheme, createShortId, createRequest} from "utils"
import {Trans, useTranslation} from "react-i18next"
import {getCitiesSelection, getCountriesSelection} from "utils"
import {getAuthUid, getUserProfile} from "redux/selectors"
import { Prompt } from 'react-router-dom'
import {ProfileHeroshot} from "pages/Member/Profile/ProfileHeroshot"

import styles from "./Profile.module.scss"
import {SmartButton} from "components"

const getPayloadName = (name: string): string => {
  const aliases: {[key:string]: string} = {
    "notDriver": "isDriver",
    "newPhoto": "photo",
    "removePhoto": "photo"
  }

  return aliases[name] || name
}

type Props = {
  status: MemberProfileStatus;
  badge: MemberProfileBadge;
  profileData: MemberProfile;
  profileId: string;
};

export const ProfileForm: React.FC<Props> = ({ status, badge, profileData, profileId }) => {

  const { t } = useTranslation('ProfileForm')
  const dispatch = useDispatch()

  const defaultValues = useMemo(() => ({
    ..._pick(profileData, ['name_en', 'surname_en', 'name_uk', 'surname_uk', 'bio_en', 'bio_uk', 'flags']),
    // hideAge: !!profileData.hideAge,
    birthDate: profileData.birthDate ? moment(profileData.birthDate).toDate() : undefined,
    debutYear: profileData.debutYear,
    //TODO: replace with date
    // @see https://github.com/Hacker0x01/react-datepicker/issues/1249#event-3307178607
    // debutYear: profileData.debutYear ? moment(profileData.debutYear).toDate() : undefined,
    country: profileData.country ? getCountriesSelection().find(country => country.value === profileData.country): undefined,
    city: profileData.country && profileData.city ? getCitiesSelection(profileData.country).find(city => city.value === profileData.city): undefined,
    // notDriver: !profileData.isDriver,
    newPhoto: undefined,
    removePhoto: undefined,
  }), [profileData])

  // form validation hook & state
  const { handleSubmit, errors, control, watch, formState, reset, register, setValue, triggerValidation, getValues } = useForm({
    ...profileData && { defaultValues }
  })

  useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  // @ts-ignore
  const country = watch('country')
  const { dirtyFields } = formState
  
  // form processing state
  const [ isProcessing, setProcessing ] = useState(false)
  const isDisabled = useMemo(() => {
    return status !== MemberProfileStatus.active && status !== MemberProfileStatus.new
  }, [status])
  const [ isCollapsed, setCollapsed ] = useState(true)

  const handleSuccess = useCallback(() => {
    dispatch(showAlert({content: t('alerts:PROFILE.SAVED'), props: { variant: "primary"}}))
    setProcessing(false)
    setCollapsed(true)
  }, [dispatch, setProcessing, t])

  const handleFail = useCallback((message: string) => {
    dispatch(showAlert({content: t('GENERAL_ERROR_MESSAGE', { message }), props: { variant: "danger"}}))
    setProcessing(false)
  }, [dispatch, setProcessing, t])

  const uid = useSelector(getAuthUid)
  const profile = useSelector(getUserProfile)
  const firestore = useFirestore()
  const firebase = useFirebase()
  
  const updatePublicProfile = useCallback(async ({newPhoto, removePhoto, notDriver, ...form}: any) => {
    setProcessing(true)
    
    if (newPhoto) {
      const uploaded = await firebase.uploadFile(`profiles/${profileId}`, newPhoto, undefined, { name: `${createShortId(HEROSHOT_FILENAME_PREFIX)}.png` })
      form.photo = removeTokenFromURL(await uploaded.uploadTaskSnapshot.ref.getDownloadURL())
    }

    if (removePhoto) {
      form.photo = null
    }

    const formPayload = {
      ...form,
      birthDate: moment(form.birthDate).format('YYYY-MM-DD'),
      country: form.country.value,
      city: form.city.value,
    }

    const payload = [...(dirtyFields as Set<string>)].reduce((obj, name) => {
      return {
        ...obj,
        [getPayloadName(name)]: formPayload[getPayloadName(name)],
      }
    }, {})
    
    createRequest({
      id: uid,
      ..._pick(profile, ['avatarUrl', 'email', 'displayName'])
    }, null, MemberRequestType.PROFILE_CHANGE, { profileId, ...payload }, firestore)
      .then(() => {
        handleSuccess()
      })
      .catch((e) => handleFail(e.message))
  }, [dirtyFields, uid, profile, profileId, firestore, firebase, handleSuccess, handleFail])
  
  const onSubmit = handleSubmit((form) => { window.confirm(t('CONFIRM')) && updatePublicProfile(form)} )
  
  return (
    <Form onSubmit={onSubmit} className={classNames(styles.ProfileForm, "mb-5", isCollapsed && styles.collapsed)}>
      {status !== MemberProfileStatus.active &&  <Alert variant={badge.variant} className={styles.alert}>
        <Trans t={t} i18nKey={`WARNING.${badge.title}`}/>
      </Alert>}
      <fieldset disabled={isDisabled || isCollapsed}>
      <Prompt
        when={!!dirtyFields.size}
        message={() =>
          t('alerts:UNSAVED') as string
        }
      />
      <Row>
        <Col sm={8}>
          <Form.Group>
            <Form.Row>
              <Col>
                <Form.Label className="required">{t('LABEL.NAME_EN')}</Form.Label>
                <Controller
                  as={Form.Control}
                  control={control}
                  type="text"
                  placeholder={t('PLACEHOLDER.NAME')}
                  name="name_en"
                  rules={{
                    required: true,
                    minLength: 2,
                    pattern: LASTNAME_EN_PATTERN
                  }}
                  isInvalid={errors.name_en}
                />
              </Col>
              <Col>
                <Form.Label className="required">{t('LABEL.SURNAME_EN')}</Form.Label>
                <Controller
                  as={Form.Control}
                  control={control}
                  type="text"
                  placeholder={t('PLACEHOLDER.SURNAME')}
                  name="surname_en"
                  rules={{
                    required: true,
                    minLength: 2,
                    pattern: LASTNAME_EN_PATTERN
                  }}
                  isInvalid={errors.surname_en}
                />
              </Col>
            </Form.Row>
              <Form.Text className={(errors.name_en || errors.surname_en)? "text-danger" : "text-muted"}>
                {t('TEXT.NAME_EN')}
              </Form.Text>
          </Form.Group>

          <Form.Group>
              <Form.Row>
              <Col>
                <Form.Label className={classNames(country && country.value === "Ukraine" && "required")}>{t('LABEL.NAME_UK')}</Form.Label>
                <Controller
                  as={Form.Control}
                  control={control}
                  type="text"
                  placeholder={t('PLACEHOLDER.NAME_UK')}
                  name="name_uk"
                  rules={{
                    minLength: 2,
                    pattern: LASTNAME_PATTERN,
                    validate: value => (!!value || !(getValues('country') && getValues('country').value === "Ukraine"))
                  }}
                  isInvalid={errors.name_uk}
                />
              </Col>
              <Col>
                <Form.Label className={classNames(country && country.value === "Ukraine" && "required")}>{t('LABEL.SURNAME_UK')}</Form.Label>
                <Controller
                  as={Form.Control}
                  control={control}
                  type="text"
                  placeholder={t('PLACEHOLDER.SURNAME_UK')}
                  name="surname_uk"
                  rules={{
                    minLength: 2,
                    pattern: LASTNAME_PATTERN,
                    validate: value => (!!value || !(getValues('country') && getValues('country').value === "Ukraine"))
                  }}
                  isInvalid={errors.surname_uk}
                />
              </Col>
            </Form.Row>
              <Form.Text className={(errors.name_uk || errors.surname_uk)? "text-danger" : "text-muted"}>
                {t('TEXT.NAME_UK')}
              </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Row>
              <Col>
                <Form.Label className="required">{t('LABEL.BIRTHDATE')}</Form.Label>
                <Controller
                  as={Datepicker}
                  control={control}
                  name="birthDate"
                  rules={{
                    required: true,
                  }}
                  valueName="selected"
                  onChange={([date]) => date}
                  className={classNames("form-control", errors.birthDate && "is-invalid")}
                  dateFormat="yyyy-MM-dd"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  maxDate={moment().subtract(7, 'years').toDate()}
                  minDate={moment().subtract(70, 'years').toDate()}
                  placeholderText={t('PLACEHOLDER.DATE')}
                  autoComplete="off"
                />
                <Form.Text className={errors.birthDate ? "text-danger" : "text-muted"}>
                  {t('TEXT.BIRTHDATE')}
                </Form.Text>
              </Col>
              <Col>
                <Form.Label className="required">{t('LABEL.DEBUTYEAR')}</Form.Label>
                {/*TODO: REPLACE WITH DATEPICKER
                @see https://github.com/Hacker0x01/react-datepicker/issues/1249#event-3307178607
                */}
                {/*<Controller*/}
                {/*  as={Datepicker}*/}
                {/*  control={control}*/}
                {/*  name="debutYear"*/}
                {/*  rules={{*/}
                {/*    required: true,*/}
                {/*  }}*/}
                {/*  valueName="selected"*/}
                {/*  onChange={([date]) => date}*/}
                {/*  className={classNames("form-control", errors.debutYear && "is-invalid")}*/}
                {/*  dateFormat="yyyy"*/}
                {/*  //@ts-ignore*/}
                {/*  showYearPicker*/}
                {/*  maxDate={moment().toDate()}*/}
                {/*  minDate={moment("2000").toDate()}*/}
                {/*  placeholderText={t('PLACEHOLDER.DATE')}*/}
                {/*  autoComplete="off"*/}
                {/*/>*/}
                <Controller
                  as={Form.Control}
                  control={control}
                  name="debutYear"
                  rules={{
                    required: true,
                    min: 2000,
                    max: moment().year(),
                    pattern: /[0-9]{4}/
                  }}
                  className={errors.debutYear && "is-invalid"}
                  placeholder={t('PLACEHOLDER.DEBUTYEAR')}
                  autoComplete="off"
                />
                <Form.Text className={errors.debutYear ? "text-danger" : "text-muted"}>
                  {t('TEXT.DEBUTYEAR')}
                </Form.Text>
              </Col>
            </Form.Row>
          </Form.Group>

          <Form.Group>
            <Form.Row>

              <Col>
                <Form.Label className="required">
                  {t('LABEL.COUNTRY')}
                </Form.Label>
                <Controller
                  control={control}
                  as={Select}
                  name="country"
                  rules={{
                    required: true
                  }}
                  options={getCountriesSelection()}
                  isDisabled={isDisabled}
                  className="select"
                  theme={selectorTheme}
                  styles={selectorStyles(!errors.country)}
                  placeholder={t('PLACEHOLDER.COUNTRY')}
                  onChange={([selected]) => {
                    setValue('city', getCitiesSelection(selected.value).find(city => city.value === profileData.city) || null)
                    return selected
                  }}
                />
              </Col>

              <Col>
                <Form.Label className="required">
                  {t('LABEL.CITY')}
                </Form.Label>
                <Controller
                  control={control}
                  as={Select}
                  name="city"
                  rules={{
                    required: true
                  }}
                  options={getCitiesSelection(country && country.value)}
                  isDisabled={isDisabled}
                  className="select"
                  theme={selectorTheme}
                  styles={selectorStyles(!errors.city)}
                  placeholder={t('PLACEHOLDER.CITY')}
                />
              </Col>
            </Form.Row>
            <Form.Text className={(errors.country || errors.city) ? "text-danger" : "text-muted"}>
              {t('TEXT.COUNTRY')}
            </Form.Text>
          </Form.Group>

          <Form.Group className="position-relative">
            <Tab.Container defaultActiveKey="en" id="uncontrolled-tab-example">
              <Nav variant="pills" className={styles.localeTabs}>
                <Nav.Item>
                  <Nav.Link eventKey="en">{t('LABEL.EN')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="uk">{t('LABEL.UK')}</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="en">
                  <Form.Label>{t('LABEL.BIO_EN')}</Form.Label>
                  <Controller
                    control={control}
                    as={ <Form.Control as="textarea" rows="3" placeholder={t('PLACEHOLDER.BIO')} />}
                    name="bio_en"
                    rules={{
                      maxLength: 500
                    }}
                    className={styles.bio}
                    isInvalid={errors.bio_en}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="uk">
                  <Form.Label>{t('LABEL.BIO_UK')}</Form.Label>
                  <Controller
                    control={control}
                    as={ <Form.Control as="textarea" rows="3" placeholder={t('PLACEHOLDER.BIO')} />}
                    name="bio_uk"
                    rules={{
                      maxLength: 500
                    }}
                    className={styles.bio}
                    isInvalid={errors.bio_uk}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            <Form.Text className="text-muted">
              {t('TEXT.BIO')}
            </Form.Text>
          </Form.Group>

          {/*TODO: Move to separate profile section*/}
          {/*<Form.Group>*/}
          {/*  <CustomInput*/}
          {/*    name="isAnonymous"*/}
          {/*    label={t('LABEL.ANONYMOUS')}*/}
          {/*    register={register}*/}
          {/*    type="switch"*/}
          {/*  />*/}
          {/*</Form.Group>*/}
          {/*<Form.Group>*/}
          {/*  <CustomInput*/}
          {/*    name="isPrivate"*/}
          {/*    label={t('LABEL.PRIVATE')}*/}
          {/*    register={register}*/}
          {/*    type="switch"*/}
          {/*  />*/}
          {/*</Form.Group>*/}

        </Col>
        <Col sm={4}>
          <ProfileHeroshot
            previousPhoto={profileData.photo}
            register={register}
            setValue={setValue}
            triggerValidation={triggerValidation}
            isDirty={dirtyFields.has('newPhoto')}
            isRemoving={dirtyFields.has('removePhoto')}
            isValid={!errors.newPhoto}
          />
        </Col>
      </Row>
      <Form.Text className="text-muted">
        {t('TEXT.SUBMIT')}
      </Form.Text>
      <SmartButton disabled={!dirtyFields.size} isProcessing={isProcessing} variant="primary" type="submit" className="mt-2">
        {t('LABEL.SUBMIT')}
      </SmartButton>


      </fieldset>
      {isCollapsed &&
      <SmartButton block
        iconType="chevronDownOutline"
        variant="secondary"
        className={styles.collapseButton}
        onClick={() => setCollapsed(false)}
      >
        <span className="align-middle">{t('EDIT')}</span>
      </SmartButton>}
    </Form>
  )
}