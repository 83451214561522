import * as React from 'react'
import { Row, Col } from 'react-bootstrap'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { ROUTE } from 'config'

import styles from './LastResult.module.scss'
import {i18path} from "i18n"

type Props = {
  eventName: string;
  eventKey: string;
  data: {number?: number, name: string, points: number, win?: boolean}[]
};

export const LastResult: React.FC<Props> = ({ eventName, eventKey, data }) => {
  return (
    <div className={styles.LastResult}>
      <p className="text-muted">{eventName}</p>
      <Link to={i18path(`${ROUTE.STANDINGS}/udrc2020/${eventKey}`)} className={styles.bracket}>
        {data.map((d, i) => (
          <Row key={i} className={classNames(styles.row, d.win && styles.win)}>
            <Col lg={2} className={classNames(d.number && styles.number)}>
              {d.number}
            </Col>
            <Col lg={8}>
              {d.name}
            </Col>
            <Col lg={2} className="text-right pl-0">
              {d.points}
            </Col>
          </Row>
        ))}
        </Link>
    </div>
  )
}