import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import en from 'translations/translations.en.json'
import uk from 'translations/translations.uk.json'

import LanguageDetector from 'i18next-browser-languagedetector'
import {MemberProfile} from "redux/reducers"


const options = {
  resources: { en, uk },
  whitelist: ["en", "uk", "ru-UA", "cimode"],
  nonExplicitWhitelist: false,
  // lng: "cimode",
  fallbackLng: {
    'ru-UA': ['uk', 'en'],
    'default': ['en']
  },

  // debug: true,
  // keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false // react already safes from xss
  },

  detection: {
    order: ['localStorage', 'navigator'],
    lookupLocalStorage: 'udrcLang',
    caches: []
  }
}

/*
TODO: Replace with usage of `t('common:LOCALES', { returnObjects: true })`
@deprecated
 */
export const locales = Object.keys(options.resources)
export function getCurrentLocale(defaultLocale?: string) {
  let locale
  return (() => {
    if (locale) return locale

    locale = i18n.languages.find((lng => lng === 'cimode' || locales.indexOf(lng) !== -1))

    if (defaultLocale) {
      return locale && locale !== 'cimode' ? locale : defaultLocale
    } else {
      return locale
    }
  })()
}

export function i18path(path: string, locale?: string) {
  const prepath = locale || getCurrentLocale() !== 'cimode' ? getCurrentLocale() : ""
  return prepath ? `/${prepath}${path}` : path
}

export function addProfileTranslations(drivers: (MemberProfile & {[key: string]: any})[]) {
  const translations: { [key:string]:any } = {}

  // translations = {
  //   en: {
  //     karpenko: {
  //       name: "Oleksii",
  //       surname: "Karpenko",
  //       fullName: "Oleksii Karpenko",
  //       bio: "Some bio text"
  //     }
  //   },
  // }

  locales.forEach(locale => {
    translations[locale] = {}
    drivers.forEach(driver => {
      translations[locale][driver.id] = {
        ...(driver[`name_${locale}`] ? {name: driver[`name_${locale}`]} : {}),
        ...(driver[`surname_${locale}`] ? {surname: driver[`surname_${locale}`]} : {}),
        ...(driver[`name_${locale}`] && driver[`surname_${locale}`] ? {fullName: `${driver[`name_${locale}`]} ${driver[`surname_${locale}`]}`} : {}),
        ...(driver[`bio_${locale}`] ? {bio: driver[`bio_${locale}`]} : {}),
      }
    })
  })
  
  for (let [key, value] of Object.entries(translations)) {
    i18n.addResourceBundle(key, "profiles", value)
  }

}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(options)

export default i18n