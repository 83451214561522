import { combineReducers } from "redux"
import global, { GlobalAppState } from "./global"
import user from "./user"
import { firebaseReducer, FirebaseReducer, FirestoreReducer} from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

export interface UserProfile {
  id: string;
  email: string
  displayName: string;
  avatarUrl: string;
  profileId: string;
  carsCount: number;
}

export interface MemberProfileCar {
  id: string;
  photo: string;
  specs: {[key:string]: any};
  updatedAt: { seconds: number; nanoseconds: number };
}

export enum ProfileStatus {
  draft = "draft",
  active = 'active',
  review = 'review'
}

export interface MemberProfile {
  id: string;
  userId: string;
  name_en: string;
  surname_en: string;
  name_uk: string;
  surname_uk: string;
  isActive: boolean;
  // isDriver: boolean;
  // hideAge: boolean;
  status: ProfileStatus;
  isPro: boolean;
  isAnonymous: boolean;
  isPrivate: boolean;
  country: string;
  city: string;
  photo?: string;
  tailNumber: {[key: string]: number}
  birthDate: string;
  debutYear: number;
  bio_en: string;
  bio_uk: string;
  defaultCar: MemberProfileCar;
}

export interface EventResult {
  id: string;
  driver: string;
  event: string;
  series: string;
  qualPoints: number;
  finalPoints: number;
  qualRank: number;
  finalRank: number;
  bestQual: number;
  total: number;
}

export interface EventStage {
  id: string;
  name: string;
  series: string;
  city: string;
  finished: boolean;
  date: string;
}

export interface Series {
  id: string;
  name: string;
  year: number;
  active: boolean;
  default: boolean;
}

export interface RaceData {
  id: string;
  round: number;
  bracket: number;
  driver1: string;
  result1: number;
  driver2: string;
  result2: number;
  attempt:number
  matchup: number;
}

export interface CarSpecData {
  id: string;
  name: string;
  photo: string;
  chassis: string;
  radio: string;
  battery: string;
  servo: string;
  gyro: string;
  motor: string;
  esc: string;
  updatedAt: { seconds: number; nanoseconds: number };
}

export enum MemberRequestType {
  PROFILE_LINK = 'profile_link',
  PROFILE_UNLINK = 'profile_unlink',
  PROFILE_CHANGE = 'profile_change',
  CAR_NUMBER = 'car_number',
  EVENT_APPLY = 'event_apply',
  EDIT_CONTENT = 'edit_content',
}

export interface MemberRequest {
  id: string;
  createdAt: { seconds: number; nanoseconds: number };
  resolvedAt: { seconds: number; nanoseconds: number } | null;
  isApproved: boolean;
  isResolved: boolean;
  isAgreed: boolean;
  author: MemberRequestAuthor;
  resolverId: string;
  votesNeeded: number;
  votes: {[userId: string]: boolean}
  type: MemberRequestType;
  payload: {[key: string]: any};
  snapshot: {[key: string]: any};
  comment: string;
  reason: string;
}

export type MemberRequestAuthor = Pick<UserProfile, 'id' | 'avatarUrl' | 'displayName' | 'email'>

export enum SiteContentType {
  regulations = "regulations"
}

export enum SiteContentStatus {
  draft = "draft",
  published = 'published',
  disabled = 'disabled'
}

export interface SiteContent {
  id: string;
  title: string;
  description: string;
  type: SiteContentType;
  content: any;
  status: SiteContentStatus;
  revisionId: string;
  updatedAt: { seconds: number; nanoseconds: number };
  locale: string;
}

export interface VendorModel {
  id: string;
  name: string;
  type: string;
  url: string;
}
export interface Vendor {
  id: string;
  name: string;
  logo?: string;
  types: string[];
  website: string;
  models: VendorModel[]
}

export type CarDriver = Pick<MemberProfile, 'id'>
export type CarOwner = Pick<UserProfile, 'id' | 'avatarUrl' | 'email' | 'displayName'>

export interface Car {
  id: string;
  name: string;
  photo: string;
  specs: {[key:string]: any}
  story_en: string;
  story_uk: string;
  updatedAt: { seconds: number; nanoseconds: number };
  status: CarStatus
  isModerated: boolean;
  driver: CarDriver;
  owner: CarOwner;
}

export type CarDelete = Pick<Car, 'id' | 'status' | 'driver'>

export enum CarStatus {
  draft = "draft",
  active = 'active',
  review = 'review',
  disabled = 'disabled'
}

// create schema for the DB
interface DBSchema {
  profiles: MemberProfile,
  requests: MemberRequest
  [name: string]: any
}
interface RootState {
  global: GlobalAppState,
  firebase: FirebaseReducer.Reducer<UserProfile, DBSchema>
  firestore: FirestoreReducer.Reducer
}

const rootReducer = combineReducers<RootState>({
  global,
  user,
  firebase: firebaseReducer,
  // TODO: remove ts-ignore, @see https://github.com/prescottprue/react-redux-firebase/issues/891
  // @ts-ignore
  firestore: firestoreReducer
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
