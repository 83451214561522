import * as React from 'react'
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Logo } from 'svg'
import {useTranslation} from "react-i18next"

import {IndexLinkContainer, LinkContainer} from 'react-router-bootstrap'

import {AuthPopup} from "./AuthPopup"
import {AlertManager} from "./AlertManager"
import {UserMenu} from "./UserMenu"

import {getCurrentLocale, i18path} from "i18n"
import {ROUTE} from "config"
import { useLocation, useRouteMatch } from 'react-router-dom'
import {Icon} from "components/Icon"

import styles from './Header.module.scss'
import {getLocales} from "utils"
import { matchPath } from 'react-router'

type Props = {

};

export const Header: React.FC<Props> = (props) => {
  const { t } = useTranslation('Header')
  const location = useLocation()
  const { path, url } = useRouteMatch()

  return (
    <>
      <Navbar expand="lg" variant="dark" bg="dark" sticky="top" className={styles.Header}>
        <Container style={{position: "relative"}}>
          <IndexLinkContainer to={i18path(ROUTE.HOME)}><Navbar.Brand><Logo width={120} height={48}/></Navbar.Brand></IndexLinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <NavDropdown active={!!matchPath(url, {path: i18path(ROUTE.UDRC), exact: false})} title={t('MENU.UDRC')} id="menu-championship">
                <LinkContainer to={i18path(ROUTE.SCHEDULE)}><NavDropdown.Item active={false} className="tbd">{t('MENU.SCHEDULES')}</NavDropdown.Item></LinkContainer>
                <LinkContainer to={i18path(ROUTE.STANDINGS)}><NavDropdown.Item active={false}>{t('MENU.STANDINGS')}</NavDropdown.Item></LinkContainer>
                <NavDropdown.Divider />
                <LinkContainer to={i18path(ROUTE.REGULATIONS)}><NavDropdown.Item active={false}>{t('MENU.REGULATIONS')}</NavDropdown.Item></LinkContainer>
              </NavDropdown>

              <LinkContainer to={i18path(ROUTE.DRIVERS)}><Nav.Link active={false}>{t('MENU.DRIVERS')}</Nav.Link></LinkContainer>
              <LinkContainer to={i18path(ROUTE.CARS)}><Nav.Link active={false}>{t('MENU.CARS')}</Nav.Link></LinkContainer>
              <LinkContainer to={i18path(ROUTE.LOCATIONS)}><Nav.Link active={false} className="tbd">{t('MENU.LOCATIONS')}</Nav.Link></LinkContainer>
              <LinkContainer to={i18path(ROUTE.PARTNERS)}><Nav.Link active={false} className="tbd">{t('MENU.PARTNERS')}</Nav.Link></LinkContainer>

              <NavDropdown title={t('MENU.MEDIA')} id="menu-media">
                <LinkContainer to={i18path(ROUTE.NEWS)}><NavDropdown.Item active={false} className="tbd">{t('MENU.NEWS')}</NavDropdown.Item></LinkContainer>
                <NavDropdown.Divider />
                <LinkContainer to={i18path(ROUTE.PHOTO_VIDEO)}><NavDropdown.Item active={false} className="tbd">{t('MENU.PHOTO_VIDEO')}</NavDropdown.Item></LinkContainer>
              </NavDropdown>
            </Nav>
            {/*TODO: LIVE EVENT*/}
            {/*<Nav className="mr-auto">*/}
            {/*  <LinkContainer to="/schedule" className="brand-title brand-title-live"><Nav.Link>UDRC'2020 Stage 2</Nav.Link></LinkContainer>*/}
            {/*</Nav>*/}
            <Nav className="mr-2">
              <NavDropdown title={<Icon type={getCurrentLocale()!}/>} id="collasible-nav-locale" className={styles.langSelect}>
                {getLocales(t).map(locale => (
                  <LinkContainer key={locale[0]} to={`${location.pathname.replace(getCurrentLocale()!, locale[0])}`}>
                    <NavDropdown.Item active={false} className={styles.lang}>
                      <Icon type={locale[0]} className="align-middle mr-2"/>
                      <span className="align-middle">{locale[1]}</span>
                    </NavDropdown.Item>
                  </LinkContainer>
                ))}
              </NavDropdown>
            </Nav>
            <UserMenu/>
          </Navbar.Collapse>
          <AlertManager/>
        </Container>
      </Navbar>
      <AuthPopup/>
    </>
  )
}