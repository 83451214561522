import React from 'react'

import {Badge, ListGroup} from 'react-bootstrap'
import {LinkContainer} from "react-router-bootstrap"
import {ROUTE} from "config"

import styles from './MemberMenu.module.scss'
import {useSelector} from "react-redux"
import {i18path} from "i18n"
import { useLocation } from 'react-router-dom'
import {useTranslation} from "react-i18next"
import {getAuthUid, getOthersActiveRequestCount, getUserProfile} from "redux/selectors"

type Props = {

};

export const MemberMenu: React.FC<Props> = () => {

  const { t } = useTranslation('MemberPage')

  const profile = useSelector(getUserProfile)
  const uid = useSelector(getAuthUid)
  const requestsCount = useSelector(getOthersActiveRequestCount(uid))
  const { pathname } = useLocation()
  
  return (
    <div className={styles.MemberMenuContainer} key={pathname}>
    {profile?.isOrganizer && <ListGroup className={styles.MemberMenu}>
        <ListGroup.Item className={styles.groupTitle}>{t('MENU.ORGANIZER')}</ListGroup.Item>

        <LinkContainer exact to={i18path(ROUTE.REQUESTS)}>
          <ListGroup.Item action active={false}>
            {t('TITLE.MEMBERS_REQUESTS')}
            {!!requestsCount && <Badge variant="primary" pill className="mr-n2">{requestsCount}</Badge>}
          </ListGroup.Item>
        </LinkContainer>
        <LinkContainer exact to={i18path(ROUTE.SERIES)}>
          <ListGroup.Item action active={false} className="tbd">{t('TITLE.SERIES_AND_EVENTS')}</ListGroup.Item>
        </LinkContainer>
        <LinkContainer exact to={i18path(ROUTE.CONTENT)}>
          <ListGroup.Item action active={false} className="tbd">{t('TITLE.CONTENT')}</ListGroup.Item>
        </LinkContainer>
        {<LinkContainer exact to={i18path(ROUTE.UPDATE_REGULATIONS)}>
          <ListGroup.Item action active={false}>{t('TITLE.UPDATE_REGULATIONS')}</ListGroup.Item>
        </LinkContainer>}
    </ListGroup>}

    <ListGroup className={styles.MemberMenu}>
      <ListGroup.Item className={styles.groupTitle}>{t('MENU.MEMBER')}</ListGroup.Item>
      <LinkContainer to={i18path(ROUTE.GARAGE)}>
        <ListGroup.Item action active={false}>{t('TITLE.GARAGE')}</ListGroup.Item>
      </LinkContainer>
      <LinkContainer exact to={i18path(ROUTE.PROFILE)}>
        <ListGroup.Item action active={false}>{t('TITLE.MEMBER_PROFILE')}</ListGroup.Item>
      </LinkContainer>

      {profile?.profileId &&
      <>
        <LinkContainer exact to={i18path(ROUTE.TEAM)}>
          <ListGroup.Item action active={false} className="tbd">{t('TITLE.TEAM')}</ListGroup.Item>
        </LinkContainer>
        <LinkContainer exact to={i18path(ROUTE.PARTICIPATIONS)}>
          <ListGroup.Item action active={false} className="tbd">{t('TITLE.PARTICIPATIONS')}</ListGroup.Item>
        </LinkContainer>
      </>
      }
    </ListGroup>

    <ListGroup className={styles.MemberMenu}>
      <ListGroup.Item className={styles.groupTitle}>{t('MENU.ACCOUNT')}</ListGroup.Item>

      <LinkContainer exact to={i18path(ROUTE.SETTINGS)}><ListGroup.Item action active={false}>
        {t('TITLE.ACCOUNT_SETTINGS')}
        {!profile.isVerified && <Badge variant="danger" pill className="mr-n2">!</Badge>}
      </ListGroup.Item></LinkContainer>
    </ListGroup>
    </div>
  )
}