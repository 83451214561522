import {Theme} from "react-select"
import {TFunction} from "i18next"

export interface SelectorValue {
  value: any;
  label: string;
  isNew?: boolean;
}

const invalidStyles = {
  '&:hover': { borderColor: '#d9534f' }, // border style on hover
  borderColor: '#d9534f', // default border color
  // boxShadow: 'none', // no box-shadow
}

const disabledStyles = {
  backgroundColor: '#EBEBEB'
}

export const selectorTheme = (theme: Theme):Theme => ({
  ...theme,
  // borderRadius: 0,
  colors: {
    ...theme.colors,
    primary: '#DF691A',
    primary25: 'rgba(223,105,26,0.25)',
    primary50: 'rgba(223,105,26,0.5)',
  },
})

export const selectorStyles = (isValid: boolean = true) => ({
  control: (base:any, { isDisabled }: { isDisabled: boolean}) => ({
    ...base,
    ...!isValid && invalidStyles,
    ...isDisabled && disabledStyles
  }),
  placeholder: (base:any) => ({
    ...base,
    whiteSpace: "nowrap"
  })
})

/**
 * Get locales
 * @param t {TFunction} translate function from i18next
 * @returns {[[string, string]]}
 */
export const getLocales = (t: TFunction) => {
  const locales = t<{[key:string]:string}>('common:LOCALES', { returnObjects: true })

  if (typeof locales === 'object') return Object.entries(locales)

  return []
}