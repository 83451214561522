import { useLocation } from "react-router-dom"
import { useEffect } from "react"
import { useFirebase } from "react-redux-firebase"

export function FirebaseAnalytics() {
  const location = useLocation()
  const firebase = useFirebase()

  useEffect(() => {
    // @ts-ignore
    const analytics = firebase && firebase.analytics
    if (typeof analytics === "function") {
      const page_path = location.pathname + location.search
      analytics().setCurrentScreen(page_path)
      analytics().logEvent("page_view", { page_path })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
  return null
}