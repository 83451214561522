import React, {useEffect, useState} from 'react'

import {Icon} from "components/Icon"
import {Button, Col, Row} from "react-bootstrap"

import {useSelector} from "react-redux"
import {isEmpty, isLoaded, useFirestoreConnect} from "react-redux-firebase"
import {Loading} from "components/Loading"
import {CreatePopup} from "pages/Member/Profile/EmptyProfile/CreatePopup"
import {LinkPopup} from "pages/Member/Profile/EmptyProfile/LinkPopup"

import styles from './EmptyProfile.module.scss'
import {useTranslation} from "react-i18next"
import {addProfileTranslations} from "i18n"
import {getAllProfiles, queryAllProfiles} from "redux/selectors"

type Props = {

};

export const EmptyProfile: React.FC<Props> = (props) => {

  const { t } = useTranslation('EmptyProfile')
  useFirestoreConnect([
    queryAllProfiles
  ])

  const [mode, setMode] = useState<'create'|'link'|null>(null)
  const [ tReady, setTReady ] = useState(false)

  const profiles = useSelector(getAllProfiles)

  useEffect(() => {
    if (isLoaded(profiles)) {
      if (!isEmpty(profiles)) addProfileTranslations(profiles)
      setTReady(true)
    }
  }, [profiles])

  if (!isLoaded(profiles) || !tReady) return <Loading inline/>

  return (
    <Row>
      <Col lg={10}>
        <h4>{t('MemberPage:TITLE.MEMBER_PROFILE')}</h4>
        <hr/>
        <div className="d-flex flex-column align-items-center p-4 px-5">
          <Icon type="profilePic" className={styles.pic}/>
          <h5 className="text-center mb-3">{t('TITLE')}</h5>
          <p className="text-center mb-5 text-muted">{t('TEXT')}</p>
          <div className="mt-n3 d-flex justify-content-center">
            <Button className="mr-3" onClick={() => setMode('link')}>{t('LINK')}</Button>
            <Button variant="outline-primary" onClick={() => setMode('create')}>{t('CREATE')}</Button>
          </div>
          <LinkPopup show={mode==='link'} onHide={() => setMode(null)} profiles={profiles}/>
          <CreatePopup show={mode==='create'} onHide={() => setMode(null)}/>
        </div>
      </Col>
    </Row>
  )
}